import * as React from 'react';
import classNames from 'classnames';

import './Dialog.scss';

interface IProps {
  className?: string;
}

class Dialog extends React.Component<IProps> {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames('dialog', className)}>
        {children}
      </div>
    );
  }
}

class DialogContent extends React.Component<IProps> {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames('dialog__content', className)}>
        {children}
      </div>
    );
  }
}

class DialogControls extends React.Component<IProps> {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames('dialog__controls', className)}>
        {children}
      </div>
    );
  }
}

export { DialogContent, DialogControls };
export default Dialog;
