import { Component } from 'react';
import IconButton from '../../../../components/buttons/IconButton/IconButton';
import * as React from 'react';

interface IProps {
  onClick: () => void;
}

export class PRRenderButton extends Component<IProps> {
  render() {
    const { onClick } = this.props;
    return process.env.REACT_APP_CL__FEATURE__PRRENDER === 'ON'
      ? <IconButton icon='camera' onClick={onClick}/>
      : null;
  }
}