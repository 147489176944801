"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

var OclEngine_1 = require("./components/OclEngine");

exports.default = OclEngine_1.OclEngine;

__export(require("./components/OclEngine"));

var OclParser_1 = require("./components/parser/OclParser");

exports.OclParser = OclParser_1.OclParser;

var expressions_1 = require("./components/expressions");

exports.PackageDeclaration = expressions_1.PackageDeclaration;

var OclExecutionContext_1 = require("./components/OclExecutionContext");

exports.OclExecutionContext = OclExecutionContext_1.OclExecutionContext;

var Utils_1 = require("./components/Utils");

exports.Utils = Utils_1.Utils;