import { IAdditionalAppliance, IKitchen, IMountedUnit } from '../schema';
import { Engine, FlatStructuralTransformer, getOptionId, Option, PModel } from '@canvas-logic/engine';
import { UnitMatcher } from './UnitMatcher';

interface LinkedElementsServiceContext {
  compatibleOptionValuesByPath: Engine['compatibleOptionValuesByPath'];
}

export default class LinkedElementsService {
  private readonly availableAdditionals: Option<IAdditionalAppliance>[] = [];
  private readonly availableAdditionalsByIndex: Array<Option<IAdditionalAppliance>[]> = [];

  constructor(private readonly context: LinkedElementsServiceContext, model: PModel<IKitchen>, private readonly unit: IMountedUnit) {
    const unitIndex = model.units.findIndex(installedUnit => installedUnit.uiId === unit.uiId);
    if (unitIndex === -1) {
      throw new Error(`Couldn't find original unit in the model`);
    }

    new UnitMatcher(this.unit.unit)
      .withAdditionals()
      .run(unit => {
        for (let index = 0; index < unit.additionals.length; index++) {
          const availableAdditionals = context.compatibleOptionValuesByPath(model, `units.${unitIndex}.unit.additionals.${index}`, new FlatStructuralTransformer<IAdditionalAppliance>()) ?? [];
          this.availableAdditionalsByIndex[index] = availableAdditionals;
          this.availableAdditionals.push(...availableAdditionals);
        }
      });
  }

  findAdditionalIndex(additionalId: string) {
    const index = this.availableAdditionalsByIndex.findIndex(additionals => additionals.some(additional => additional._id === additionalId));
    return index === -1 ? undefined : index;
  }

  findChildElement(additional: IAdditionalAppliance): Option<IAdditionalAppliance> | undefined {
    return this.availableAdditionals.find((availableAdditional, index) => availableAdditional._id === additional.child);
  }

  findInstalledChildElement(additional: IAdditionalAppliance): IAdditionalAppliance | undefined {
    let installedChild: IAdditionalAppliance | undefined = undefined;
    new UnitMatcher(this.unit.unit)
      .withAdditionals()
      .run(unit => {
        if (!additional) {
          return undefined;
        }
        installedChild = unit.additionals.find((availableAdditional, index) =>
          getOptionId(availableAdditional) === additional.child) ?? undefined;
      });

    return installedChild;
  }
}
