import {ILoader, ITraversable} from '../model/TemplateEngine';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

export class GltfLoaderService implements ILoader {
  private loader = new GLTFLoader();

  load(path: string, successCallback: (resource: ITraversable) => void, progressCallback: () => void, failCallback: (error: ErrorEvent) => void): void {
    this.loader.load(path, (gltf: GLTF) => {
      successCallback(
        {
          traverse(fn: any) {
            return gltf.scene.traverse(fn)
          },
          scene: gltf.scene
        });
    }, progressCallback, failCallback);
  }
}
