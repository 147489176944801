import { observable, action, computed } from "mobx";

/**
 * Influences styles of message window.
 */
export enum NotificationType {
  INFO,
  ERROR
}

/**
 * Some specific messages may have a specific ID assigned to them.
 * It is used now to add a possibility for user to mute a particular kind of messages.
 *
 * If message was instantiated with `canBeMuted` parameter, it's ID will be used to keep
 * track of other messages with the same ID and handle them properly.
 */
export enum NotificationID {
  RELATED_PRODUCTS,
  MUTATOR_ERROR,
}

/**
 * @field messageID represents react-intl message id
 * @field raw is a plain text that will be injected in dialog or a JSX.Element
 */
export type NotificationContent = {
  messageID?: string,
  raw?: JSX.Element | string,
};

/**
 * A collection of types for each kind of possible messages to enforce type-checking.
 */

type RelatedProductsNotificationConfiguration = {
  id: NotificationID.RELATED_PRODUCTS,
  type: NotificationType.INFO,
  content: NotificationContent,
  canBeMuted?: boolean,
  modal?: boolean,
}

type MutatorErrorConfiguration = {
  id: NotificationID.MUTATOR_ERROR,
  type: NotificationType,
  content: NotificationContent,
  canBeMuted?: undefined,
  modal: true,
}

type UniformNotificationConfiguration = {
  id?: undefined,
  type: NotificationType,
  content: NotificationContent,
  canBeMuted?: false,
  modal?: boolean,
}

type EnqueuedNotification = MessageConfiguration & {
  resolver: () => void,
}

export type MessageConfiguration = RelatedProductsNotificationConfiguration
  | MutatorErrorConfiguration
  | UniformNotificationConfiguration;

export class NotificationsStore {
  private mutedNotifications: { [key in NotificationID]?: boolean; } = { };

  @observable
  private notificationStack: EnqueuedNotification[] = [];

  async showNotification(config: MessageConfiguration) {
    if (this.isNotificationMuted(config)) {
      return Promise.resolve();
    }

    // keep track of notification promise resolver, to get back to it later if needed
    return new Promise(resolve => this.notificationStack = [...this.notificationStack, { ...config, resolver: resolve }]);
  }

  muteNotification(notificationId: NotificationID) {
    this.mutedNotifications[notificationId] = true;
  }

  @action.bound
  onClose(doNotShowAgain?: boolean) {
    const currentMessage = this.notificationStack[0];
    if (currentMessage.canBeMuted && doNotShowAgain === true) {
      this.muteNotification(currentMessage.id);
    }

    currentMessage.resolver();

    // when message is closed, we have to filter message stack and resolve all messages which are muted by now
    this.notificationStack = this.notificationStack
      .slice(1)
      .filter(notification => {
        if (this.isNotificationMuted(notification)) {
          notification.resolver();
          return false;
        }
        return true;
      });
  }

  @computed
  get currentNotification() {
    return this.notificationStack.length > 0 ? this.notificationStack[0] : undefined;
  }

  private isNotificationMuted(config: MessageConfiguration) {
    return config.id !== undefined && this.mutedNotifications[config.id] === true;
  }
}
