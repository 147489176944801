import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { ConfiguratorStore } from '../../stores/ConfiguratorStore';
import KitchenViewer from '../../services/KitchenViewer';

import './ConfiguratorModelViewer.scss';

interface IProps {
  store: ConfiguratorStore;
  template: string;
  useViewModel?: boolean;
}

@observer
export default class ConfiguratorModelViewer extends Component<IProps> {
  ref = React.createRef<HTMLCanvasElement>();
  private kitchenViewer: KitchenViewer;

  async componentDidMount() {
    const { store } = this.props;
    if (this.ref.current) {
      this.kitchenViewer = new KitchenViewer(store, this.ref.current);
      this.kitchenViewer.init()
        .finally(() => {
          store.configuratorLoaded();
          store.scene = this.kitchenViewer.sceneToExport;
        })
    } else {
      throw new Error('In ConfiguratorModelViewer, canvas ref is null');
    }
  }

  componentWillUnmount() {
    this.kitchenViewer.dispose();
  }

  render() {
    return (
      <div className="model-viewer">
        <canvas className="model-viewer-canvas" ref={this.ref} />
        {/*Temporary disable*/}
        {/*<img className="model-viewer-canvas back-image" src={this.props.store.canvasURL} alt=""/>*/}
      </div>
    );
  }
}
