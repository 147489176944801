import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import IconButton from '../buttons/IconButton/IconButton';
import Tooltip from '../Tooltip/Tooltip';
import ShareTarget from '../../features/ShareLink/components/ShareTarget/ShareTarget';
import { ShareOn } from '../../model/Model';
import { copyTextToClipboard } from '../../helpers/utils';

import './ShareButton.scss';

interface IProps {
  getLink: () => string;
  onShare: (shareOn: ShareOn) => void;
}

const ShareButton: React.FC<IProps> = ({ getLink, onShare }) => {
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const timerRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);

  const socialSharing = process.env.REACT_APP_CL__FEATURE_SHARE_LINK === 'ON';

  const copyLink = async () => {
    const link = getLink();

    await copyTextToClipboard(link);
  };

  const resetTimer = React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const showTooltip = () => {
    resetTimer();
    timerRef.current = setTimeout(() => setTooltipVisible(false), 3000);
    setTooltipVisible(true);
  };

  const handleLinkCopied = React.useCallback(() => {
    if (popupVisible) {
      setPopupVisible(false);
    }
    showTooltip();
  }, [popupVisible, setPopupVisible, showTooltip]);

  const handleClick = async () => {
    if (socialSharing) {
      if (popupVisible) {
        setPopupVisible(false);
      } else {
        if (tooltipVisible) {
          resetTimer();
          setTooltipVisible(false);
        }
        setPopupVisible(true);
      }
    } else {
      await copyLink();
      handleLinkCopied();
    }
  };

  const handleBlur = React.useCallback((event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setPopupVisible(false);
    }
  }, [setPopupVisible]);

  return (
    <div className='share-button__wrapper' onBlur={handleBlur}>
      <div className={classNames('share-button__popup', { 'share-button__popup--visible': popupVisible })}>
        <ShareTarget getLink={getLink} onLinkCopied={handleLinkCopied} onShare={onShare}/>
      </div>
      <Tooltip className={classNames('share-button__tooltip', { 'share-button__tooltip--visible': tooltipVisible })}>
        <FormattedMessage id="ui.share.copy.tooltip"/>
      </Tooltip>
      <IconButton
        icon="share"
        altIcon={'close'}
        showAltIcon={popupVisible}
        onClick={handleClick}
      />
    </div>
  );
};

export default ShareButton;
