import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { MountingOption } from '../../model/Model';

import './ReportMountingService.scss';

interface IProps {
  options: MountingOption[],
  selectedMountingVariantId: string | null;
  onChange: (option: string | null) => void;
}

class ReportMountingService extends React.Component<IProps> {
  render() {
    const { options, selectedMountingVariantId, onChange } = this.props;
    const showPrices = process.env.REACT_APP_CL__FEATURE__PRICING === 'ON';

    return (
      <div className="cart-mounting-service">
        <h2 className="cart-mounting-service__header">
          <FormattedMessage id="ui.report.mounting_service.header"/>
        </h2>
        <div className="cart-mounting-service__main">
          <div
            className={classNames(
              "cart-mounting-service__option",
              {"cart-mounting-service__option--print-invisible": selectedMountingVariantId}
            )}
          >
            <input
              className="cart-mounting-service__option-input"
              type="radio"
              id="none"
              name="mounting-service"
              value=""
              checked={!selectedMountingVariantId}
              onChange={() => onChange(null)}
            />
            <label
              className="cart-mounting-service__option-label"
              htmlFor="none"
            >
              <p className="cart-mounting-service__option-title">
                <FormattedMessage id={`ui.report.mounting_service.none`}/>
              </p>
            </label>
          </div>
          {options.map(option => (
            <div key={option.variantId} 
              className={classNames(
                "cart-mounting-service__option",
                {"cart-mounting-service__option--print-invisible": selectedMountingVariantId !== option.variantId}
              )}
            >
              <input
                className="cart-mounting-service__option-input"
                type="radio"
                id={option.variantId}
                name="mounting-service"
                value={option.variantId}
                checked={selectedMountingVariantId === option.variantId}
                onChange={() => onChange(option.variantId)}
              />
              <label
                className="cart-mounting-service__option-label"
                htmlFor={option.variantId}
              >
                <p className="cart-mounting-service__option-title">
                  <FormattedMessage id={`ui.report.mounting_service.${option.name}`}/>
                </p>
                <p className="cart-mounting-service__option-vendor-code">
                  <FormattedMessage id="ui.elements.ean"/>: {option.EAN}
                </p>
                {showPrices && <p className="cart-mounting-service__option-price">{option.price} €</p>}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default ReportMountingService;
