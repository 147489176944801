// for handle : R or L
import { deepCopy, getOptionId, MutatorContext, Option, PModel, ValidationResult } from '@canvas-logic/engine';
import { IKitchen, IMountedUnit } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';

export class ManageUnitOptionsMutator extends KitchenBaseMutator {
  constructor(private selectedNobiliaUnit: IMountedUnit, private property: string, private value: Option | string | number) {
    super();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, model: IKitchen): [PModel<IKitchen>, ValidationResult] {
    const units = model.units;
    const unit = units.find(u => getOptionId(u.unit) === getOptionId(this.selectedNobiliaUnit.unit) && u.position === this.selectedNobiliaUnit.position);
    if (!unit) {
      return [model, ValidationResult.Error('ui.error.no_original')];
    }
    if (this.value instanceof Option) {
      (unit.unit as any)[this.property] = deepCopy(this.value.model);
    } else {
      (unit.unit as any)[this.property] = this.value;
    }
    return [model, ValidationResult.Valid()];
  }
}
