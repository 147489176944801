import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './HQLoader.scss'

interface IProps {
  className?: string;
  error?: boolean;
}

interface IState {
  progress: number;
  timerRef: ReturnType<typeof setInterval> | null;
}

class HQLoader extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.startProgress();
  }

  startProgress = () => {
    const interval = setInterval(this.updateProgress, 1000);

    this.state = {
      progress: 0,
      timerRef: interval,
    }
  };

  updateProgress = () => {
    const { progress } = this.state;

    this.setState({
      progress: progress === 3 ? 0 : progress + 1
    })
  };

  componentWillUpdate(nextProps: Readonly<IProps>) {
    const { error } = this.props;

    if (nextProps.error === true && error !== nextProps.error) {
      const { timerRef } = this.state;
      if (timerRef) {
        clearInterval(timerRef);

        this.setState({
          timerRef: null
        })
      }
    } else if (!nextProps.error && error != nextProps.error) {
      this.startProgress();
    }
  }

  componentWillUnmount() {
    const { timerRef } = this.state;

    if (timerRef) {
      clearInterval(timerRef);
    }
  }

  render() {
    if (process.env.REACT_APP_CL__FEATURE__PRRENDER === 'ON') {
      const { className, error } = this.props;
      const { progress } = this.state;

      return (
        <small className={classNames('hq-loader', className)}>
          {error
            ? <FormattedMessage id="ui.render.error"/>
            : <><FormattedMessage id="ui.render.loader"/>{'.'.repeat(progress)}</>
          }
        </small>
      );
    } else {
      return null;
    }
  }
}

export default HQLoader;
