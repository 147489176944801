import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import IconButton from '../../../../components/buttons/IconButton/IconButton';
import Toggle from '../../../../components/buttons/Toggle/Toggle';
import ShareTarget from '../../../ShareLink/components/ShareTarget/ShareTarget';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import ARLoader from '../../../../components/ARLoader/ARLoader';
import GooglePlayButton from '../../../../components/GooglePlayButton/GooglePlayButton';
import { ShareOn } from '../../../../model/Model';
import LocaleSelector from '../../../LocaleSwitch/components/LocaleSelector/LocaleSelector';
import { Locale } from '../../../../stores/Localization';
import RootStore from '../../../../stores/RootStore';

import './ActionMenu.scss';

interface ILinkProps {
  link?: string;
}

const ARLink: React.FC<ILinkProps> = ({ link }) => {
  return (
    <div className='ar-link'>
      {link ?
        <p className='ar-link__content'>
          <FormattedMessage
            id="ui.ar.link.ready"
            values={{
              link: <>
                <br/>
                <a href={link} className='ar-link__link'>
                  <FormattedMessage id="ui.ar.link.action"/>
                </a>
              </>
            }}
          />
        </p>
        :
        <ARLoader className='ar-link__content'>
          <FormattedMessage id="ui.ar.link.loader"/>
        </ARLoader>
      }
      <GooglePlayButton/>
    </div>
  );
};

interface IProps {
  showRuler?: boolean;
  onToggleRuler?: () => void;
  getARLink: () => Promise<string>;
  getShareLink: () => string;
  onShare: (target: ShareOn) => void;
  onRenderClick: () => void;
  locale: Locale;
  onSwitchLocale: (locale: Locale) => void;
}

enum ActionPage {
  AR,
  SHARE
}

const ActionMenu: React.FC<IProps> = ({
                                        showRuler,
                                        onToggleRuler,
                                        getARLink,
                                        getShareLink,
                                        onShare,
                                        onRenderClick,
                                        locale,
                                        onSwitchLocale
                                      }) => {
  const [popupVisible, setPopupVisible] = React.useState(false);
  const popupRef = React.useRef<HTMLDivElement>(null);

  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const tooltipTimerRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);

  const [currentPage, setCurrentPage] = React.useState<ActionPage | null>(null);
  const [arLink, setARLink] = React.useState('');

  const showAR = process.env.REACT_APP_CL__FEATURE_AR === 'ON';
  const showPRRender = process.env.REACT_APP_CL__FEATURE__PRRENDER === 'ON';
  const showLocaleSwitch = (process.env.REACT_APP_CL__FEATURE__LOCALE_SWITCH === 'ON' && !RootStore.isEmbedded) ||
    (process.env.REACT_APP_CL__FEATURE__LOCALE_SWITCH === 'PROGRESSIVE' && !RootStore.localeControlledExternally);
  const multipleActions = React.useMemo(() => showAR || showPRRender, [showAR, showPRRender]);

  const resetTimer = React.useCallback(() => {
    if (tooltipTimerRef.current) {
      clearTimeout(tooltipTimerRef.current);
    }
  }, []);

  const showTooltip = React.useCallback(() => {
    resetTimer();
    tooltipTimerRef.current = setTimeout(() => setTooltipVisible(false), 3000);
    setTooltipVisible(true);
  }, []);

  const closePopup = React.useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  const handleClick = React.useCallback(() => {
    if (popupVisible) {
      closePopup();
    } else {
      if (multipleActions) {
        setCurrentPage(null);
      } else {
        setCurrentPage(ActionPage.SHARE);
      }
      setPopupVisible(true);
      resetTimer();
      setTooltipVisible(false);
    }
  }, [closePopup, setPopupVisible, popupVisible, multipleActions, setCurrentPage, resetTimer, setTooltipVisible]);

  const handleARClick = React.useCallback(async () => {
    if (currentPage === ActionPage.AR) {
      setCurrentPage(null);
    } else {
      setARLink('');
      setCurrentPage(ActionPage.AR);
      const link = await getARLink();
      console.log(link);
      setARLink(link);
    }
  }, [currentPage, setCurrentPage, setARLink, getARLink]);

  const handleRenderClick = React.useCallback(() => {
    onRenderClick();
    closePopup();
  }, [onRenderClick, closePopup]);

  const handleShareClick = React.useCallback(() => {
    if (currentPage === ActionPage.SHARE) {
      setCurrentPage(null);
    } else {
      setCurrentPage(ActionPage.SHARE);
    }
  }, [currentPage, setCurrentPage]);

  const handlePopupTransitionEnd = React.useCallback(() => {
    const { current: popup } = popupRef;
    if (popup) {
      popup.removeEventListener('animationend', handlePopupTransitionEnd);
      popup.focus();
    }
  }, []);

  const handleBlur = React.useCallback((event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      closePopup();
    }
  }, []);

  const handleLinkCopied = React.useCallback(() => {
    if (popupVisible) {
      setPopupVisible(false);
    }
    showTooltip();
  }, [popupVisible, setPopupVisible, showTooltip]);

  return (
    <div className='action-menu__wrapper' onBlur={handleBlur}>
      <div
        className={classNames('action-menu__popup', {
          'action-menu__popup--visible': popupVisible,
          // 'action-menu__popup--expanded': currentPage !== null,
        })}
        ref={popupRef}
        tabIndex={0}
      >
        <div className='action-menu__main'>
          <p className='action-menu__header'>
            <FormattedMessage id="ui.menu.options"/>
          </p>
          {showLocaleSwitch &&
          <>
            <p className='action-menu__subheader'>
              <FormattedMessage id="ui.lang"/>
            </p>
            <div className='action-menu__lang'>
              <LocaleSelector value={locale} onChange={onSwitchLocale}/>
            </div>
          </>
          }
          <div className='action-menu__toggle__wrapper'>
            <p className='action-menu__toggle__label'>
              <FormattedMessage id="ui.menu.size"/>
            </p>
            <Toggle
              checked={showRuler}
              color='gray'
              onChange={onToggleRuler}
            />
          </div>
          <hr className='action-menu__separator'/>
          <p className='action-menu__header'>
            <FormattedMessage id="ui.menu.actions"/>
          </p>
          <div className='action-menu__actions'>
            {multipleActions &&
            <>
              {showAR && <IconButton
                icon="ar"
                altIcon="ar"
                showAltIcon={currentPage === ActionPage.AR}
                onClick={handleARClick}
              />
              }
              {showPRRender &&
              <IconButton
                icon="camera"
                onClick={handleRenderClick}
              />
              }
              <IconButton
                icon="share"
                altIcon="share"
                showAltIcon={currentPage === ActionPage.SHARE}
                onClick={handleShareClick}
              />
            </>
            }
          </div>
        </div>
        <div className={classNames('action-menu__action-content', { 'action-menu__action-content--visible': currentPage !== null })}>
          {currentPage === ActionPage.AR && <ARLink link={arLink}/>}
          {currentPage === ActionPage.SHARE && <ShareTarget getLink={getShareLink} onLinkCopied={handleLinkCopied} onShare={onShare}/>}
        </div>
      </div>
      <Tooltip className={classNames('action-menu__tooltip', { 'action-menu__tooltip--visible': tooltipVisible })}>
        <FormattedMessage id="ui.share.copy.tooltip"/>
      </Tooltip>
      <IconButton
        icon="more"
        altIcon="close"
        showAltIcon={popupVisible}
        onClick={handleClick}
      />
    </div>
  );
};

export default ActionMenu;
