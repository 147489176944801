import { getOptionId, MutatorContext, Option, PModel, useTypeFrom, ValidationResult } from '@canvas-logic/engine';
import { EMPTY_UIID, InsertUnit, unitElementGuard } from '../model/Model';
import { layoutService } from '../services/LayoutService';
import { UnitMatcher } from '../services/UnitMatcher';
import { IAdditionalAppliance, IKitchen, IMountedUnit, Unit, UnitKind } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';

export class ReplaceUnitMutator extends KitchenBaseMutator {
  constructor(private unit: IMountedUnit, private unitToInsert: PModel<Unit>) {
    super();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, model: IKitchen): [PModel<IKitchen>, ValidationResult] {
    const units = model.units;
    const unitIndex = units.findIndex(unit =>
      unit.position === this.unit.position && getOptionId(unit.unit) === getOptionId(this.unit.unit));

    if (unitIndex < 0) {
      return [model, ValidationResult.Error('ui.error.no_original')];
    }

    let unitPosition: any = InsertUnit.After(this.unit.position - 1);
    if (this.unit.position == 0) {
      unitPosition = InsertUnit.Left();
    }
    const floorUnitsPositions = model.units.filter(unit => unit.unit.unitKind === UnitKind.bottom || UnitKind.tall)
      .map(unit => unit.position);

    if (this.unit.position === Math.max(...floorUnitsPositions)) {
      unitPosition = InsertUnit.Right();
    }
    // Replace unit
    let openingSide = undefined;
    if (unitElementGuard(this.unit.unit)) {
      openingSide = this.unit.unit.openingSide;
    }
    let newUnit: PModel<IMountedUnit> = Object.assign({} as any, {
      position: this.unit.position,
      uiId: this.unit.uiId,
      unit: { ...this.unitToInsert, openingSide}
    });
    useTypeFrom(newUnit.unit, this.unitToInsert);
    useTypeFrom(newUnit, units[0]);

    // Remove oldUnit
    units.splice(unitIndex, 1); //, newUnit);
    let unitToInsertWithOldAdditionals: any = { ...this.unitToInsert, openingSide };
    new UnitMatcher(this.unitToInsert)
      .withAdditionals()
      .run(unitToInsert => {
        const newAdditionals = unitToInsert.additionals;
        new UnitMatcher(this.unit.unit)
          .withAdditionals()
          .run(oldUnit => {
            const oldAdditionals = oldUnit.additionals;

            // Reuse additionals from previously selected unit if possible.
            // Validation will be evaluated as a transaction - either all additionals or none will be reused
            for (let i = 0; i < newAdditionals.length; i++) {
              for (let j = 0; j < oldAdditionals.length; j++) {
                const oldAdditional = oldAdditionals[j];
                const oldOptions = context.propertyValuesByPath(this.unit.unit as any, `additionals.${j}`) as Option<IAdditionalAppliance>[];
                if (!oldOptions.length) {
                  continue;
                }
                const oldType = oldOptions[0].model.additionalApplianceType;

                const newOptions = context.propertyValuesByPath(newUnit.unit as any, `additionals.${i}`) as Option<IAdditionalAppliance>[];
                const newType = newOptions[0].model.additionalApplianceType;

                const oldAdditionalId = oldAdditional ? getOptionId(oldAdditional) : null;
                if (!this.additionalsMatch(oldType, newType, oldAdditionalId, newOptions)) {
                  continue;
                }
                if (!oldAdditional) {
                  // Old additional was removed, so new additional also should be removed
                  unitToInsertWithOldAdditionals = this.changeAdditional(unitToInsertWithOldAdditionals, i, null);

                } else {
                  // Reuse oldAdditional
                  unitToInsertWithOldAdditionals = this.changeAdditional(unitToInsertWithOldAdditionals, i, oldAdditional);
                }
              }
            }

          })
      });

    model.units.splice(unitIndex, 0, {position: this.unit.position, uiId: this.unit.uiId, unit: unitToInsertWithOldAdditionals});

    if (context.validate(model).isValid) {
      // We can safely reuse old additionals
      if (layoutService.valid(model)) {
        return [model, ValidationResult.Valid()];
      } else {
        return [model, ValidationResult.Error('ui.error.replace_element')];
      }
    } else {
      model.units.splice(unitIndex, 1, newUnit);
      if (!layoutService.valid(model)) {
        return [model, ValidationResult.Error('ui.error.replace_element')];
      }
      return [model, ValidationResult.Valid()];
    }
  }

  private additionalsMatch(oldType: string, newType: string, oldOptionId: string, newOptions: Option<IAdditionalAppliance>[]) {
    return (oldType === newType) && (!oldOptionId || newOptions.map(o => o._id).includes(oldOptionId));
  }

  private changeAdditional(unit: Unit & {additionals: (IAdditionalAppliance | null)[]}, additionalIndex: number, value: IAdditionalAppliance | null) {
    return {
      ...unit,
      additionals: unit.additionals.map((additional, index) => index === additionalIndex ? value : additional)
    };
  }
}
