import * as React from "react";
import { action, observable } from 'mobx';
import { createIntl, createIntlCache, defineMessages, IntlShape, FormattedHTMLMessage } from 'react-intl';
import { enumKeys } from '../helpers/utils';

export enum Locale {
  en = 'en',
  de = 'de',
  fr = 'fr',
  nl = 'nl',
}

type RawLocaleMessages = Array<[string, string | RawLocaleMessages]>
type LocaleMessages = Record<string, string>;

type LocaleSwitchListener = (locale: Locale) => void;

class Localization {
  @observable locale: Locale;
  @observable intl: IntlShape;
  /**
   * If locale is controlled by host site.
   *
   * @see EmbeddingService.subscribeToLocaleChange
   */
  @observable externalControl: boolean = false;

  private localeSwitchListener?: LocaleSwitchListener;

  constructor(initialLocale: Locale) {
    this.locale = initialLocale;
  }

  @action
  async init() {
    const messages = await this.fetchTranslation();
    const flattenMessages = Object.fromEntries(this.flattenTranslation(Object.entries(messages)));
    this.initIntl(flattenMessages);
  }

  @action
  private async fetchTranslation() {
    const url = `assets/i18n/${this.locale}.json`;
    try {
      const response = await fetch(url);
      return await response.json() as RawLocaleMessages;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  private flattenTranslation(messageEntries: Array<[string, string | object]>): Array<[string, string]> {
     return messageEntries.flatMap(([key, entry]) => {
      if (typeof entry === 'string') {
        return [[key, entry]];
      } else {
        return this.flattenTranslation(Object.entries(entry)).map(([innerKey, innerEntry]) => { return [`${key}.${innerKey}`, innerEntry] });
      }
    }) as Array<[string, string]>;
  }

  @action.bound
  setLocaleSwitchListener(listener: LocaleSwitchListener) {
    this.localeSwitchListener = listener;
  }

  @action.bound
  removeLocaleSwitchListener() {
    this.localeSwitchListener = undefined;
  }

  @action.bound
  async changeLanguage(locale: Locale) {
    this.locale = locale;
    await this.init();

    if (this.localeSwitchListener) {
      this.localeSwitchListener(locale);
    }
  }

  formatMessage(key: string, values?: Record<string, any>) {
    if (!this.intl) {
      console.warn("Localization didn't init");
      return key;
    }

    const messages = defineMessages({
      [key]: {
        id: key,
      },
    });

    return this.intl.formatMessage(messages[key], values);
  }

  formatHTMLMessage(key: string, values?: Record<string, any>) {
    return <FormattedHTMLMessage id={key} values={values} />
  }

  private initIntl(messages: LocaleMessages) {
    // This is optional but highly recommended
    // since it prevents memory leak
    const cache = createIntlCache();

    this.intl = createIntl(
      {
        locale: this.locale,
        messages: messages,
        textComponent: 'span',
      },
      cache,
    );
  }

  static isLocaleSupported(locale: any): locale is keyof typeof Locale {
    return enumKeys(Locale).includes(locale);
  }

  static get defaultLocale() {
    return Locale.en;
  }

  static readonly KEY_LOCALE = 'LOCALE';

  static get BrowserAwareLocale() {
    const mainLocale = navigator.language.substr(0, 2);
    return this.isLocaleSupported(mainLocale) ? Locale[mainLocale] : Localization.defaultLocale;
  }

  static get PreferredUserLocale() {
    let storedLocale = localStorage.getItem(Localization.KEY_LOCALE);

    return this.isLocaleSupported(storedLocale) ? Locale[storedLocale] : Localization.BrowserAwareLocale;
  }
}

export default Localization;
