import { ModelMutator, MutatorContext, PModel, TypeReference, ValidationResult } from '@canvas-logic/engine';
import { layoutService } from '../services/LayoutService';
import { IKitchen } from '../schema';
import { updateModel } from './utils';

export abstract class KitchenBaseMutator implements ModelMutator {
  static lastGeneratedId = 1;

  abstract mutateKitchen(context: MutatorContext, typeRef: TypeReference, model: IKitchen): [PModel<IKitchen>, ValidationResult];

  mutate(context: MutatorContext, typeRef: TypeReference, model: PModel<IKitchen>): [PModel<IKitchen>, ValidationResult] {
    const [newModel, validationResult] = this.mutateKitchen(context, typeRef, model)
    if (validationResult.isValid) {
      try {
        this.postProcessModel(newModel, context);
      } catch(e) {
        return [newModel, ValidationResult.Error(e.message)];
      }
      return [newModel, ValidationResult.Valid()];
    }
    return [newModel, validationResult];
  }

  private postProcessModel(model: IKitchen, context: MutatorContext) {
    layoutService.normalizeKitchen(model);
    updateModel(model, context)
  }
}
