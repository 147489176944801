import { Option, OptionGroup, SourcedValue } from '@canvas-logic/engine';

export class EngineUtils {
  flattenOptions(options: (OptionGroup | Option)[]): Option[] {
    if (!options || !options.length) {
      return [];
    }
    let q: any[] = options!.slice() as any;
    const result = [];
    while (q.length > 0) {
      const next = q.pop();
      if (next instanceof OptionGroup) {
        q.push(...next.children);
      } else if (next instanceof Option) {
        result.push(next);
      }
    }
    return result;
  }

}

export const engineUtils = new EngineUtils();