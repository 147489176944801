import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { PModel } from '@canvas-logic/engine';

import IconButton from '../buttons/IconButton/IconButton';
import Button from '../buttons/Button/Button';
import ProgressBar from '../ProgressBar/ProgressBar';
import RenderArt from '../RenderArt/RenderArt';
import { CancellablePromiseError, copyTextToClipboard, makeCancelable, random } from '../../helpers/utils';
import { RenderOptions, ServerViewModel } from '../../model/ViewModel';
import { IKitchen } from '../../schema';
import PRRenderService from '../../features/PRRender/PRRenderService';
import { ShareImageParams, ShareOn } from '../../model/Model';
import ShareIcon from '../ShareIcon/ShareIcon';
import ButtonWithTooltip from '../ButtonWithTooltip/ButtonWithTooltip';

import './RenderView.scss';

interface IProps {
  className?: string;
  model: PModel<IKitchen>;
  onClose?: () => void;
  onShare: (target: ShareOn, imageUrl?: string, params?: ShareImageParams) => void;
  getShareLink: () => string;
}

interface IState {
  progress: number;
  error: boolean;
  imageUrl?: string;
  timerReference?: ReturnType<typeof setTimeout>;
  renderPromise?: ReturnType<typeof makeCancelable>;
}

@observer
class RenderView extends React.Component<IProps, IState> {
  private imageParams: RenderOptions = {
    height: 992,
    width: 558,
    type: 'png',
    watermark: [
      {
        name: "nobilia",
        align: "bottom-left",
        rotation: 0
      },
      {
        name: "poweredBy",
        align: "right",
        rotation: 270
      }
    ]
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      progress: 0,
      error: false,
    };
  }

  componentDidMount() {
    this.scheduleProgressUpdate();
    const renderPromise = makeCancelable(this.renderModel());
    renderPromise.promise
      .then(imageUrl => {
        this.setState({
          imageUrl,
          progress: 100
        });
      })
      .catch((error) => {
        if (!(error instanceof CancellablePromiseError)) {
          this.setState({
            error: true
          });
          console.error(error);
        }
      });
    this.setState({
      renderPromise
    });
  }

  componentWillUnmount() {
    const { timerReference, renderPromise } = this.state;

    if (timerReference) {
      clearTimeout(timerReference);
    }
    if (renderPromise) {
      renderPromise.cancel();
    }
  }

  renderModel = async () => {
    const { model } = this.props;
    const renderModel = new ServerViewModel(model, this.imageParams);

    return new PRRenderService().render(renderModel);
  };

  scheduleProgressUpdate = () => {
    const reference = setTimeout(() => {
      const { progress } = this.state;
      const updatedProgress = Math.min(100, progress + random(20));

      this.setState({
        progress: updatedProgress
      });

      if (progress < 100) {
        this.scheduleProgressUpdate();
      }

    }, random(3000));

    this.setState({
      timerReference: reference,
    });
  };

  handleCopyLinkClick = async () => {
    const { getShareLink } = this.props;
    const link = getShareLink();

    console.log(link);
    await copyTextToClipboard(link);
  };

  renderProgressView = () => {
    const { onClose } = this.props;
    const { progress } = this.state;

    return (
      <div className='render-view__progress'>
        <FormattedMessage id="ui.render.loader"/>
        <RenderArt className='render-view__art'/>
        <ProgressBar className='render-view__progress-bar' progress={progress}/>
        <Button color='white' onClick={onClose}>
          <FormattedMessage id="ui.cancel"/>
        </Button>
      </div>
    );
  };

  renderErrorView = () => {
    const { onClose } = this.props;

    return (
      <div className='render-view__progress'>
        <FormattedMessage id="ui.render.error"/>
        <RenderArt className='render-view__art' error/>
        <ProgressBar className='render-view__progress-bar' progress={0}/>
        <Button color='white' onClick={onClose}>
          <FormattedMessage id="ui.close"/>
        </Button>
      </div>
    );
  };

  renderImageView = () => {
    const { onClose, onShare } = this.props;
    const { imageUrl } = this.state;

    return (
      <div className='render-view__image-container'>
        <div className='render-view__image-wrapper'>
          <img
            className='render-view__image'
            src={imageUrl}
            alt='High resolution kitchen image'
          />
        </div>
        <IconButton className='render-view__close' icon={'close'} size='small' onClick={onClose}/>
        <ul className='render-view__actions'>
          <li>
            <button onClick={() => onShare(ShareOn.FB, imageUrl, this.imageParams)}><ShareIcon type={'facebook'}/></button>
          </li>
          <li>
            <ButtonWithTooltip
              button={<button><ShareIcon type={'link'}/></button>}
              tooltipContent={<FormattedMessage id="ui.share.copy.tooltip"/>}
              onClick={this.handleCopyLinkClick}
              classes={{
                tooltip: 'render-view__copy-tooltip',
                tooltipVisible: 'render-view__copy-tooltip--visible'
              }}
            />
          </li>
          <li>
            <a href={imageUrl} download target="_blank"><ShareIcon type={'download'}/></a>
          </li>
        </ul>
      </div>
    );
  };

  render() {
    const { className } = this.props;
    const { progress, imageUrl, error } = this.state;

    return (
      <div className={classNames('render-view', className)}>
        {error ?
          this.renderErrorView()
          : (progress === 100 && imageUrl) ?
            this.renderImageView()
            : this.renderProgressView()
        }
      </div>
    );
  }
}

export default RenderView;
