import React from 'react';

import './Checkbox.scss';

interface IProps {
  className?: string;
  label?: JSX.Element;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

class Checkbox extends React.Component<IProps> {

  render() {
    const { checked } = this.props;

    return(
      <div className={`${'checkbox' + (this.props.className ? ' ' + this.props.className : '')}`}>
        <input id="checkbox" type="checkbox" className="checkbox__input" checked={checked} onChange={this.onChange} />
        <label htmlFor="checkbox" className="checkbox__label">
          <svg className="checkbox__label-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g transform="translate(-554 -2589)">
              <path fillRule="evenodd" d="M15.818,7.339a1,1,0,0,1,0,1.414l-5.453,5.453a1,1,0,0,1-1.414,0l-1.995-2A1,1,0,0,1,8.37,10.8l1.288,1.288L14.4,7.339A1,1,0,0,1,15.818,7.339Z" transform="translate(554.987 2590.004)"/></g></svg>
        </label>
        <span className="checkbox__text">{this.props.label}</span>
      </div>
    );
  }

  onChange = (event: any) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.checked);
    }
  }
}

export default Checkbox;
