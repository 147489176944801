export class Transition {
  private startedAt: number;
  private finishedAt: number;
  private changeHandler: (value: number[]) => void;
  private currentValue: number[];
  private finishValue: number[];
  private running = false;
  private finishHandler: () => void;
  private startHandler: () => void;
  private easeFunction = (t: number) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

  constructor(private startedValue: number[], private time: number = 1000) {
    this.currentValue = startedValue;
  }

  transitionTo(finishValue: number[], time: number = this.time) {
    requestAnimationFrame((t) => {
      this.finishValue = finishValue.slice();
      this.startedValue = this.currentValue.slice();
      if (this.startedValue.length != this.finishValue.length) {
        throw new Error('Incorrect number of elements');
      }
      this.startedAt = t;
      this.finishedAt = t + time;
      this.startHandler();
      if (!this.running) {
        this.running = true;
        this.handleRAF(t);
      }
    });
  }

  onStart(fn: () => void) {
    this.startHandler = fn;
  }

  onChange(fn: (value: number[]) => void) {
    this.changeHandler = fn;
  }

  onFinish(fn: () => void) {
    this.finishHandler = fn;
  }

  private handleRAF(time: number) {
    if (time <= this.finishedAt) {
      const percent = (time - this.startedAt) / (this.finishedAt - this.startedAt);
      this.currentValue = this.startedValue.map(
        (_, i) => this.startedValue[i] + this.easeFunction(percent) * (this.finishValue[i] - this.startedValue[i]));
      this.fireChange();
      if (!this.reachedFinishedValues()) {
        requestAnimationFrame(t => this.handleRAF(t));
      }
    } else {
      // Fires exact last value
      if (!this.reachedFinishedValues()) {
        this.currentValue = this.finishValue.slice();
        this.fireChange();
      }
    }
  }

  private fireChange() {
    this.changeHandler(this.currentValue);
    if (this.reachedFinishedValues()) {
      this.running = false;
      this.finishHandler();
    }
  }

  private reachedFinishedValues() {
    return this.currentValue.every((_, i) => this.currentValue[i] === this.finishValue[i]);
  }
}