export function flatMap<T, R>(xs: T[], fn: (x: T) => R[]): R[] {
  const result: R[] = [];
  for (let i = 0; i < xs.length; i++) {
    result.push(...fn(xs[i]));
  }
  return result;
}

export function assertUnreachable(value: never, message: string): never {
  throw new Error(message);
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/**
 * @todo research native clipboard API bug
 *       "Bug, it changes text"
 */
export async function copyTextToClipboard(text: string) {
  // if (navigator.clipboard) {
  //   await navigator.clipboard.writeText(link);
  // } else {
  const tmpElement = document.createElement('textarea');
  tmpElement.value = text;
  document.body.appendChild(tmpElement);
  tmpElement.select();
  document.execCommand('copy');
  document.body.removeChild(tmpElement);
  // }
}

export class CancellablePromiseError extends Error {
  constructor(private readonly inCancelled: boolean, message?: string) {
    super(message);
  }
}

export function makeCancelable<T>(promise: Promise<T>) {
  let isCanceled = false;
  const wrappedPromise =
          new Promise<T>((resolve, reject) => {
            promise
              .then(
                val => (isCanceled
                  ? reject(new CancellablePromiseError(true))
                  : resolve(val))
              )
              .catch(
                error => (isCanceled
                  ? reject(new CancellablePromiseError(true))
                  : reject(error))
              );
          });
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

export function random(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function toTimeZonedISODate(date: Date) {
  const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num: number) {
          const norm = Math.floor(Math.abs(num));
          return (norm < 10 ? '0' : '') + norm;
        };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(tzo / 60) +
    ':' + pad(tzo % 60);
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}
