import React from 'react';
import {ActiveCamera} from '../../../model/ConfigurationState';

import './Switch.scss';

interface IProps {
  cameraView: ActiveCamera;
  onChange: () => void;
}

const Switch: React.FC<IProps> = ({cameraView, onChange}) => {
  return (
    <div className="switch">
      <input id="567" name="view" type="radio" className="switch__input" checked={cameraView === ActiveCamera.Front}
             onChange={onChange}/>
      <label htmlFor="567" className="switch__label">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <path d="M28,29H12a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H28a1,1,0,0,1,1,1V28A1,1,0,0,1,28,29ZM13,27H27V13H13Z"/>
        </svg>
      </label>
      <input id="5678" name="view" type="radio" className="switch__input" checked={cameraView === ActiveCamera.Perspective}
             onChange={onChange}/>
      <label htmlFor="5678" className="switch__label">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <path d="M30,14.7a1,1,0,0,0-.09-.39l-.05-.1a1.18,1.18,0,0,0-.23-.27h0a1.2,1.2,0,0,0-.32-.16l-9-2.57a.9.9,0,0,0-.54,0l-9,2.57a1,1,0,0,0-.73,1,.8.8,0,0,0,0,.15V26.3a1,1,0,0,0,.72,1l9,2.57a.84.84,0,0,0,.27,0,.9.9,0,0,0,.28,0l9-2.57a1,1,0,0,0,.73-1V14.7ZM20,13.17l5.39,1.54L20,16.24,14.62,14.7ZM12,16l7,2v9.51l-7-2Zm16,9.5-7,2V18l7-2Z"/>
        </svg>
      </label>
      <div className="switch__circle"/>
    </div>
  );
}

export default Switch;

