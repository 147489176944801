import * as React from 'react';
import classNames from 'classnames';

import { ReactComponent as Logo } from './images/logo.svg';

import './CanvasLogicLogo.scss';

interface IProps {
  className?: string;
}

const CanvasLogicLogo: React.FC<IProps> = ({ className }) => {
  return (
    <small className={classNames('cl-logo', className)}>
      <a href='https://www.canvaslogic.de/' target='_blank'>
        <Logo className='cl-logo__image' />
      </a>
    </small>
  );
};

export default CanvasLogicLogo;
