import React, { Component } from 'react';
import classNames from 'classnames';
import { Redirect, Route, Switch } from 'react-router';
import { RawIntlProvider } from 'react-intl';
import { observer } from 'mobx-react';

import { ROUTES } from './routes';

import RootStore from './stores/RootStore';
import Report from './pages/Report/Report';
import { LoadingMask } from './components/LoadingMask/LoadingMask';
import Configurator from './pages/Configurator/Configurator';

import './App.scss';

@observer
class App extends Component {

  async componentDidMount() {
    await RootStore.init();
  }

  render() {
    console.log('Env', process.env);
    const { isLoading, localization } = RootStore;

    return isLoading ? (
      <LoadingMask/>
    ) : (
      <div className={classNames('layout', { 'no-prices': process.env.REACT_APP_CL__FEATURE__PRICING !== 'ON' })}>
        <RawIntlProvider value={localization.intl}>
            <main className="main">
              <Switch>
                <Route path={ROUTES.configurator} exact component={Configurator}/>
                <Route path={ROUTES.report} exact component={Report}/>
                <Redirect to={ROUTES.configurator}/>
              </Switch>
            </main>
        </RawIntlProvider>
      </div>
    );
  }
}

export default App;
