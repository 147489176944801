import * as React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { OverviewViewStore } from '../../../stores/ConfiguratorViews/OverviewViewStore';
import Button from '../../../../src/components/buttons/Button/Button';
import SmallButton from '../../../../src/components/buttons/SmallButton/SmallButton';
import Icon from '../../../../src/components/Icon/Icon';
import { ElementsList } from '../../../components/ElementsList/ElementsList';
import SettingsList from '../../../../src/components/SettingsList/SettingsList';

import './Overview.scss';

export interface IProps {
  store: OverviewViewStore;
}

@observer
export default class Overview extends Component<IProps> {
  render() {
    const { store } = this.props;

    return (
      <div className="overview">
        <div className="overview__panel">
          <Button className="overview__panel__button overview__panel__button--persistent overview__add-element-button" onClick={store.handleAddComponent}>
            <Icon type='plus_24' />
            <FormattedMessage id="ui.elements.add" />
          </Button>
          <SmallButton
            className="overview__panel__button"
            onClick={store.handleCloseSidebar}
          >
            <Icon type='close' />
          </SmallButton>
        </div>
        <div className="overview__container">
          <SettingsList model={store.configuratorStore.model} className="overview__column" onChange={store.handleChangeOption} />
          <ElementsList store={store.configuratorStore} className="overview__column" />
        </div>
      </div>
    );
  }
}
