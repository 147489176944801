export function getModelValue(model: any, path: string) {
  const paths = path.split('.');
  let result: any = model;
  for (let i = 0; i < paths.length; i++) {
    result = result[paths[i]];
  }
  return result;
}


/***
 * Query value from models.
 * @param rootModel - used when path starts with $
 * @param relativeModel - used when path w/o $
 * @param path - $.test - for rootModel, property.value - for relativeModel
 */
export function queryValue(rootModel: any, relativeModel: any, path: string) {
  if (!path || !path.length) {
    return undefined;
  }
  if (path[0] === '$') {
    return getModelValue(rootModel, path.slice(2));
  } else {
    return getModelValue(relativeModel, path);
  }
}