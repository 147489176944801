import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { getOptionId, Option } from '@canvas-logic/engine';
import { INameImage } from '../../../schema';

import SmallButton from '../../../../src/components/buttons/SmallButton/SmallButton';
import Icon from '../../../../src/components/Icon/Icon';
import { EditOptionViewStore } from '../../../stores/ConfiguratorViews/EditOptionViewStore';
import Button from '../../../components/buttons/Button/Button';
import MaterialItem from '../../../components/MaterialItem/MaterialItem';

import './EditOption.scss';


interface IProps {
  store: EditOptionViewStore;
}

@observer
export default class EditOption extends React.Component<IProps> {
  render() {
    const { store } = this.props;

    return (
      <div className="edit-option">
        <div className="edit-option__panel">
          <SmallButton
            className="edit-option__panel__button"
            color="transparent"
            onClick={store.handleBack}
          >
            <Icon type='leftArrow'/>
          </SmallButton>
          <FormattedMessage id={`ui.settings.properties.${store.editingProperty}`}/>
          <SmallButton
            color="transparent"
            disabled
          />
        </div>
        <div className="edit-worktop__container">

          <h2 className="edit-option__heading">
            <FormattedMessage id="ui.settings.texture"/>
          </h2>
          <ul className="edit-option__list">
            {
              (store.availableOptions as Option<INameImage>[]).map(option => (
                <MaterialItem
                  prefix={`content.property.${store.editingProperty}.`}
                  material={option.model}
                  selected={getOptionId(option.model) === getOptionId(store.selectedOption)}
                  onClick={() => store.changeKitchenMaterial(option)}
                  component="li"
                />
              ))
            }
          </ul>
        </div>
        <div className="edit-option__panel--bottom">
          <Button className="edit-option__add-button" onClick={store.handleAddComponent}>
            <Icon type='plus_24'/>
            <FormattedMessage id="ui.elements.add"/>
          </Button>
        </div>
      </div>
    );
  }
}
