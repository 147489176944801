import * as React from 'react';
import { Component } from 'react';
import ItemCard from '../ItemCard/ItemCard';
import { ISkinal } from '../../schema';

interface IProps {
  skinal: ISkinal;
  onClick?(skinal: ISkinal): void;
}

export default class SkinalItem extends Component<IProps> {
  render() {
    const { skinal } = this.props;
    return <ItemCard key={'skinal'} item={skinal} installed={0} onClick={this.onClick}/>
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.skinal);
    }
  }
}
