import { ConfiguratorStore, IConfiguratorState } from '../../../stores/ConfiguratorStore';
import { biRestService } from '../actions/BIRestService';
import ActionTracker, { IActionRecord } from './ActionTracker';

/**
 * Analytics-bound action tracker (producer).
 * Sends recorded actions via service to remote API.
 *
 * @see ActionTracker
 * @see BIRestService
 */
export default class BIActionTracker extends ActionTracker {
  constructor(configuratorStore: ConfiguratorStore) {
    super(configuratorStore);
    this.startRemoteRecording();
  }

  initConfigurator(configuratorState: IConfiguratorState): void {
    biRestService.startSession(configuratorState.deviceType);

    super.initConfigurator(configuratorState);
  }

  quit(): void {
    super.quit();

    this.pushActions();
    biRestService.endSession();
  }

  private startRemoteRecording() {
    setInterval(async () => {
      await this.pushActions();
    }, 5000);
  }

  private async pushActions() {
    if (this.actions.length === 0) {
      return;
    }

    const actions = this.actions.slice();
    this.actions = [];
    await BIActionTracker.sendActionsToServer(actions);
  }

  private static async sendActionsToServer(actions: IActionRecord[]) {
    await biRestService.recordActions(actions);
  }
}
