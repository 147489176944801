import { RootStore } from '../RootStore';
import { ConfigurationViewStoreName, ConfiguratorStore, ViewStore } from '../ConfiguratorStore';
import { action, computed } from 'mobx';
import { FlatStructuralTransformer } from '@canvas-logic/engine';
import { INameImage, IWorktop } from '../../schema';
import { ChangeWorktopDepthMutator } from '../../mutators/ChangeWorktopDepthMutator';
import { ChangeWorktopMaterialMutator } from '../../mutators/ChangeWorktopMaterialMutator';
import { NotificationID, NotificationType } from '../NotificationsStore';

export class EditWorktopViewStore implements ViewStore {
  constructor(private rootStore: RootStore, private configuratorStore: ConfiguratorStore) {
  }

  readonly name = ConfigurationViewStoreName.EDIT_WORKTOP;

  @action.bound
  handleBack() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.OVERVIEW);
  }

  @computed
  get worktop() {
    return this.configuratorStore.worktop;
  }

  @computed
  get depths() {
    const worktops = this.configuratorStore.engine.optionValuesByPath(this.configuratorStore.model, 'worktop', new FlatStructuralTransformer<IWorktop>()) ?? [];
    return [...new Set(worktops.map(w => w.model.depth))]
  }

  @computed
  get worktopMaterials() {
    const worktops = this.configuratorStore.engine.optionValuesByPath(this.configuratorStore.model, 'worktop', new FlatStructuralTransformer<IWorktop>()) ?? [];
    const materials: INameImage[] = [];
    for (let worktop of worktops) {
      if (!materials.some(m => m.name === worktop.model.name && m.image === worktop.model.image)) {
        materials.push({
          name: worktop.model.name,
          image: worktop.model.image
        })
      }
    }
    return materials;
  }

  @action.bound
  async changeWorktopDepth(depth: number) {
    const mutator = new ChangeWorktopDepthMutator(depth);
    await this.configuratorStore.applyMutator(mutator);
  }

  @action.bound
  changeWorktopMaterial(material: INameImage) {
    const mutator = new ChangeWorktopMaterialMutator(material.name);
    this.configuratorStore.applyMutator(mutator);
  }

  @action.bound
  handleAddComponent() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.SELECT_CATEGORY);
  }
}
