import React from 'react';
import { FormattedMessage } from 'react-intl';
import Pill, { PillAlignment } from '../Pill/Pill';
import { Converter } from '../../helpers/Converter';

import './WidthFilter.scss';
import PillList from '../PillList/PillList';

interface IProps {
  active: number | null;
  width: number[];
  onChange: (value: number | null) => void;
}

const WidthFilter: React.FC<IProps> = ({ active, onChange, width }) => {
  return (
    <div className="filter-width">
      <span className="filter-width__label">
        <FormattedMessage id="ui.width"/>
      </span>
      <PillList className="filter-width__pill-list">
        {width.map((item) =>
          <li key={item}>
            <Pill active={active ? active === item : false}
                  alignment={PillAlignment.Center}
                  onClick={() => onChange((active && active === item) ? null : item)}
            >
              <div>{Converter.mmToCm(item)}</div>
              <div className="filter-width__measure"><FormattedMessage id="ui.units.cm"/></div>
            </Pill>
          </li>)}
      </PillList>
    </div>);
};

export default WidthFilter;
