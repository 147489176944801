import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ElementViewStore } from '../../stores/ConfiguratorViews/ElementViewStore';
import RelatedProductItem from '../RelatedProductItem/RelatedProductItem';

import './ElementAdditionals.scss';
import {getOptionId} from "@canvas-logic/engine";
import { IAdditionalAppliance } from '../../schema';

interface IElementAdditionalsProps {
  store: ElementViewStore;
  additionals: (IAdditionalAppliance | null)[];
  onSelect: (additionalIndex: number) => void;
  onRemove: (additionalIndex: number) => void;
}

@observer
export default class ElementAdditionals extends React.Component<IElementAdditionalsProps> {
  render() {
    const { additionals } = this.props;

    return (
      <div>
        <h2 className="related-products__heading">
          <FormattedMessage id="ui.additional.header"/>
        </h2>
        <ul className="related-products__list">
          {additionals.map((additional, index: number) =>
            this.renderAdditional(additional, index)
          )}
        </ul>
      </div>
    );
  }

  renderAdditional(additional: (IAdditionalAppliance | null), index: number): any {
    if (additional) {
      if (additional.visibility === 'conf' || additional.visibility === 'both') {
        return this.renderUnitAdditional(additional, index);
      } else {
        return null;
      }
    } else {
      const emptyAdditional = this.props.store.getEmptyAdditionalForSelectedUnit(index);
      if (emptyAdditional) {
        return this.renderEmptyAdditional(emptyAdditional, index);
      }
    }
  }

  renderEmptyAdditional(emptyAdditional: IAdditionalAppliance, index: number) {
    const { onSelect } = this.props;

    return (
      <div key={'additional_' + index}>
        <div className="related-products__action">
          <span className="related-products__type">
            <FormattedMessage id={`content.additional.${emptyAdditional.additionalApplianceType}`}/>
          </span>
          <button className="related-products__select" onClick={() => onSelect(index)}>
            <FormattedMessage id="ui.select"/>
          </button>
        </div>
        <div className="empty-additional">
          <FormattedMessage id="ui.additional.empty"/>
        </div>
      </div>
    );
  }

  renderUnitAdditional(additional: IAdditionalAppliance, index: number) {
    const { onSelect, onRemove } = this.props;

    return (
      <div key={getOptionId(additional)}>
        <div className="related-products__action">
          <span className="related-products__type"><FormattedMessage
            id={`content.additional.${additional.additionalApplianceType}`}/></span>
          <button className="related-products__select" onClick={() => onRemove(index)}>
            <FormattedMessage id="ui.remove"/>
          </button>
          <button className="related-products__select" onClick={() => onSelect(index)}>
            <FormattedMessage id="ui.change"/>
          </button>
        </div>
        <RelatedProductItem
          item={additional}
          onSelect={() => onSelect(index)}/>
      </div>
    );
  }
}
