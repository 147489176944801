import * as React from 'react';
import classNames from 'classnames';

import './ProgressBar.scss'

interface IProps {
  progress: number;
  className?: string;
}

const ProgressBar: React.FC<IProps> = ({ progress, className}) => {

  return (
    <div className={classNames('progress-bar', className)} role="region" aria-live="polite">
      <div className='progress-bar__track'>
        <div
          role="progressbar"
          aria-valuenow={ progress }
          aria-valuemin={0}
          aria-valuemax={100}
          tabIndex={0}
          className='progress-bar__progress'
          style={ { width: `${progress}%` } } />
      </div>
    </div>
  );
};

export default ProgressBar;
