"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function singlePropertyLookupTypeGuard(value) {
  return value && value['option'] && value['property'];
}

exports.singlePropertyLookupTypeGuard = singlePropertyLookupTypeGuard;

function multiPropertiesLookupRecordTypeGuard(value) {
  return value && value['option'] && value['properties'] && !value['key'];
}

exports.multiPropertiesLookupRecordTypeGuard = multiPropertiesLookupRecordTypeGuard;

function extendedSinglePropertyLookupTypeGuard(value) {
  return value && value['option'] && value['key'] && value['properties'];
}

exports.extendedSinglePropertyLookupTypeGuard = extendedSinglePropertyLookupTypeGuard;

function oneOptionLookupRecordTypeGuard(value) {
  return singlePropertyLookupTypeGuard(value) || multiPropertiesLookupRecordTypeGuard(value) || extendedSinglePropertyLookupTypeGuard(value);
}

exports.oneOptionLookupRecordTypeGuard = oneOptionLookupRecordTypeGuard;