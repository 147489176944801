import { action } from 'mobx';
import { OptionGroup } from '@canvas-logic/engine';

import { ConfigurationViewStoreName, ConfiguratorStore, ViewStore } from '../ConfiguratorStore';

import { RootStore } from '../RootStore';

export class SelectCategoryViewStore implements ViewStore {
  name = ConfigurationViewStoreName.SELECT_CATEGORY;

  constructor(private rootStore: RootStore, private configuratorStore: ConfiguratorStore) { }

  @action.bound
  handleCloseSidebar() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.OVERVIEW);
    this.configuratorStore.toggleSidebar(false);
  }

  @action.bound
  handleSelectGroup(id: string) {
    this.configuratorStore.navigateToSelectElement(id);
  }

  get groups(): OptionGroup[] {
    return this.configuratorStore.availableGroups;
  }
}
