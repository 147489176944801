import * as React from 'react';
import classNames from 'classnames';

import './SmallButton.scss'

/**
 * @todo: merge with `IconButton`
 */

interface IProps {
  className?: string;
  color?: 'white' | 'primary' | 'transparent';
  disabled?: boolean;
  onClick?: () => void;
}

const SmallButton: React.FC<IProps> = ({ className, color, disabled, onClick, children }) => {
  return (
    <button
      className={classNames('small-button', {
      'small-button--primary': color === 'primary',
        'small-button--transparent': color === 'transparent',
    }, className)}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SmallButton;
