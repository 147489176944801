import TypeMatcher, { WithOpeningSide } from './TypeMatcher';

/**
 * Determine specific entity features by its structure.
 * Works kinda like type guard.
 *
 * @see UnitMatcher
 */

export class StructuralTypeMatcher<T> extends TypeMatcher<T> {
  withOpeningSide(): StructuralTypeMatcher<T & WithOpeningSide> {
    if (typeof (this.entity as any)?.openingSide !== 'undefined') {
      return new StructuralTypeMatcher(this.entity as any, true);
    } else {
      return new StructuralTypeMatcher(this.entity as any, false);
    }
  }
}
