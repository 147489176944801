import { ShareImageParams, ShareOn } from '../../model/Model';
import { ConfiguratorStore } from '../../stores/ConfiguratorStore';

export class ShareLinkService {
  constructor(private configuratorStore: ConfiguratorStore) {
  }
  async shareOn(target: ShareOn, imageUrl?: string, params?: ShareImageParams) {
    if (process.env.REACT_APP_CL__FEATURE_SHARE_LINK) {
      const shareLinkServiceUrl = process.env.REACT_APP_CL__SHARE_LINK_SERVICE_URL;
      if (imageUrl && !params) {
        throw Error('External image share parameters have to be defined');
      }

      let imageToShare = imageUrl;
      const imageParams = {
        width: 1200,
        height: 628
      };

      if (!imageToShare) {
        await this.configuratorStore.makeScreenshot();
        imageToShare = this.configuratorStore.canvasURL;
      }

      const response = await fetch(shareLinkServiceUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          image: imageToShare,
          redirectUrl: encodeURIComponent(this.configuratorStore.shareDirectLink()),
          title: 'CanvasLogic',
          description: 'Kitchen configurator',
          width: imageParams.width,
          height: imageParams.height
        })
      });
      const data = await response.json();
      if (target === ShareOn.FB) {
        const href = encodeURIComponent(shareLinkServiceUrl + data.id);
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${href}`, 'pop', 'width=600, height=400, scrollbars=no')
      }
    }
  }
}