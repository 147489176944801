import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { AdditionalAlignment, IAdditionalAppliance } from '../../schema';

import './RelatedProductItem.scss';

interface IProps {
  item: IAdditionalAppliance;
  selected?: boolean;
  onSelect: () => void;
  className?: string;
}

export default class RelatedProductItem extends Component<IProps> {
  descriptionWrapRef: React.RefObject<HTMLDivElement> = React.createRef();
  descriptionRef: React.RefObject<HTMLParagraphElement> = React.createRef();

  componentDidMount(): void {
    const entry = this.descriptionRef.current;
    if (entry && entry.scrollHeight > entry.clientHeight) {
      entry.classList.add('truncated');
    } else if (this.descriptionWrapRef.current) {
      this.descriptionWrapRef.current.classList.add('expanded');
    }
  }

  render() {
    const { item, onSelect, selected, className } = this.props;
    const showPrices = process.env.REACT_APP_CL__FEATURE__PRICING === 'ON';
    const imageClasses = classNames('related-products-card__image-wrapper', { 'mirror-x': item.alignment === AdditionalAlignment.L });
    return (
      <li className={classNames('related-products-card', selected && 'selected', className)} onClick={onSelect}>
        {!!className && <div className="card__status"><FormattedMessage id="ui.additional.forbidden"/></div>}
        <div className={imageClasses}>
          <img className="related-products-card__image" src={item.itemImage} alt=""/>
        </div>
        <h5 className="related-products-card__heading">
          <FormattedMessage id={item.name}/>
        </h5>
        <div className="related-products-card__data">
          <p className="related-products-card__type-number">
            <FormattedMessage id="ui.elements.typeNumber"/>: {item.typeNo}
          </p>
          {showPrices && <p className="related-products-card__price">{item.price} €</p>}
        </div>
        <div className="related-products-card__description" ref={this.descriptionWrapRef}>
          <p ref={this.descriptionRef}><FormattedMessage id={item.description}/></p>
          <button type="button" className="elements-card__more-info" onClick={this.handleShowMoreInfo}>
            <FormattedMessage id="ui.elements.description.more"/>
          </button>
          <button type="button" className="elements-card__less-info" onClick={this.handleShowLessInfo}>
            <FormattedMessage id="ui.elements.description.less"/>
          </button>
        </div>
      </li>
    );
  }

  handleShowMoreInfo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.descriptionWrapRef.current) {
      this.descriptionWrapRef.current.classList.add('expanded');
    }
  };

  handleShowLessInfo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.descriptionWrapRef.current) {
      this.descriptionWrapRef.current.classList.remove('expanded');
    }
  };
}
