import { getOptionId, MutatorContext, PModel, ValidationResult } from '@canvas-logic/engine';
import { UnitMatcher } from '../services/UnitMatcher';
import { IKitchen, IMountedUnit } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';
import LinkedElementsService from '../services/LinkedElementsService';
import LinkedElementModelEvent, { LinkedElementModelEventType } from '../helpers/LinkedElementModelEvent';

export class RemoveAdditionalMutator extends KitchenBaseMutator {
  constructor(private unit: IMountedUnit, private additionalIndex: number) {
    super();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, model: IKitchen): [PModel<IKitchen>, ValidationResult] {
    let resultModel = model;

    const unit = resultModel.units.find(unit => unit.position === this.unit.position && unit.unit.unitKind === this.unit.unit.unitKind);
    if (!unit) {
      return [resultModel, ValidationResult.Error('ui.error.no_original')];
    }
    new UnitMatcher(unit.unit)
      .withAdditionals()
      .run(unit => {
        const currentAdditional = unit.additionals[this.additionalIndex];
        const linkedElementsService = new LinkedElementsService(context, model, this.unit);

        if (currentAdditional) {
          const childToBeRemoved = linkedElementsService.findInstalledChildElement(currentAdditional);
          if (childToBeRemoved) {
            if (childToBeRemoved.child && childToBeRemoved.child !== getOptionId(currentAdditional)) {
              return [model, ValidationResult.Error(`Child additional (id: ${getOptionId(childToBeRemoved)}) has own child (id: ${childToBeRemoved.child}) which is not his parent (id: ${getOptionId(currentAdditional)}.`)];
            }

            const childIndex = linkedElementsService.findAdditionalIndex(getOptionId(childToBeRemoved));
            if (childIndex !== undefined) {
              const child = unit.additionals[childIndex];
              if (child) {
                context.notifications.notify(LinkedElementModelEvent.EVENT_NAME, new LinkedElementModelEvent(LinkedElementModelEventType.REMOVE, model, child));
              }
              unit.additionals[childIndex] = null;
            }
          }

          unit.additionals[this.additionalIndex] = null;
        }
      });
    return [resultModel, ValidationResult.Valid()];
  }

}
