import * as React from 'react';
import classNames from 'classnames';

import { ReactComponent as Button } from './images/button.svg';

import './GooglePlayButton.scss';

interface IProps {
  className?: string;
}

const GooglePlayButton: React.FC<IProps> = ({ className }) => {
  return (
    <a target='_blank' className={classNames('gplay-button', className)}>
      <Button className='gplay-button__image'/>
    </a>
  );
};

export default GooglePlayButton;
