import { action } from 'mobx';

import { ConfigurationViewStoreName, ConfiguratorStore, ViewStore } from '../ConfiguratorStore';
import { KitchenOption } from './EditOptionViewStore';
import { RootStore } from '../RootStore';

export class OverviewViewStore implements ViewStore {
  name = ConfigurationViewStoreName.OVERVIEW;

  /**
   * @todo fix access for `rootStore` and `configuratorStore`
   */
  constructor(public rootStore: RootStore, public configuratorStore: ConfiguratorStore) {
  }

  @action.bound
  handleCloseSidebar() {
    this.configuratorStore.toggleSidebar(false);
  }

  @action.bound
  handleAddComponent() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.SELECT_CATEGORY);
  }

  @action.bound
  handleChangeOption(property: KitchenOption) {
    switch (property) {
      case KitchenOption.WORKTOP: {
        this.configuratorStore.navigateToEditWorktop();
        break;
      }
      case KitchenOption.UPSTAND: {
        this.configuratorStore.navigateToEditOption(property, 'upstand_material');
        break;
      }
      default:
        this.configuratorStore.navigateToEditOption(property);
    }
  }
}
