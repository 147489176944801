import {ConfiguratorStore, DeviceType} from '../../stores/ConfiguratorStore';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { Object3D, Box3, Vector3, Mesh, MeshPhongMaterial, BoxBufferGeometry } from 'three';

const PLINTH_HEIGHT = 0.15;
const REDUCTION = 1000;

export class ArService {
  constructor(private configuratorStore: ConfiguratorStore) {
  }
  async generateArLink() {
    if (process.env.REACT_APP_CL__FEATURE_AR) {
      const gltf = await exportScene(this.alignScene(this.configuratorStore.scene));
      const json = JSON.stringify(gltf);
      const url = process.env.REACT_APP_CL__STORAGE_SERVICE_URL;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        body: json
      });
      const data = await response.json();
      return this.configuratorStore.deviceType === DeviceType.HANDLET
          ? url + `link/${data.id}`
          : `com.nobilia.ARcore.app:${data.id}`;
    } else {
      return '';
    }
  }

  alignScene(scene: Object3D): Object3D {
    let sceneToExport: Object3D = scene.clone();
    let bbox = new Box3();
    let center = new Vector3();
    const size = new Vector3();

    bbox.setFromObject(sceneToExport);
    bbox.getCenter(center);
    bbox.getSize(size);

    sceneToExport.position.x += -center.x;
    sceneToExport.position.z += -size.z / 2;
    sceneToExport.position.y = PLINTH_HEIGHT;

    bbox.setFromObject(sceneToExport);
    bbox.getCenter(center);

    let geometry = new BoxBufferGeometry(size.x / REDUCTION, size.y / REDUCTION, size.z / REDUCTION);
    let material = new MeshPhongMaterial({opacity: 0, transparent: true, visible: false});
    let mesh = new Mesh(geometry, material);
    sceneToExport.add(mesh);
    mesh.name = "bounds";
    mesh.position.set(center.x + size.x / 2, center.y - PLINTH_HEIGHT, center.z + size.z / 2);
    return sceneToExport;
  }
}

async function exportScene(scene: Object3D) {
  const exporter = new GLTFExporter();
  return new Promise<object>((resolve) => {
    exporter.parse(scene, (gltf) => {
      resolve(gltf);
    }, {forceIndices: true});
  });
}
