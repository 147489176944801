import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { SelectCategoryViewStore } from '../../../stores/ConfiguratorViews/SelectCategoryViewStore';
import SmallButton from '../../../../src/components/buttons/SmallButton/SmallButton';
import ElementGroup from '../../../../src/components/ElementGroup/ElementGroup';
import Icon from '../../../../src/components/Icon/Icon';

import './SelectCategory.scss';

interface IProps {
  store: SelectCategoryViewStore;
}

@observer
class SelectCategory extends React.Component<IProps> {
  render() {
    const { store } = this.props;

    return (
      <div className="select-category">
        <div className="select-category__panel">
          <SmallButton onClick={store.handleCloseSidebar}>
            <Icon type="close" />
          </SmallButton>
        </div>
        <div className="select-category__container">
          <div className="select-category__column">
            <h1 className="select-category__heading"><FormattedMessage id="ui.elements.adding" /></h1>
            <p className="select-category__description">
              <FormattedMessage id="ui.elements.select_group" />
            </p>
            <ul className="select-category__list">
              {store.groups.map(group => <ElementGroup group={group} key={group._id} onClick={store.handleSelectGroup} className='select-category__item'/>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectCategory;
