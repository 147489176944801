import { action, computed, observable } from 'mobx';
import { FlatStructuralTransformer, getOptionId, Option, OptionGroup } from '@canvas-logic/engine';

import { InsertUnit, UnitInsertPosition } from '../../model/Model';
import { AddSkinalMutator } from '../../mutators/AddSkinalMutator';
import { AddUnitMutator } from '../../mutators/AddUnitMutator';
import { ConfigurationViewStoreName, ConfiguratorStore, DeviceType, ViewStore } from '../ConfiguratorStore';
import { RootStore } from '../RootStore';
import { layoutService } from '../../services/LayoutService';
import { NotificationID, NotificationType } from '../NotificationsStore';
import { notEmpty } from '../../helpers/utils';
import { domainService } from '../../services/DomainService';
import { AdditionalAlignment, IAdditionalAppliance, IMountedUnit, IPlate, ISkinal, Unit, UnitKind } from '../../schema';
import { AddUnitAction } from '../../features/ActionTracker/actions/Action';
import { UnitMatcher } from '../../services/UnitMatcher';

export enum SelectElementState {
  SELECTION,
  PLACEMENT
}

export class SelectElementViewStore implements ViewStore {
  name = ConfigurationViewStoreName.SELECT_ELEMENT;

  private readonly DEFAULT_FILTER = 600;
  @observable private _widthFilter: number | null = null;

  @observable state: SelectElementState = SelectElementState.SELECTION;
  @observable elementToInsert: Option<Unit> | null = null;
  @computed get worktopDepth() {
   return this.configuratorStore.model.worktop[0].depth;
  }

  @computed get skinal() {
    return this.configuratorStore.model.skinal;
  }

  constructor(private rootStore: RootStore, private configuratorStore: ConfiguratorStore, private selectedGroup: string) {
  }

  @action.bound
  handleCancel() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.OVERVIEW);
    this.configuratorStore.toggleSidebar(false);
  }

  @action.bound
  handleBack() {
    if (this.configuratorStore.deviceType === DeviceType.HANDLET && this.state === SelectElementState.PLACEMENT) {
      this.state = SelectElementState.SELECTION;
      this.elementToInsert = null;
    } else {
      this.configuratorStore.navigateTo(ConfigurationViewStoreName.SELECT_CATEGORY);
    }
  }

  @computed
  get installedUnits() {
    const units = this.configuratorStore.model.units ?? [];
    return units.map(mountedUnit => getOptionId(mountedUnit.unit));
  }

  @computed
  get widthFilter() {
    if (this._widthFilter === null) {
      return this.availableWidths.includes(this.DEFAULT_FILTER) ? this.DEFAULT_FILTER : null;
    } else {
      return this._widthFilter;
    }
  }

  @computed
  get filteredUnits() {
    if (!this.widthFilter) {
      return this.availableUnits;
    }

    return this.availableUnits.filter(unit => unit.model.width === this.widthFilter);
  }

  @computed
  get availableWidths() {
    return [...new Set(this.availableUnits.map(unit => unit.model.width))].sort((a, b) => { return a - b; });
  }

  @action.bound
  applyFilter(width: number | null) {
    this._widthFilter = width;
    this.deselectElement();
  }

  @computed
  get availableUnits() {
    const groups = this.configuratorStore.availableGroups;
    const availableUnitsInGroup = groups.find((item: OptionGroup) => item._id === this.selectedGroup)?.children ?? [] as unknown[];
    return availableUnitsInGroup as (Option<Unit> | Option<IPlate>)[];
  }

  @action.bound
  handleSelectElement(element: Option<Unit> | Option<IPlate>) {
    if (this.elementToInsert?._id === element._id) {
      this.deselectElement()
    } else {
      if (isElementSkinal(element)) {
        const mutator = new AddSkinalMutator(element);
        // @todo: figure out IPlate and ISkinal relation at this point
        this.rootStore.actionTracker.addSkinal(element.model as unknown as ISkinal);
        this.configuratorStore.applyMutator(mutator);
        this.configuratorStore.toggleUnit(null);
      } else {
        // Automatically install component if there is no other components on these line (for top - top, for bottom/tall - bottom/tall)
        const hasSomeSelectedUnitType = this.configuratorStore.model.units.some((u: IMountedUnit) =>
          layoutService.unitsOnSameLine(u.unit.unitKind as UnitKind, element.model.unitKind as UnitKind));
        if (!hasSomeSelectedUnitType) {
          this.insertUnitAt(element, InsertUnit.Left());
        }
        this.elementToInsert = element;
        this.state = SelectElementState.PLACEMENT;
      }
    }
  }

  @action.bound
  private deselectElement() {
    this.elementToInsert = null;
    this.state = SelectElementState.SELECTION;
  }

  isPositionForSelectedValid(position: UnitInsertPosition) {
    if (!this.elementToInsert) {
      return false;
    }
    try {
      for (let unit of this.getUnitSetups(this.elementToInsert)) {
        const mutator: AddUnitMutator = new AddUnitMutator(position, unit);
        const [_, validationResult] = this.configuratorStore.engine.mutate(this.configuratorStore.model, mutator);
        if (validationResult.isInvalid) {
          console.warn(validationResult.errorMessage)
        } else {
          return true;
        }
      }
      return false;
    } catch (e) {
      console.error(e.message);
      return false;
    }
  }

  @action
  async insertSelectedUnitAt(position: UnitInsertPosition) {
    if (!this.elementToInsert) {
      return;
    }

    this.insertUnitAt(this.elementToInsert, position);
  }

  @action
  private async insertUnitAt(unit: Option<Unit>, position: UnitInsertPosition) {
    const action = this.rootStore.actionTracker.addUnit(unit, position);
    const isValid = await this.applyInsertUnitAtAction(action);
    if (isValid) {
      const additionalTypes = domainService.unitAdditionals(unit.model).filter(notEmpty);
      if (additionalTypes.length > 0) {
        await this.rootStore.notification({
          id: NotificationID.RELATED_PRODUCTS,
          type: NotificationType.INFO,
          modal: true,
          content: {
            raw: this.rootStore.localization.formatHTMLMessage('ui.additional.notification'),
          }
        });
        this.rootStore.muteNotification(NotificationID.RELATED_PRODUCTS);
      }
    }
  }

  async applyInsertUnitAtAction(action: AddUnitAction) {
    const { position, product: { id } } = action;
    const unitBase = this.configuratorStore.getUnitOptionById(id);
    for (let unit of this.getUnitSetups(unitBase)) {
      const mutator: AddUnitMutator = new AddUnitMutator(position, unit);
      const [_, validationResult] = this.configuratorStore.engine.mutate(this.configuratorStore.model, mutator);
      if (validationResult.isValid) {
        return await this.configuratorStore.applyMutator(mutator);
      }
    }
    return false;
  }

  private* getUnitSetups(unit: Option<Unit>) {
    // First candidate is default unit
    yield unit;
    const model = unit.model;
    const additionals = domainService.unitAdditionals(model).slice();
    new UnitMatcher(model)
      .withAdditionals()
      .run(model => model.additionals = additionals);

    const incompatibleAdditionalIndex = additionals.findIndex(additional => additional?.alignment !== AdditionalAlignment.NONE);
    const incompatibleAdditional = additionals[incompatibleAdditionalIndex];
    let candidates = this.configuratorStore.engine.optionValuesByPath(model, `additionals.${incompatibleAdditionalIndex}`, new FlatStructuralTransformer()) ?? [];
    const contraryAdditional = candidates.find(candidate => {
      const candidateModel = (candidate.model as IAdditionalAppliance);
      return candidateModel.articleNumber === incompatibleAdditional?.articleNumber && candidateModel.alignment !== incompatibleAdditional.alignment
    });
    //Next candidate is unit with additional with the same articleNumber but contrary alignment
    if (contraryAdditional) {
      additionals[incompatibleAdditionalIndex] = contraryAdditional.model as IAdditionalAppliance;
      yield unit;
    }
    //Next we iterate through the rest
    for (let i = 0; i < candidates.length; i++) {
      const nextCandidate = candidates[i];
      if (nextCandidate != contraryAdditional && i !== incompatibleAdditionalIndex) {
        additionals[incompatibleAdditionalIndex] = nextCandidate.model as IAdditionalAppliance;
        yield unit;
      }
    }

  }
}

export function isElementSkinal(component: Option<Unit> | Option<IPlate>): component is Option<IPlate> {
  return typeof (component as Option<any>).model.minCount !== 'undefined';
}
