import * as React from 'react';
import { Component } from 'react';
import Button from '../buttons/Button/Button';
import Icon from '../Icon/Icon';
import { FormattedMessage } from 'react-intl';
import SkinalItem from '../SkinalItem/SkinalItem';
import { ISkinal } from '../../schema';

interface IProps {
  skinal: ISkinal;
  onDelete: () => void;
}

export class SkinalDetails extends Component<IProps> {
  render() {
    const { skinal, onDelete } = this.props;
    return (
      <div className="elements__list-wrapper">
        <div className="elements-card-in-details">
          {skinal && <SkinalItem skinal={skinal} />}
          <div className="elements-card-in-details__wrapper elements-card-in-details__wrapper--buttons">
            <Button
              className="button_with-icon"
              color='gray'
              fullWidth
              onClick={onDelete}
            >
              <Icon type="trash"/>
              <FormattedMessage id="ui.delete"/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
