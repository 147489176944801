export type OptionId = string;
export type ISerializedAddition = OptionId | null;

interface ISerializedComposite {
  optionId: OptionId | null,
  count: number
}

export interface ISerializedKitchenV1 {
  front: OptionId;
  body: OptionId;
  worktop: ISerializedComposite;
  plinths: ISerializedComposite;
  skinal: ISerializedComposite;
  mountingSet: ISerializedComposite;
  handle: OptionId;
  handle_alignment: string;
  units: ISerializedUnit[];
}

export interface ISerializedKitchenV2 {
  version: 2;
  front: OptionId;
  body: OptionId;
  upstand: OptionId;
  worktop: ISerializedComposite;
  plinths: ISerializedComposite;
  skinal: ISerializedComposite;
  mountingSet: ISerializedComposite;
  handle: OptionId;
  handle_alignment: string;
  units: ISerializedUnit[];
}

export interface ISerializedUnit {
  position: number;
  unit: {
    optionId: OptionId;
    openingSide: string;
    additionals: ISerializedAddition[]
  }
}

export interface ISerializedKitchenV3 {
  version: 3;
  front: OptionId;
  body: OptionId;
  upstand: OptionId;
  worktop: ISerializedComposite;
  plinths: ISerializedComposite;
  skinal: ISerializedComposite;
  mountingSet: ISerializedComposite;
  ledCabel: ISerializedComposite;
  handle: OptionId;
  handle_alignment: string;
  units: ISerializedUnitV3[];
}

export interface ISerializedUnitV3 {
  position: number;
  unit: {
    optionId: OptionId;
    openingSide?: string;
    additionals?: ISerializedAddition[]
  }
}

export interface ISerializedUnitV4 {
  uiId: number;
  position: number;
  unit: {
    optionId: OptionId;
    openingSide?: string;
    additionals?: ISerializedAddition[]
  }
}

export interface ISerializedKitchenV4 {
  version: 4;
  upstand: OptionId;
  worktop: ISerializedComposite;
  skinal: ISerializedComposite;
  units: ISerializedUnitV4[];
}

export type SerializedKitchenLatest = ISerializedKitchenV4;
export type SerializedUnitLatest = SerializedKitchenLatest['units'][0]
export type SerializedKitchen = ISerializedKitchenV1 |
  ISerializedKitchenV2 |
  ISerializedKitchenV3 |
  ISerializedKitchenV4;

export function serializedKitchenV1Guard(state: SerializedKitchen): state is ISerializedKitchenV1 {
  return !(state as any).version;
}

export function serializedKitchenV2Guard(state: SerializedKitchen): state is ISerializedKitchenV1 {
  return (state as any).version === 2;
}

export function serializedKitchenV3Guard(state: SerializedKitchen): state is ISerializedKitchenV1 {
  return (state as any).version === 3;
}

export function serializedKitchenV4Guard(state: SerializedKitchen): state is ISerializedKitchenV1 {
  return (state as any).version === 4;
}

export class SerializedKitchenMatcher<T extends SerializedKitchen> {
  constructor(private state: T, private satisfy: boolean = true) {

  }

  map<R>(mapper: (state: T) => R, altMapper: () => R) {
    return (this.satisfy) ? mapper(this.state) : altMapper();
  }

  run(match: (state: T) => void, notMatched?: () => void) {
    if (this.satisfy) {
      match(this.state);
    } else {
      notMatched && notMatched();
    }
    return this;
  };

  withUpstand(): SerializedKitchenMatcher<T & SerializedKitchen & WithUpstand> {
    return new SerializedKitchenMatcher(
      this.state,
      (this.state as any).upstand != null) as any;
  }
}



export interface WithUpstand {
  upstand: OptionId;
}
