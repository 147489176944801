import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { copyTextToClipboard } from '../../../../helpers/utils';
import { ShareOn } from '../../../../model/Model';
import ShareIcon from '../../../../components/ShareIcon/ShareIcon';
import Button from '../../../../components/buttons/Button/Button';

import './ShareTarget.scss';

interface IProps {
  className?: string;
  getLink: () => string;
  onLinkCopied?: () => void;
  onShare: (target: ShareOn) => void;
}

/**
 * @todo consider Web Share API implementation
 */
const ShareTarget: React.FC<IProps> = ({ className, getLink, onLinkCopied, onShare }) => {

  const socialSharing = process.env.REACT_APP_CL__FEATURE_SHARE_LINK === 'ON';

  const handleCopyLinkClick = async () => {
    const link = getLink();

    console.log(link);
    await copyTextToClipboard(link);
    if (onLinkCopied) {
      onLinkCopied();
    }
  };

  return (
    <div className={classNames('share-target', {
      'share-target--only-copy': !socialSharing,
    }, className)}>
      {socialSharing ?
        <>
          <p className='share-target__description'>
            <FormattedMessage id="ui.share.description"/>
          </p>
          <div className="share-target__variants">
            <button onClick={() => onShare(ShareOn.FB)}><ShareIcon type={'facebook'}/></button>
            <button onClick={handleCopyLinkClick}><ShareIcon type={'link'}/></button>
          </div>
        </>
        :
        <Button color='gray' fullWidth onClick={handleCopyLinkClick}>
          <FormattedMessage id="ui.share.copy.action"/>
        </Button>
      }
    </div>
  );
};

export default ShareTarget;
