import { ReduxDevToolsBase, ReduxDevToolsFactory } from '../ReduxDevTools';
import { ConfiguratorStore, IConfiguratorState } from '../../../stores/ConfiguratorStore';
import { IState } from '../state';
import {
  additionalGuard,
  compositeGuard,
  fittingStripGuard,
  hoodUnitGuard,
  nameImageGuard,
  placeholderGuard,
  skinalGuard,
  staticVariantIdGuard,
  UIId,
  unitElementGuard,
  UnitInsertPosition,
  upstandGuard,
  worktopGuard
} from '../../../model/Model';
import {
  AddAdditionalAction,
  AddSkinalAction,
  AddUnitAction,
  BackToConfigurationAction,
  ChangeHandleOpeningSideAction,
  ChangeQuantityAction,
  ChangeSkinalMaterialAction,
  ChangeUpstandMaterialAction,
  ChangeWidthAction,
  ChangeWorktopMaterialAction,
  CheckoutAction,
  ConfiguratorAction,
  DeselectSelectedElementAction,
  ExcludeItemAction,
  IncludeItemAction,
  OpenReportAction,
  PrintProjectAction,
  ProductActionData,
  RemoveAdditionalAction,
  RemoveSkinalAction,
  RemoveUnitAction,
  ReplaceAdditionalAction,
  SelectUnitAction,
  ShiftUnitAction,
  UnselectUnitAction,
  ZoomInAction,
  ZoomOutAction
} from '../actions/Action';
import { IAdditionalAppliance, ICart, IComposite, IFittingStrip, INameImage, ISkinal, IWorktop, OpeningSide, Unit } from '../../../schema';
import { ShiftType } from '../../../mutators/ShiftUnitMutator';
import { getOptionId, getTypeRef, Option } from '@canvas-logic/engine';
import { toTimeZonedISODate } from '../../../helpers/utils';
import { domainService } from '../../../services/DomainService';

export interface IActionRecord {
  time: string; // ISO 8601 with timezone
  action: ConfiguratorAction;
}

enum MonitorMessage {
  START    = 'START',
  DISPATCH = 'DISPATCH'
}

/**
 * Base action tracker (producer).
 * Produces and stores action objects for model and UI actions.
 *
 * @todo consider moving `actions` storage to `BIActionTracker` as we don't really need it here (left for backwards-compatibility)
 * @see BIActionTracker
 */
export default class ActionTracker {
  protected actions: IActionRecord[] = [];
  private devTools: ReduxDevToolsBase;

  constructor(private configuratorStore: ConfiguratorStore) {
    this.devTools = ReduxDevToolsFactory.create();
    this.devTools.subscribe((message) => {
      console.log('Message: ', message);
      if (message.type === MonitorMessage.START) {
        this.devTools.init(this.getCurrentState());
      }
      if (message.type === MonitorMessage.DISPATCH && message.state) {
        const idx = message.payload.index;
        // if (idx === 0) {
        const state = JSON.parse(message.state) as IState;
        this.deserialize(state);

        // } else {
        //   const actionRecord = this.actions[idx - 1];
        //   actionRecord.action.run();
        // }
      }
    });
  }

  initConfigurator(configuratorState: IConfiguratorState): void {
    this.addAction({
      name: 'InitAction',
      // @todo: record initial kitchen state
    })
  }

  quit(): void {
  }

  unselectUnit(uiId: UIId | null): UnselectUnitAction {
    return {
      uiId,
      name: 'UnselectUnit'
    };
  }

  shiftUnit(uiId: UIId, unit: Unit, shiftType: ShiftType): ShiftUnitAction {
    return this.addAction({
      name: 'ShiftUnit',
      category: 'ProductAction',
      uiId,
      shiftType: shiftType,
      product: this.getProductData(unit),
    });
  }

  addUnit(unit: Option<Unit>, position: UnitInsertPosition): AddUnitAction {
    return this.addAction({
      name: 'AddUnit',
      category: 'ProductAction',
      position,
      product: this.getProductData(unit.model),
    });
  }

  removeUnit(uiId: UIId, position: number, unit: Unit): RemoveUnitAction {
    return this.addAction({
      name: 'RemoveUnit',
      category: 'ProductAction',
      position,
      uiId,
      product: this.getProductData(unit),
    });
  }

  selectUnit(uiId: UIId): SelectUnitAction {
    return {
      name: 'SelectUnitAction',
      uiId
    };
  }

  /**
   * @todo: can this be replaced with `unselectUnit`?
   */
  deselectSelectedElement(): DeselectSelectedElementAction {
    return { name: 'DeselectSelectedElement' };
  }

  addSkinal(skinal: ISkinal): AddSkinalAction {
    return this.addAction({
      name: 'AddSkinal',
      category: 'ProductAction',
      product: this.getProductData(skinal)
    });
  }

  removeSkinal(skinal: ISkinal): RemoveSkinalAction {
    return this.addAction({
      name: 'RemoveSkinal',
      category: 'ProductAction',
      product: this.getProductData(skinal)
    });
  }

  zoomIn(unit: Unit | ISkinal, uiId?: UIId): ZoomInAction {
    return this.addAction({
      name: 'ZoomIn',
      category: 'ProductAction',
      uiId,
      product: this.getProductData(unit),
    });
  }

  // is not tracked for now
  zoomOut(): ZoomOutAction {
    return {
      name: 'ZoomOut',
      category: 'UIAction',
    };
  }

  changeWidth(uiId: UIId, unit: Unit, width: number): ChangeWidthAction {
    return this.addAction({
      name: 'ChangeWidthAction',
      category: 'ProductAction',
      uiId,
      width,
      product: this.getProductData(unit),
    });
  }

  changeHandleOpeningSide(uiId: UIId, unit: Unit, openingSide: OpeningSide): ChangeHandleOpeningSideAction {
    return this.addAction({
      name: 'ChangeHandleOpeningSideAction',
      category: 'ProductAction',
      uiId,
      openingSide,
      product: this.getProductData(unit),
    });
  }

  addAdditional(uiId: UIId, additional: IAdditionalAppliance): AddAdditionalAction {
    return this.addAction({
      name: 'AddAdditionalAction',
      category: 'ProductAction',
      uiId,
      product: this.getProductData(additional),
    });
  }

  replaceAdditional(uiId: UIId, additional: IAdditionalAppliance): ReplaceAdditionalAction {
    return this.addAction({
      name: 'ReplaceAdditionalAction',
      category: 'ProductAction',
      uiId,
      product: this.getProductData(additional),
    });
  }

  removeAdditional(uiId: UIId, additional: IAdditionalAppliance): RemoveAdditionalAction {
    return this.addAction({
      name: 'RemoveAdditionalAction',
      category: 'ProductAction',
      uiId,
      product: this.getProductData(additional),
    });
  }

  changeSkinalMaterial(skinal: ISkinal): ChangeSkinalMaterialAction {
    return this.addAction({
      name: 'ChangeSkinalMaterialAction',
      category: 'ProductAction',
      product: this.getProductData(skinal),
    });
  }

  changeWorktopMaterial(worktop: IWorktop): ChangeWorktopMaterialAction {
    return this.addAction({
      name: 'ChangeWorktopMaterialAction',
      category: 'ProductAction',
      product: this.getProductData(worktop),
    });
  }

  changeUpstandMaterial(material: INameImage): ChangeUpstandMaterialAction {
    return this.addAction({
      name: 'ChangeUpstandMaterialAction',
      category: 'ProductAction',
      product: this.getProductData(material),
    });
  }

  printProject(): PrintProjectAction {
    return this.addAction({
      name: 'PrintProjectAction',
      category: 'UIAction',
    })
  }

  checkout(): CheckoutAction {
    return this.addAction({
      name: 'CheckoutAction',
      category: 'UIAction',
    })
  }

  openReport(): OpenReportAction {
    return this.addAction({
      name: 'OpenReportAction',
      category: 'UIAction',
    })
  }

  backToConfiguration(): BackToConfigurationAction {
    return this.addAction({
      name: 'BackToConfigurationAction',
      category: 'UIAction'
    })
  }

  excludeItem(item: ICart): ExcludeItemAction {
    return this.addAction({
      name: 'ExcludeItemAction',
      category: 'ProductAction',
      product: this.getProductData(item),
    })
  }

  includeItem(item: ICart): IncludeItemAction {
    return this.addAction({
      name: 'IncludeItemAction',
      category: 'ProductAction',
      product: this.getProductData(item),
    })
  }

  changeItemQuantity(item: ICart, quantity: number): ChangeQuantityAction {
    return this.addAction({
      name: 'ChangeQuantityAction',
      category: 'ProductAction',
      quantity,
      product: this.getProductData(item),
    })
  }

  private addAction<T extends ConfiguratorAction>(action: T): T {
    const record = {
      time: toTimeZonedISODate(new Date()),
      action
    };
    this.devTools.send(record, this.getCurrentState());
    this.actions.push(record);
    return action;
  }

  private getCurrentState(): IState {
    return {
      configuratorStore: this.configuratorStore.serialize()
    }
  }

  private deserialize(state: IState) {
    this.configuratorStore.deserialize(state.configuratorStore);
  }

  playActions(actions: IActionRecord[]): void {
    // Remove all actions
    this.actions = [];
    actions.forEach(record => {
      this.actions.push(record);
      this.configuratorStore.applyAction(record.action);
    });
  }

  private extractVariantId(unit: Unit | ISkinal | IAdditionalAppliance | ICart) {
    if (placeholderGuard(unit)) {
      return undefined;
    }

    return staticVariantIdGuard(unit.variantId) ? unit.variantId.value : domainService.getVariantId(this.configuratorStore.model, unit.variantId, unit);
  }

  private getProductData(unit: Unit | ISkinal | IAdditionalAppliance | INameImage | IWorktop | ICart | IComposite | IFittingStrip): ProductActionData {
    if (skinalGuard(unit) || worktopGuard(unit) || compositeGuard(unit)) {
      return {
        id: getOptionId(unit),
        name: unit.name,
        type: getTypeRef(unit),
        category: unit.reportCategory,
        articleNumber: unit.articleNumber,
        price: unit.price,
        variantId: this.extractVariantId(unit) ?? '',
        material: unit.material,
      }
    }

    if (nameImageGuard(unit)) {
      return {
        id: getOptionId(unit),
        name: unit.name,
        type: getTypeRef(unit),
        material: unit.name,
      }
    }

    if (additionalGuard(unit)) {
      return {
        id: getOptionId(unit),
        name: unit.name,
        type: unit.additionalApplianceType,
        category: unit.reportCategory,
        articleNumber: unit.articleNumber,
        price: unit.price,
        variantId: this.extractVariantId(unit),
      }
    }

    if (unitElementGuard(unit)) {
      return {
        id: getOptionId(unit),
        name: unit.name,
        type: unit.unitKind,
        category: unit.reportCategory,
        articleNumber: unit.articleNumber,
        openingDirection: unit.openingSide,
        price: unit.price,
        variantId: this.extractVariantId(unit),
      }
    }

    if (upstandGuard(unit) || fittingStripGuard(unit)) {
      return {
        id: getOptionId(unit),
        name: unit.name,
        type: unit.unitKind,
        category: unit.reportCategory,
        articleNumber: unit.articleNumber,
        price: unit.price,
        variantId: this.extractVariantId(unit),
      }
    }

    if (hoodUnitGuard(unit)) {
      return {
        id: getOptionId(unit),
        name: unit.name,
        type: unit.unitKind,
        category: unit.reportCategory,
        articleNumber: unit.articleNumber,
        price: unit.price,
        variantId: this.extractVariantId(unit),
      }
    }
    if (placeholderGuard(unit)) {
      return {
        id: getOptionId(unit),
        name: unit.name,
        type: unit.unitKind,
      }
    }
    throw new TypeError(`ActionTracker: Unhandled product type: ${getTypeRef(unit)}`);
  }
}
