import { Converter } from '../helpers/Converter';
import { layoutService } from '../services/LayoutService';
import { UnitMatcher } from '../services/UnitMatcher';
import { AdditionalAlignment, IAdditionalAppliance, IKitchen, IMountedUnit, INameImage, Unit, UnitKind, Visibility } from '../schema';
import { placeholderGuard, upstandGuard } from './Model';


export interface IViewModel {
  front: INameImage;
  body: INameImage;
  handle: INameImage;
  items: any[];
}

function hasFridgeVentilation(unit: Unit) {
  return new UnitMatcher(unit)
    .withAdditionals()
    .map(unit => !!unit.additionals
        .find(additional => additional?.additionalApplianceType === 'fridge_ventilation'),
      () => false
    );
}

export class ViewModel implements IViewModel {
  front: INameImage;
  body: INameImage;
  handle: INameImage;
  worktop: INameImage & { depth: number};
  items: any[];
  upstand?: INameImage;
  skinal: INameImage | false;
  bottomUnitsWidth: number;
  floorUnitsWidth: number;
  ceilUnitsWidth: number;

  constructor(model: IKitchen) {
    let { units, front, body, handle, worktop, skinal, upstand } = model;
    this.front = front;
    this.handle = handle;
    this.body = body;
    // Items use center-aligned origin
    this.items = items(units);
    // Z
    this.upstand = upstand;
    const worktopElement = worktop[0];
    this.worktop = { name: worktopElement.material!, image: worktopElement.image, depth: worktopElement.depth };
    if (skinal) {
      this.skinal = { name: skinal.material!, image: skinal.image };
    } else {
      this.skinal = false;
    }
    this.bottomUnitsWidth = layoutService.bottomUnitsWidth(model);
    this.floorUnitsWidth = layoutService.floorUnitsWidth(model);
    this.ceilUnitsWidth = layoutService.ceilUnitsWidth(model);
  }


}

interface IServerMaterial {
  id: string
}

type LogoAlignment = "top-left" | "top" | "top-right" | "left" | "right" | "bottom-left" | "bottom" | "bottom-right";

type LogoWatermarkOptions = {
  name: 'nobilia' | "poweredBy";
  align: LogoAlignment;
  rotation: number;
}

export type RenderOptions = {
  width: number;
  height: number;
  type: string;
  watermark: LogoWatermarkOptions[];
}

export class ServerViewModel {
  private items: any[];
  private image: RenderOptions;
  private front: IServerMaterial;
  private handle: IServerMaterial;
  private body: IServerMaterial;
  private upstand: (IServerMaterial & { target: any }) | false;
  private worktop: IServerMaterial;
  private skinal: IServerMaterial | false;
  private dimensions: { bottomUnitsWidth: number; ceilUnitsWidth: number; floorUnitsWidth: number };

  constructor(model: IKitchen, options: RenderOptions) {
    const units = items(model.units);
    this.front = { id: model.front.name };
    this.handle = { id: model.handle.name };
    this.body = { id: model.body.name };
    this.items = units.filter(item => !placeholderGuard(item.data));
    // Server rendering using left-aligned origin, so we need to shift our center-based origin to the left by width/2
    this.items.forEach(item => {
      item.id = item.data.uiId;
      item.x -= Converter.mmToMeters(item.data.width / 2);
    })
    const worktopElement = model.worktop[0];
    this.worktop = { id: worktopElement.material! };
    if (model.skinal) {
      this.skinal = { id: model.skinal.material! };
    } else {
      this.skinal = false;
    }
    this.upstand = model.upstand ?
      {
        id: model.upstand.name,
        target: units
          .filter(item => upstandGuard(item.data))
          .map(item => ({ uid: item.data.uiId }))
      }
      : false;
    this.image = options;
    this.dimensions = {
      bottomUnitsWidth: layoutService.bottomUnitsWidth(model),
      floorUnitsWidth: layoutService.floorUnitsWidth(model),
      ceilUnitsWidth: layoutService.ceilUnitsWidth(model)
    }
  }
}

function items(units: IMountedUnit[]) {
  // Possible configuration: [group of talls] [group of top /bottom]
  var topOffset = 0, bottomOffset = 0;
  const unitsToTraverse = units.slice().sort((a: IMountedUnit, b: IMountedUnit) => a.position - b.position);
  let isStartingTallFinished = false;
  var items = [];
  // adds bottom and tall
  for (let current of unitsToTraverse) {
    const position = current.position;
    const item = current.unit;

    if (item.unitKind === UnitKind.bottom) {
      isStartingTallFinished = true;
      const widthInMeters = Converter.mmToMeters(item.width);
      let x = bottomOffset + 0.5 * widthInMeters;
      const unitToAdd = { position, x, view: item.view, data: { ...item, uiId: current.uiId } };
      new UnitMatcher(unitToAdd.data)
        .withAdditionals()
        .run((data) => {
          data.additionals = makeAdditionals(current);
        }, () => {
          (unitToAdd.data as any).additionals = [];
        });
      items.push(unitToAdd);
      bottomOffset += widthInMeters;
    }
    if (item.unitKind === UnitKind.tall) {
      const widthInMeters = Converter.mmToMeters(item.width);
      let x = bottomOffset + 0.5 * widthInMeters;
      const addPlinth = ifFridgeCabinet(item)
        && !hasFridgeVentilation(current.unit)!;
      const unitToAdd = { position, x, view: item.view, data: { ...item, uiId: current.uiId } };
      new UnitMatcher(unitToAdd.data)
        .withAdditionals()
        .run((data) => {
          data.additionals = makeAdditionals(current, addPlinth)
        }, () => {
          (unitToAdd.data as any).additionals = [];
        });
      items.push(unitToAdd);
      bottomOffset += widthInMeters;

      // adds top offset until starting group of tall units not finished
      if (!isStartingTallFinished) {
        topOffset += widthInMeters;
      }
    }
  }
  //adds top
  for (let current of unitsToTraverse) {
    const position = current.position;
    const item = current.unit;

    if (item.unitKind === UnitKind.top) {
      const widthInMeters = Converter.mmToMeters(item.width);
      let x = topOffset + 0.5 * widthInMeters;
      const unitToAdd = { position, x, view: item.view, data: { ...item, uiId: current.uiId } };
      new UnitMatcher(unitToAdd.data)
        .withAdditionals()
        .run((data) => {
            data.additionals = makeAdditionals(current);
          },
          () => {
            (unitToAdd.data as any).additionals = [];
          });
      items.push(unitToAdd);
      topOffset += widthInMeters;
    }
  }
  return items;
}

function ifFridgeCabinet(unit: Unit) {
  return new UnitMatcher(unit)
    .withArticleNumber()
    .map(unit => ['40386', '41377', '47099'].includes(unit.articleNumber.toString()), () => false);
}

function makeAdditionals(current: IMountedUnit, addPlinth: boolean = false): (IAdditionalAppliance | null)[] {
  return new UnitMatcher(current.unit)
    .withAdditionals()
    .map(unit => {
      const unitAdditionals = unit.additionals.map((additional: IAdditionalAppliance | null) => {
        if (!additional) {
          return additional;
        }
        switch (additional.additionalApplianceType) {
          case 'hob':
            return { ...additional, x: Converter.mmToMeters(unit.width / 2 - additional.width / 2), y: 0, z: 0 };
          case 'oven':
            return { ...additional, x: 0, y: Converter.mmToMeters(unit.unitKind === 'tall' ? 600 : 0), z: 0 };
          case 'fridge_ventilation':
            return { ...additional, x: 0, y: 0, z: 0 };
          default:
            if (additional.width > unit.width) {
              // Sink
              return { ...additional, x: Converter.mmToMeters(getXOffset(current, additional.width, additional.alignment)), y: 0, z: 0 }
            } else {
              if (additional.additionalApplianceType === 'mixer_tap') {
                const sink = getSink(unit.additionals);
                if (sink) {
                  return {
                    ...additional,
                    x: Converter.mmToMeters(getMixerXOffset(sink, current)),
                    y: 0,
                    z: Converter.mmToMeters(getZOffset(sink.articleNumber))
                  }
                } else {
                  return { ...additional, x: Converter.mmToMeters(current.unit.width / 2), y: 0, z: 0.05 }
                }
              } else {
                return { ...additional, x: Converter.mmToMeters(current.unit.width / 2 - additional.width / 2), y: 0, z: 0 }
              }
            }

        }
      });
      if (addPlinth) {
        unitAdditionals.push({
          visibility: Visibility.both,
          view: 'Sockel_Front',
          x: 0,
          y: 0,
          z: 0,
          width: unit.width
        } as any)
      }
      return unitAdditionals;
    }, () => []);
}

function getSink(additionals: (IAdditionalAppliance | null)[]) {
  const sinks = additionals
    .filter(function (additional) {
      return additional && additional.additionalApplianceType === 'sink'
    });
  if (sinks.length > 0) {
    return sinks[0];
  } else {
    return null;
  }
}

function getXOffset(unit: IMountedUnit, additionalWidth: number, alignment: AdditionalAlignment) {
  return additionalWidth > unit.unit.width && alignment !== AdditionalAlignment.L
      ? ((unit.unit.width - additionalWidth) - 20)
      : 0;
}

function getMixerXOffset(sink: IAdditionalAppliance, unit: IMountedUnit) {
  let oversizeSinkOffset = getXOffset(unit, sink.width, sink.alignment);
  oversizeSinkOffset += (oversizeSinkOffset != 0 || sink.alignment === AdditionalAlignment.L? sink.width : unit.unit.width) / 2;
  if (sink.alignment !== AdditionalAlignment.L) {
    switch (sink.articleNumber.toString()) {
      case '87100':
        return oversizeSinkOffset + 10;
      case '87324':
        return oversizeSinkOffset + 25;
      default:
        return oversizeSinkOffset;
    }
  } else {
    return sink.articleNumber.toString() === '87324' ? oversizeSinkOffset - 17.5 : oversizeSinkOffset;
  }
}

function getZOffset(sinkArticleNumber: string) {
  switch (sinkArticleNumber.toString()) {
    case '87100':
      return 135;
    case '87042':
      return 100;
    case '87324':
      return 127.5;
    case '87516':
      return 110;
    default:
      return 0;
  }
}
