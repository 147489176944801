import { ConfiguratorStore } from '../../../stores/ConfiguratorStore';
import BIActionTracker from './BIActionTracker';
import ActionTracker from './ActionTracker';

export default class ActionTrackerFactory {
  static create(configuratorStore: ConfiguratorStore) {
    if (process.env.REACT_APP_CL__FEATURE__ACTION_TRACKER === 'ON') {
      return new BIActionTracker(configuratorStore);
    } else {
      return new ActionTracker(configuratorStore);
    }
  }
}
