import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import KitchenViewer, { NodeClickEventHandler } from '../../services/KitchenViewer';

export class ZoomService {

  constructor(private loader: GLTFLoader, private kitchenViewer: KitchenViewer) {
  }
  loadMagnifierModel() {
    if (process.env.REACT_APP_CL__FEATURE__ZOOM === 'ON') {
      return new Promise((resolve, reject) => {
        this.loader.load(`assets/models/magnifier.gltf`, (gltf: GLTF) => {
          resolve(gltf.scene);
        }, noop, reject);
      });
    } else {
      return Promise.resolve(new THREE.Object3D());
    }
  }

  addNodeClickListener(nodeName: string, handler: NodeClickEventHandler) {
    if (process.env.REACT_APP_CL__FEATURE__ZOOM === 'ON') {
      this.kitchenViewer.addNodeClickEventHandler(nodeName, handler);
    }
  }
}

function noop() {

}