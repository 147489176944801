import { IActionRecord } from '../features/ActionTracker/trackers/ActionTracker';

export enum IFrameMessageType {
  BOOTSTRAPPED       = 'bootstrapped',
  CONFIGURATOR_READY = 'configurator_ready',
  LOCATION_REQUEST   = 'getLocation',
  LOCATION           = 'location',
  PLAY_ACTIONS       = 'play_actions',
  SWITCH_LOCALE      = 'switch_locale'
}

type IFrameMessageEventData<T extends IFrameMessageType> = {
  readonly type: T
}

type IFrameMessageEvent<T extends IFrameMessageType> = MessageEvent & {
  data: IFrameMessageEventData<T>
}

type IFrameMessageHandler<T extends IFrameMessageType> = (event?: IFrameMessageEvent<T>) => void;

type IFrameMessageEventListener<T extends IFrameMessageType = any> = {
  once?: boolean;
  handler: IFrameMessageHandler<T>;
}

/**
 * Communication wrapper between iframe host and the application.
 *
 * Message channel: window.postMessage
 * When application is used outside of iframe, no message is sent or handled.
 *
 * @see EmbeddingService.isEmbedded
 * @see window.postMessage
 */
export default class EmbeddingService {
  private readonly ignoreEmbedCheck: boolean;
  private listenerMap = new Map<IFrameMessageType, IFrameMessageEventListener>();

  /**
   * Decide if we are working from inside the iframe
   */
  get isEmbedded() {
    if (this.ignoreEmbedCheck) {
      return true;
    }

    return window !== window.parent;
  }

  constructor(ignoreEmbedCheck?: boolean) {
    this.ignoreEmbedCheck = ignoreEmbedCheck === true;

    if (this.isEmbedded) {
      window.addEventListener('message', this.handleMessage);
    }
  }

  /**
   * Awaiting initial message from the host page.
   * Presumably waiting for host to finish initialization.
   */
  async awaitBootstrapping() {
    if (!this.isEmbedded) {
      return;
    }

    await this.awaitEvent(IFrameMessageType.BOOTSTRAPPED);
  }

  sendReady() {
    this.sendMessage(IFrameMessageType.CONFIGURATOR_READY);
  }

  /**
   * Obtain page location data.
   * Get current window data for standalone version and parent window data for embedded one.
   *
   * @see window.location
   */
  async getLocation(): Promise<Location> {
    if (!this.isEmbedded) {
      return window.location;
    }

    const event = await this.asyncCall(IFrameMessageType.LOCATION_REQUEST, IFrameMessageType.LOCATION);
    return JSON.parse(event.data.location);
  }

  subscribeToActionsControl(handler: (actions: IActionRecord[]) => void) {
    this.subscribeToEvent(IFrameMessageType.PLAY_ACTIONS, event => {
      const { payload } = event?.data;

      if (payload !== undefined) {
        handler(payload)
      }
    });
  }

  subscribeToLocaleChange(handler: (locale: string) => void) {
    this.subscribeToEvent(IFrameMessageType.SWITCH_LOCALE, event => {
      const locale = event?.data.locale;

      if (locale !== undefined) {
        handler(locale)
      }
    });
  }

  subscribeToEvent(type: IFrameMessageType, handler: IFrameMessageHandler<typeof type>) {
    if (!this.isEmbedded) {
      return;
    }

    this.setMessageListener(type, handler);
  }

  unsubscribe(type: IFrameMessageType) {
    if (!this.isEmbedded) {
      return;
    }

    this.removeMessageListener(type);
  }

  /**
   * Send message to parent and wait for specific message in response.
   *
   * @param requestType app -> host request message type
   * @param responseType host -> app response message type
   * @private
   */
  private async asyncCall<T extends IFrameMessageType>(requestType: IFrameMessageType, responseType: T): Promise<IFrameMessageEvent<T>> {
    this.sendMessage(requestType);
    return this.awaitEvent(responseType);
  }

  private sendMessage(type: IFrameMessageType) {
    if (!this.isEmbedded) {
      return;
    }

    window.parent.postMessage({ type }, '*');
  }

  /**
   * Wait for specific message to be received.
   *
   * @param type message type to wait for
   * @private
   */
  private async awaitEvent(type: IFrameMessageType): Promise<IFrameMessageEvent<typeof type>> {
    return new Promise(resolve => {
      this.setMessageListener(type, event => {
        resolve(event);
      }, true);
    })
  }

  private setMessageListener(type: IFrameMessageType, handler: IFrameMessageHandler<typeof type>, once?: boolean) {
    if (this.listenerMap.has(type)) {
      console.warn(`Overriding iframe message listener (type: ${type})`);
    }

    this.listenerMap.set(type, {
      handler,
      once
    });
  }

  private removeMessageListener(type: IFrameMessageType) {
    this.listenerMap.delete(type);
  }

  private handleMessage = <T extends IFrameMessageType>(event: IFrameMessageEvent<T>) => {
    const { data: { type } } = event;
    const listener = this.listenerMap.get(type);

    if (listener === undefined) {
      console.warn(`Unhandled iframe message (type: ${type})`);
      return;
    }

    listener.handler(event);

    if (listener.once) {
      this.removeMessageListener(type);
    }
  }

  dispose() {
    window.removeEventListener('message', this.handleMessage);
  }
}
