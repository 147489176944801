import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import QRCode from '../QRCode/QRCode';
import GooglePlayButton from '../GooglePlayButton/GooglePlayButton';
import ARLoader from '../ARLoader/ARLoader';

import './ARCode.scss';

interface IProps {
  arLink?: string;
  className?: string;
}

const ARCode: React.FC<IProps> = ({ arLink, className }) => {
  return (
    <div className={classNames('ar-code', className)}>
      <p className='ar-code__description'>
        <FormattedMessage id="ui.ar.description"/>
      </p>
      <div className="ar-code__qr-wrapper">
        {arLink ?
          <QRCode
            className='ar-code__qr'
            data={arLink}
          />
          :
          <ARLoader>
            <FormattedMessage id="ui.ar.loader"/>
          </ARLoader>
        }
      </div>
      <GooglePlayButton className="ar-code__app--gplay" />
    </div>
  );
};

export default ARCode;
