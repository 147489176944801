import React, { Component } from 'react';
import { observer } from 'mobx-react';

import ActionBar from '../../components/ActionBar/ActionBar';
import IconButton from '../../components/buttons/IconButton/IconButton';
import Switch from '../../components/buttons/Switch/Switch';
import CartLink from '../../features/Pricing/components/CartLink/CartLink';
import ConfiguratorModelViewer from '../../components/ConfiguratorModelViewer/ConfiguratorModelViewer';

import { LoadingMask } from '../../components/LoadingMask/LoadingMask';
import Sidebar from '../../components/Sidebar/Sidebar';

import { ConfigurationViewStoreName, ConfiguratorStore, DeviceType } from '../../stores/ConfiguratorStore';
import { EditOptionViewStore } from '../../stores/ConfiguratorViews/EditOptionViewStore';
import { ElementViewStore } from '../../stores/ConfiguratorViews/ElementViewStore';
import { OverviewViewStore } from '../../stores/ConfiguratorViews/OverviewViewStore';
import { SelectAdditionalViewStore } from '../../stores/ConfiguratorViews/SelectAdditionalViewStore';
import { SelectCategoryViewStore } from '../../stores/ConfiguratorViews/SelectCategoryViewStore';
import { SelectElementViewStore } from '../../stores/ConfiguratorViews/SelectElementViewStore';
import RootStore from '../../stores/RootStore';
import SelectAdditional from './SelectAdditional/SelectAdditional';

import EditOption from './EditOption/EditOption';
import { ElementView } from './ElementView/ElementView';
import Overview from './Overview/Overview';
import SelectCategory from './SelectCategory/SelectCategory';
import SelectElement from './SelectElement/SelectElement';
import CanvasLogicLogo from '../../components/CanvasLogicLogo/CanvasLogicLogo';
import RenderView from '../../components/RenderView/RenderView';

import './Configurator.scss';
import { EditWorktopViewStore } from '../../stores/ConfiguratorViews/EditWorktopViewStore';
import EditWorktop from './EditWorktop/EditWorktop';
import NotificationContainer from '../../components/Notification/NotificationContainer';

@observer
export default class Configurator extends Component {
  private readonly configuratorStore: ConfiguratorStore;
  private readonly mediaQueryList: MediaQueryList;

  constructor(props: any) {
    super(props);
    this.configuratorStore = RootStore.configurator;

    /**
     * @todo unify this media query with the one used by SCSS
     */
    this.mediaQueryList = window.matchMedia('only screen and (max-width: 1023px)');
    this.handleDeviceTypeChanged(this.mediaQueryList.matches);
  }

  async componentDidMount() {
    await this.configuratorStore.init();
    if (this.mediaQueryList.addEventListener) {
      this.mediaQueryList.addEventListener('change', this.handleMediaChanged);
    } else {
      // Safari does not treat MediaQueryList as EventTarget yet
      this.mediaQueryList.addListener(this.handleMediaChanged);
    }
  }

  async componentWillUnmount() {
    if (this.mediaQueryList.removeEventListener) {
      this.mediaQueryList.removeEventListener('change', this.handleMediaChanged);
    } else {
      // Safari does not treat MediaQueryList as EventTarget yet
      this.mediaQueryList.removeListener(this.handleMediaChanged);
    }
  }

  handleMediaChanged = (event: MediaQueryListEvent) => {
    this.handleDeviceTypeChanged(event.matches);
  };

  handleDeviceTypeChanged = (matchesHandlet: boolean) => {
    this.configuratorStore.setDeviceType(matchesHandlet ? DeviceType.HANDLET : DeviceType.DESKTOP);
  };

  render() {
    let component;
    const { configuratorStore } = this;

    switch (configuratorStore.currentView.name) {
      case ConfigurationViewStoreName.OVERVIEW:
        component = <Overview store={configuratorStore.currentView as OverviewViewStore}/>;
        break;
      case ConfigurationViewStoreName.EDIT_OPTION:
        component = <EditOption store={configuratorStore.currentView as EditOptionViewStore}/>;
        break;
      case ConfigurationViewStoreName.EDIT_WORKTOP:
        component = <EditWorktop store={configuratorStore.currentView as EditWorktopViewStore}/>;
        break;
      case ConfigurationViewStoreName.SELECT_ADDITIONAL:
        component = <SelectAdditional store={configuratorStore.currentView as SelectAdditionalViewStore}/>;
        break;
      case ConfigurationViewStoreName.SELECT_CATEGORY:
        component = <SelectCategory store={configuratorStore.currentView as SelectCategoryViewStore}/>;
        break;
      case ConfigurationViewStoreName.SELECT_ELEMENT:
        component = <SelectElement store={configuratorStore.currentView as SelectElementViewStore}/>;
        break;
      case ConfigurationViewStoreName.ELEMENT_VIEW:
        component = <ElementView store={configuratorStore.currentView as ElementViewStore}/>;
        break;
    }
    return (
      <div className="configurator__layout">
        {configuratorStore.loaded && configuratorStore.sideBarOpened &&
        <Sidebar className="configurator__sidebar">
          {component}
        </Sidebar>
        }
        <div className="model-viewer_wrapper">
          <ConfiguratorModelViewer
            store={this.configuratorStore}
            template={this.configuratorStore.template}/>
          <CanvasLogicLogo className='model-viewer__cl-logo'/>
          {!configuratorStore.sideBarOpened &&
          <div className="configurator__actions-container">
            <IconButton
              className="configurator__action"
              icon="menu"
              onClick={configuratorStore.toggleSidebar}
            />
            <IconButton
              className="configurator__action"
              color='primary'
              icon="plus_24"
              onClick={configuratorStore.handleAddItem}
            />
          </div>
          }
          {configuratorStore.loaded && <>
            <CartLink
              price={configuratorStore.totalPrice}
              beforeOpenReport={configuratorStore.handleBeforeOpenReport}
            />
            {(!configuratorStore.sideBarOpened || configuratorStore.deviceType === DeviceType.DESKTOP) &&
            <ActionBar
              mobileVersion={configuratorStore.deviceType === DeviceType.HANDLET}
              className={'configurator__action-bar'}
              onOpenAR={configuratorStore.generateARLink}
              onRenderClick={configuratorStore.handleRenderClick}
              getShareLink={configuratorStore.shareDirectLink}
              onRulerClick={configuratorStore.toggleRuler}
              showRuler={configuratorStore.showRuler}
              onShare={configuratorStore.shareOn}
              locale={RootStore.localization.locale}
              onSwitchLocale={RootStore.localization.changeLanguage}
            >
              <Switch cameraView={configuratorStore.cameraView} onChange={configuratorStore.toggleCameraView}/>
            </ActionBar>
            }
          </>
          }
          {configuratorStore.showRender &&
          <div className="modal-shim">
            <RenderView
              model={configuratorStore.model}
              onClose={configuratorStore.handleRenderClose}
              onShare={configuratorStore.shareOn}
              getShareLink={configuratorStore.shareDirectLink}
            />
          </div>
          }
          <NotificationContainer alertStore={RootStore.notificationsStore} />
          {!configuratorStore.loaded && <div className="loading-mask"><LoadingMask/></div>}
        </div>
      </div>
    );
  }
}
