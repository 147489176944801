import { MutatorContext, ValidationResult } from '@canvas-logic/engine';
import { IKitchen, IMountedUnit } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';

export enum ShiftType {
  Left,
  Right
}
export class ShiftUnitMutator extends KitchenBaseMutator {
  constructor(private unit: IMountedUnit, private shiftType: ShiftType) {
    super();
  }
  mutateKitchen(context: MutatorContext, typeRef: string, model: IKitchen): [IKitchen, ValidationResult] {
    const unit = model.units.find(u => u.position === this.unit.position && u.unit.unitKind === this.unit.unit.unitKind);
    const positionShift = this.shiftType === ShiftType.Right ? 1 : -1;
    const relatedUnit =model.units.find(u => u.position === (this.unit.position + positionShift) && u.unit.unitKind === this.unit.unit.unitKind);
    if (relatedUnit && unit) {
      relatedUnit.position = this.unit.position;
      unit.position = this.unit.position + positionShift;
      return [model, ValidationResult.Valid()];
    }
    return [model, ValidationResult.Error('shift.element.error')];
  }
}
