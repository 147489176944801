import * as React from 'react';
import classNames from 'classnames';

import Tooltip from '../Tooltip/Tooltip';

import './ButtonWithTooltip.scss';

interface IClasses {
  tooltip?: string;
  tooltipVisible?: string;
  button?: string;
}

interface IProps {
  classes?: IClasses;
  onClick?: () => void;
  button: JSX.Element;
  tooltipContent: JSX.Element;
}

interface IState {
  tooltipVisible: boolean;
  timerRef: ReturnType<typeof setTimeout> | null;
}

class ButtonWithTooltip extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tooltipVisible: false,
      timerRef: null,
    }
  }

  resetTimer = () => {
    const { timerRef } = this.state;

    if (timerRef) {
      clearTimeout(timerRef);
      this.setState({
        timerRef: null,
      })
    }
  };

  hideTooltip = () => {
    this.setState({
      timerRef: null,
      tooltipVisible: false
    })
  };

  showTooltip = () => {
    this.resetTimer();

    const timerRef = setTimeout(this.hideTooltip, 3000);

    this.setState({
      timerRef,
      tooltipVisible: true,
    })
  };

  handleClick = () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    }
    this.showTooltip();
  };

  render() {
    const { classes, button, tooltipContent } = this.props;
    const { tooltipVisible } = this.state;

    const conditionalClasses: any = {
      'button-with-tooltip__tooltip--visible': tooltipVisible,
    };

    if (classes !== undefined && classes.tooltipVisible) {
      conditionalClasses[classes.tooltipVisible] = tooltipVisible;
    }

    return (
      <div className='button-with-tooltip__wrapper'>
        <Tooltip className={classNames(
          'button-with-tooltip__tooltip',
          classes?.tooltip,
          conditionalClasses
        )}
        >
          {tooltipContent}
        </Tooltip>
        {React.cloneElement(button, { onClick: this.handleClick })}
      </div>
    );
  }
}

export default ButtonWithTooltip;
