/*** 
  Autogenerated by ts-generator 1.8.0.
  Schema: public/assets/dataset/nobilia.schema.yml
  Date: 2021-03-15T12:11:00.401Z
***/
import { MLString, BigNumber, WithOptionId, DatasetService, ExternalFieldRegistry } from '@canvas-logic/engine';
export type VariantId = IStaticVariantId | IDynamicVariantId;
export interface IStaticVariantId {
  value: string;
}
export interface ISideMenuViewModel {
  showOpeningSide: boolean;
  sideMenuWidth?: number;
}
export interface ICart {
  price: number;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  width: number;
  height: number;
  depth: number;
  description: string;
  name: string;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
}
export interface IView {
  view: string;
}
export interface IDimension {
  width: number;
  height: number;
  depth: number;
}
export interface IPrice {
  price: number;
}
export interface IBasicItem {
  name: string;
  description: string;
}
export interface INameImage {
  name: string;
  image: string;
}
export interface IKeyValue {
  key: string;
  value: string;
}
export type KeyValueList = IKeyValue[];
export interface IDynamicVariantId {
  fieldName: string;
  values: KeyValueList;
}
export enum AdditionalApplianceType {
  oven = 'oven',
  hob = 'hob',
  dishwasher = 'dishwasher',
  fridge = 'fridge',
  microwave = 'microwave',
  sink = 'sink',
  mixer_tap = 'mixer_tap',
  fridge_ventilation = 'fridge_ventilation',
  moisture_protection = 'moisture_protection',
  cutlery_tray = 'cutlery_tray',
  anti_slip_mat = 'anti_slip_mat',
  trash_can = 'trash_can',
  carbon_filter = 'carbon_filter',
  led = 'led',
  passbar = 'passbar',
  hood = 'hood'
}
export enum UnitKind {
  tall = 'tall',
  top = 'top',
  bottom = 'bottom'
}
export enum AdditionalAlignment {
  L = 'L',
  R = 'R',
  NONE = 'NONE'
}
export interface IAdditionalAppliance {
  name: string;
  description: string;
  price: number;
  width: number;
  height: number;
  depth: number;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
  view: string;
  additionalApplianceType: AdditionalApplianceType;
  alignment: AdditionalAlignment;
}
export enum ReportCategory {
  cabinets = 'cabinets',
  equipment = 'equipment',
  accessories = 'accessories'
}
export enum ReportCalculation {
  selected = 'selected',
  deselected = 'deselected',
  fixed = 'fixed'
}
export enum Visibility {
  conf = 'conf',
  spec = 'spec',
  both = 'both'
}
export enum ChangeQuantity {
  yes = 'yes',
  no = 'no'
}
export type AdditionalApplianceList = (IAdditionalAppliance | null)[];
export type Unit = IUnitElement | IHoodUnit | IUnitPlaceholder | IUpstand | IFittingStrip;
export interface IUnitElement {
  name: string;
  description: string;
  price: number;
  width: number;
  height: number;
  depth: number;
  view: string;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
  sideMenuWidth?: number;
  test?: MLString;
  unitKind: UnitKind;
  openingSide: OpeningSide;
  groupByWidth?: string;
  additionals: AdditionalApplianceList;
}
export interface IHoodUnit {
  name: string;
  description: string;
  width: number;
  height: number;
  depth: number;
  price: number;
  view: string;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
  unitKind: UnitKind;
  groupByWidth?: string;
  additionals: AdditionalApplianceList;
}
export interface IUnitPlaceholder {
  width: number;
  height: number;
  depth: number;
  view: string;
  name: string;
  description: string;
  unitKind: UnitKind;
  itemImage: string;
  groupByWidth?: string;
}
export interface IUpstand {
  name: string;
  description: string;
  width: number;
  height: number;
  depth: number;
  price: number;
  view: string;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
  unitKind: UnitKind;
  groupByWidth?: string;
  combi: boolean;
}
export interface IFittingStrip {
  name: string;
  description: string;
  width: number;
  height: number;
  depth: number;
  price: number;
  view: string;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
  unitKind: UnitKind;
  groupByWidth?: string;
}
export enum OpeningSide {
  L = 'L',
  R = 'R',
  NONE = 'NONE'
}
export enum HandleAlignment {
  horizontal = 'horizontal',
  vertical = 'vertical'
}
export interface IMountedUnit {
  uiId: number;
  position: number;
  unit: Unit;
}
export type MountedUnitList = IMountedUnit[];
export interface IPlate {
  price: number;
  width: number;
  height: number;
  depth: number;
  name: string;
  description: string;
  articleNumber: string;
  image: string;
  itemImage: string;
  material: string;
  minCount: number;
  basePrice: number;
  variantId: VariantId;
  dynamicVariantId?: IDynamicVariantId;
  view: string;
}
export interface IMaterialComponent {
  value: WithOptionId<INameImage>;
}
export type WorktopList = IWorktop[];
export interface IWorktop {
  price: number;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  width: number;
  height: number;
  depth: number;
  description: string;
  name: string;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
  image: string;
  minCount: number;
  material?: string;
}
export interface ISkinal {
  price: number;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  width: number;
  height: number;
  depth: number;
  description: string;
  name: string;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
  image: string;
  minCount: number;
  material?: string;
}
export interface IComposite {
  price: number;
  variantId: VariantId;
  typeNo: string;
  EAN: VariantId;
  articleNumber: string;
  visibility: Visibility;
  changeQuantity: ChangeQuantity;
  reportCategory: ReportCategory;
  reportCalculation: ReportCalculation;
  itemImage: string;
  basePrice: number;
  width: number;
  height: number;
  depth: number;
  description: string;
  name: string;
  amount: number;
  exclusiveGroup: string;
  child: string;
  showOpeningSide: boolean;
  image: string;
  minCount: number;
  material?: string;
}
export type CompositeList = IComposite[];
export interface IKitchen {
  units: MountedUnitList;
  handleAlignment: HandleAlignment;
  front: WithOptionId<INameImage>;
  body: WithOptionId<INameImage>;
  worktop: WithOptionId<WorktopList>;
  handle: WithOptionId<INameImage>;
  upstand?: WithOptionId<INameImage>;
  plinths: WithOptionId<CompositeList>;
  skinal?: WithOptionId<ISkinal>;
  mountingSet: WithOptionId<IComposite>;
  supportLegMounting: WithOptionId<IComposite>;
  accessories: WithOptionId<CompositeList>;
}
