import { observable } from 'mobx';

import { ServerViewModel } from '../../model/ViewModel';

export enum RenderStatus {
  IDLE = 'IDLE',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR'
}

type RenderRequest = {
  id: string;
}

type RenderStatusResponse = {
  status: RenderStatus;
}

export default class PRRenderService {
  @observable status: RenderStatus = RenderStatus.IDLE;

  constructor(private readonly serverAddress = process.env.REACT_APP_CL__PRRENDER_SERVICE_URL,
              private readonly statusCheckPeriod = 2000) {
  }

  private async startRendering(model: ServerViewModel) {
    this.status = RenderStatus.IN_PROGRESS;

    const response = await fetch(`${this.serverAddress}/start`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    });
    const json: RenderRequest = await response.json();
    return json.id;
  }

  private async waitForRender(requestId: string) {
    return new Promise((resolve, reject) => {
      const id = setInterval(async () => {
        const response = await fetch(`${this.serverAddress}/status/${requestId}`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
        });
        const json: RenderStatusResponse = await response.json();
        if (json.status !== RenderStatus.IN_PROGRESS) {
          clearInterval(id);
          this.status = json.status;

          if (json.status === RenderStatus.FINISHED) {
            resolve();
          } else if (json.status === RenderStatus.ERROR) {
            reject('Render error');
          }
        }
      }, this.statusCheckPeriod);
    });
  }

  private buildImageURL(requestId: string) {
    return `${this.serverAddress}/image/${requestId}`;
  }

  private async preFetchImage(imageUrl: string) {
    const image = new Image();
    const resultPromise = new Promise(resolve => image.addEventListener('load', resolve, false));
    image.src = imageUrl;
    return resultPromise;
  }

  async render(model: ServerViewModel) {
    if (process.env.REACT_APP_CL__FEATURE__PRRENDER === 'ON') {
      try {
        const requestId = await this.startRendering(model);
        await this.waitForRender(requestId);
        const imageUrl = this.buildImageURL(requestId);
        await this.preFetchImage(imageUrl);
        return imageUrl;
      } catch (e) {
        this.status = RenderStatus.ERROR;
        throw e;
      }
    } else {
      return '';
    }
  }
}
