import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from '../buttons/Button/Button';

import './ReportTotal.scss';

interface IProps {
  className?: string;
  classes?: Record<'root' | 'wrapper', string>;
  items: JSX.Element[];
  total: number;

  onCheckout(): void;
}

class ReportTotal extends React.Component<IProps> {
  render() {
    const { items, className, classes, total, onCheckout } = this.props;
    const showPrices = process.env.REACT_APP_CL__FEATURE__PRICING === 'ON';

    return (
      <div className={classNames('cart-total-bar', className, classes?.root)}>
        <div className={classNames('cart-total-bar__wrapper', classes?.wrapper)}>
          <ul className="cart-total-bar__list">
            {items.map((item, index) => <li className="cart-total-bar__item" key={index}>{item}</li>)}
          </ul>
          {showPrices &&
          <div className="cart-total-bar__total">
            <p className="cart-total-bar__total-price">{total.toFixed(2)} €</p>
            <p className="cart-total-bar__total-vat">
              <FormattedMessage id="ui.report.summary.vat" values={{ sum: ReportTotal.countVat(total).toFixed(2) }}/>
            </p>
          </div>
          }
          {showPrices &&
          <div className="cart-total-bar__button-checkout">
            <Button
              color="white"
              onClick={onCheckout}
            >
              <FormattedMessage id="ui.report.checkout"/>
            </Button>
          </div>
          }
        </div>
      </div>
    );
  }

  static countVat(sum: number) {
    return (sum / 1.19) * 0.19
  }
}

export default ReportTotal;
