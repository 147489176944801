import { Schema } from '@canvas-logic/engine-data';
import { FlatStructuralTransformer, getOptionId, MutatorContext } from '@canvas-logic/engine';
import { layoutService } from '../services/LayoutService';
import { domainService } from '../services/DomainService';
import { IKitchen, IMountedUnit, INameImage, IWorktop, Unit, UnitKind } from '../schema';
import { supportLegGuard, upstandGuard } from '../model/Model';

const OVEN = 'oven';
const HOB = 'hob';
const SINK = 'sink';
const LED = 'led';

export function getLastStartingTall(model: IKitchen): Unit | null {
  const sorted = model.units.slice().sort((a, b) => a.position - b.position);
  if (!sorted.length || sorted[0].unit.unitKind !== UnitKind.tall) {
    return null;
  }
  // first is tall
  let i = 0;
  while ((i + 1) < sorted.length && sorted[i + 1].unit.unitKind === UnitKind.tall) {
    i++;
  }
  return sorted[i].unit;
}

export function getFirstFinishinggTall(model: IKitchen): Unit | null {
  const sorted = model.units.slice().sort((a, b) => a.position - b.position);
  let i = 0;
  // skip starting talls
  while (i < sorted.length && sorted[i].unit.unitKind === UnitKind.tall) {
    i++;
  }
  // skip until find first tall
  while (i < sorted.length && sorted[i].unit.unitKind !== UnitKind.tall) {
    i++;
  }

  if (i < sorted.length) {
    return sorted[i].unit;
  }
  return null;
}

export function updatePlinthsCount(model: IKitchen) {
  const plinths = model.plinths;
  plinths.forEach((plinth, i) => {
    const minCount = plinth.minCount;
    let totalWidth = getBottomUnitsWidth(model);
    // In case there is no bottom units don't calculate plinths for talls
    if (totalWidth === 0) {
      model.plinths[i].amount = 0;
      return;
    }
    const lastStartingTall = getLastStartingTall(model);
    const firstFinishingTall = getFirstFinishinggTall(model);
    if (lastStartingTall) {
      totalWidth += lastStartingTall.depth;
    }

    if (firstFinishingTall) {
      totalWidth += firstFinishingTall.depth;
    }
    model.plinths[i].amount = Math.max(minCount, Math.ceil(totalWidth / plinth.width));
  });
}

/*
 For plinths with 0 amount we also set 0 amount to their
 child items.
 */
function updatePlinthAccessoriesCount(model: IKitchen) {
  model.plinths.forEach(plinth => {
    model.accessories = model.accessories.map(accessory => {
      if (getOptionId(accessory) === plinth.child) {
        accessory.amount = plinth.amount > 0 ? 1 : 0;
      }
      return accessory;
    });
  })
}

export function updateSkinalCount(model: IKitchen) {
  if (!model.skinal) {
    return;
  }
  const skinal = model.skinal;
  const minCount = skinal.minCount;
  let totalWidth = getBottomUnitsWidth(model);
  const skinalCount = totalWidth > 0
    ? Math.max(minCount, Math.ceil(totalWidth / skinal.width))
    : 0;
  model.skinal.amount = skinalCount;
}

export function updateMountingSetCount(model: IKitchen) {
  const additionals = domainService.allUnitsAdditionals(model);
  const ovenCount = additionals.filter(additional => additional && additional.additionalApplianceType === OVEN).length;
  const hobCount = additionals.filter(additional => additional && additional.additionalApplianceType === HOB).length;
  const sinkCount = additionals.filter(additional => additional && additional.additionalApplianceType === SINK).length;
  const count = Math.max(ovenCount, hobCount, sinkCount);
  model.mountingSet.amount = count;

}

export function removeSkinalIfNoBottomUnits(model: IKitchen) {
  const hasBottomUnits = model.units.some(unit => unit.unit.unitKind === UnitKind.bottom || unit.unit.unitKind === UnitKind.tall);
  if (!hasBottomUnits && model.skinal) {
    model.skinal = undefined;
  }
}

export function updateSupportLegMountingCount(model: IKitchen) {
  const supportLegs = model.units.filter(unit => supportLegGuard(unit.unit));
  model.supportLegMounting.amount = supportLegs.length;
}

function holdUpstandMaterialInvariant(model: IKitchen, whiteMaterial: INameImage) {
  // if all upstands are tc_16896  then 'White_rough' material only possible
  const whiteMaterialOnly = model.units.filter(u => upstandGuard(u.unit))
    .every(u => getOptionId(u.unit) === 'tc_16896')

  if (whiteMaterialOnly) {
    model.upstand = whiteMaterial;
  }

}

export function updateModel(model: IKitchen, context: MutatorContext) {
  const worktop = model.worktop[0];
  const availableWorktops = (context.optionValuesByPath(model,
    'worktop',
    new FlatStructuralTransformer<IWorktop>()
  ) ?? [])
    .map(o => o.model)
    .filter(w => w.depth === worktop.depth && w.name === worktop.name);

  const whiteUpstandMaterial = (context.optionValuesByPath(model,
    'upstand',
    new FlatStructuralTransformer<INameImage>()
  ) ?? [])
    .filter(option => option._id === 'White_rough')
    .map(option => option.model)[0];


  updateWorktopPlateCount(model, availableWorktops);
  updatePlinthsCount(model);
  updatePlinthAccessoriesCount(model);
  updateSkinalCount(model);
  updateMountingSetCount(model);
  updateSupportLegMountingCount(model);
  removeSkinalIfNoBottomUnits(model);
  holdUpstandMaterialInvariant(model, whiteUpstandMaterial);
}

function getBottomUnitsWidth(model: IKitchen) {
  const totalWidth = model.units
    .filter(u => u.unit.unitKind === UnitKind.bottom)
    .reduce((acc, next) => acc + next.unit.width, 0);
  return totalWidth;
}

function findNearestWorktop(sortedWorktops: IWorktop[], totalWidth: number): IWorktop {
  let nearest = sortedWorktops[0];
  let i = 1;
  while (i < sortedWorktops.length && sortedWorktops[i].width >= totalWidth) {
    nearest = sortedWorktops[i++];
  }
  return nearest;
}

function findCheapestWorktop(availableWorktops: IWorktop[], totalWidth: number, number: number): IWorktop[] {
  const starting = availableWorktops.map(w => ({ ...w, amount: 0 }));
  const candidates = getCandidates();
  let minPrice = Infinity;
  let result = candidates[0];
  for (let candidate of candidates) {
    const candidatePrice = price(candidate);
    if (candidatePrice > 0 && candidatePrice < minPrice) {
      result = candidate;
      minPrice = candidatePrice;
    }
  }
  return result;

  function getCandidates() {
    const q: IWorktop[][] = [starting];
    const visited: IWorktop[][] = [];
    const candidates: IWorktop[][] = []
    while (q.length > 0) {
      const next = q.pop()!;
      const isVisited = visited.find(v => v.every((item, idx) => item.amount === next[idx].amount))
      if (!isVisited) {
        visited.push(next);
        if (width(next) >= totalWidth) {
          candidates.push(next);
          continue;
        }
        // console.log(next);
        for (let i = 0; i < next.length; i++) {
          const copy = next.map((w, idx) => i === idx ? { ...w, amount: w.amount + 1 } : w);
          q.push(copy);
        }
      }
    }
    return candidates;
  }

  function width(worktops: IWorktop[]) {
    return worktops.reduce((acc, next) => acc + next.width * next.amount, 0);
  }

  function price(worktops: IWorktop[]) {
    return worktops.reduce((acc, next) => acc + next.price * next.amount, 0);
  }
}

export function updateWorktopPlateCount(model: IKitchen, availableWorktops: IWorktop[]) {
  availableWorktops.sort((a, b) => b.width - a.width);
  const maxWidth = availableWorktops[0].width;
  let bottomUnitsWidth = getBottomUnitsWidth(model);
  const supportLegs = model.units.filter(u => supportLegGuard(u.unit));

  const totalSupportLegsWidth = supportLegs
    .map(u => u.unit.width)
    .reduce((acc, next) => acc + next, 0);

  const restUnitWidth = bottomUnitsWidth - totalSupportLegsWidth;
  for (let supportLeg of supportLegs) {
    if (restUnitWidth < supportLeg.unit.width) {
      throw new Error('ui.error.not_rigid_worktop');
    }
  }

  if (layoutService.bottomUnits(model).length === 0) {
    const worktopPlate = { ...model.worktop[0], amount: 0 };
    model.worktop = [worktopPlate]
    return;
  }
  if (maxWidth >= bottomUnitsWidth) {
    const nearestWorktop = findNearestWorktop(availableWorktops, bottomUnitsWidth);
    model.worktop = [{ ...nearestWorktop, amount: 1 }];
  } else {
    throw new Error('ui.error.worktop_unreachable');
    // const worktop = findCheapestWorktop(availableWorktops, expectedWidth, 0);
    // model.worktop = worktop.filter(w => w.amount > 0);
  }
}

export function collectInformation(units: IMountedUnit[]): {
  maxTopIndex: number,
  topWidth: number,
  bottomWidth: number,
  maxBottomIndex: number,
  tallFirstWidth: number,
  tallLastWidth: number
} {
  let returnObj = {
    maxTopIndex: 0,
    topWidth: 0,
    bottomWidth: 0,
    maxBottomIndex: 0,
    tallFirstWidth: 0,
    tallLastWidth: 0
  };
  let unitsMap: {
    tall: { [pos: number]: IMountedUnit },
    top: { [pos: number]: IMountedUnit },
    bottom: { [pos: number]: IMountedUnit },
  } = {
    tall: {},
    top: {},
    bottom: {}
  };

  for (let u of units) {
    (unitsMap as any)[u.unit.unitKind][u.position] = u;
  }

  let sort = (a: string, b: string) => Number.parseInt(a) - Number.parseInt(b);

  for (let topPos of Object.keys(unitsMap.top).sort(sort)) {
    let pos = Number.parseInt(topPos);
    returnObj.maxTopIndex = returnObj.maxTopIndex <= pos ? pos : returnObj.maxTopIndex;
    returnObj.topWidth += unitsMap.top[pos].unit.width;
  }

  for (let botPos of Object.keys(unitsMap.bottom).sort(sort)) {
    let pos = Number.parseInt(botPos);
    returnObj.maxBottomIndex = returnObj.maxBottomIndex <= pos ? pos : returnObj.maxBottomIndex;
    returnObj.bottomWidth += unitsMap.bottom[pos].unit.width;
  }
  let tallLastFirstSectionPosition = -1;
  for (let tallPos of Object.keys(unitsMap.tall).sort(sort)) {
    let pos = Number.parseInt(tallPos);
    returnObj.maxBottomIndex = returnObj.maxBottomIndex <= pos ? pos : returnObj.maxBottomIndex;
    if (pos - 1 > tallLastFirstSectionPosition) {
      returnObj.tallLastWidth += unitsMap.tall[pos].unit.width;
    } else {
      returnObj.tallFirstWidth += unitsMap.tall[pos].unit.width;
      tallLastFirstSectionPosition++;
    }
  }
  return returnObj;

}

export function createSelectedNobiliaUnit(schema: Schema, unit: any, number: number, uiId: number): IMountedUnit {
  let item = {
    uiId: uiId,
    position: number,
    unit: unit
  };
  return item;
}


