"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var record_1 = require("../record");

function isOption(value) {
  return typeof value === 'object' && '_id' in value && value.kind === record_1.RecordKind.Option;
}

exports.isOption = isOption;

function isNoRestriction(vs) {
  return vs.kind == 'NO_RESTRICTIONS';
}

exports.isNoRestriction = isNoRestriction;

function isRestrictedTo(vs) {
  return vs.kind === 'RESTRICTED_TO';
}

exports.isRestrictedTo = isRestrictedTo;

function noRestrictions() {
  return {
    kind: 'NO_RESTRICTIONS'
  };
}

exports.noRestrictions = noRestrictions;

function restrictedTo(values) {
  return {
    kind: 'RESTRICTED_TO',
    values: values
  };
}

exports.restrictedTo = restrictedTo;

function isValuesCompatibleValues(values) {
  if (values.length <= 1) {
    return true;
  }

  var firstValueType = typeof values[0];
  return values.slice(1).every(function (v) {
    return typeof v === firstValueType;
  });
}

function castCompatibleValue(compatibleValues) {
  if (isValuesCompatibleValues(compatibleValues)) {
    return compatibleValues;
  } else {
    throw new Error('Incompatible results of compatible values');
  }
}

exports.castCompatibleValue = castCompatibleValue;