import * as React from 'react';
import classNames from 'classnames';

import IconButton from '../buttons/IconButton/IconButton';
import ShareButton from '../ShareButton/ShareButton';
import ARButton from '../../features/AR/components/ARButton/ARButton';
import NobiliaLogo from '../NobiliaLogo/NobiliaLogo';

import './ActionBar.scss';
import ActionMenu from '../../features/cross/components/ActionMenu/ActionMenu';
import { ShareOn } from '../../model/Model';
import { PRRenderButton } from '../../features/PRRender/components/PRRenderButton/PRRenderButton';
import LocaleSwitchButton from '../../features/LocaleSwitch/components/LocaleSwitchButton/LocaleSwitchButton';
import { Locale } from '../../stores/Localization';

interface IProps {
  mobileVersion?: boolean;
  className?: string;
  getShareLink: () => string;
  onOpenAR: () => Promise<string>;
  onRenderClick: () => void;
  onRulerClick: () => void;
  showRuler: boolean;
  onShare: (target: ShareOn) => void;
  locale: Locale;
  onSwitchLocale: (locale: Locale) => void;
}

class ActionBar extends React.Component<IProps> {
  render() {
    const {
            className,
            mobileVersion,
            onRenderClick,
            onRulerClick,
            showRuler,
            locale,
            onSwitchLocale
          } = this.props;

    const showLogo = process.env.REACT_APP_CL__FEATURE__LOGO === 'ON';

    return (
      <div className={classNames('action-bar', className)}>
        <div className='action-bar__actions action-bar__actions--left'>
          {showLogo && <NobiliaLogo className='action-bar__action--vertical-center'/>}
        </div>
        <div className='action-bar__actions action-bar__actions--center'>
          {this.props.children}
        </div>
        <div className='action-bar__actions action-bar__actions--right'>
          {mobileVersion ?
            <ActionMenu
              showRuler={showRuler}
              onToggleRuler={onRulerClick}
              getARLink={this.props.onOpenAR}
              onRenderClick={onRenderClick}
              getShareLink={this.props.getShareLink}
              onShare={this.props.onShare}
              locale={locale}
              onSwitchLocale={onSwitchLocale}
            />
            :
            <>
              <IconButton icon="ruler" altIcon="ruler" showAltIcon={showRuler} onClick={onRulerClick}/>
              <PRRenderButton onClick={onRenderClick}/>
              <ARButton getLink={this.props.onOpenAR}/>
              <ShareButton getLink={this.props.getShareLink} onShare={this.props.onShare}/>
              <LocaleSwitchButton currentLocale={locale} onSwitchLocale={onSwitchLocale}/>
            </>
          }
        </div>
      </div>
    );
  }
}

export default ActionBar;
