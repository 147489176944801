import React from 'react';
import classNames from 'classnames';

import './Pill.scss';

export enum PillAlignment {
  Left,
  Right,
  Center
}

interface IProps {
  className?: string;
  active?: boolean;
  alignment?: PillAlignment;
  onClick?: () => void;
}

export default class Pill extends React.Component<IProps> {
  render() {
    const { active, onClick, children, alignment, className } = this.props;

    return (
      <button
        className={classNames('pill', {
          'pill--active': active,
          'pill--right': alignment === PillAlignment.Right,
          'pill--center': alignment === PillAlignment.Center
        }, className)}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
}
