"use strict";

var _classCallCheck = require("/home/felger/projects/canvaslogic/canvas-logic-model/apps/nobilia/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ModelNotificationEvent = function ModelNotificationEvent(target) {
  _classCallCheck(this, ModelNotificationEvent);

  this.target = target;
};

exports.ModelNotificationEvent = ModelNotificationEvent;