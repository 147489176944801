import { FlatStructuralTransformer, getOptionId, MutatorContext, PModel, ValidationResult } from '@canvas-logic/engine';
import { UnitMatcher } from '../services/UnitMatcher';
import { IAdditionalAppliance, IKitchen, IMountedUnit } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';
import LinkedElementsService from '../services/LinkedElementsService';
import LinkedElementModelEvent, { LinkedElementModelEventType } from '../helpers/LinkedElementModelEvent';

export class ChangeUnitAdditionalMutator extends KitchenBaseMutator {
  constructor(private unit: IMountedUnit,
              private additionalIndex: number,
              private additional: IAdditionalAppliance,
              private readonly silent = true) {
    super();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, model: PModel<IKitchen>): [PModel<IKitchen>, ValidationResult] {
    const units = model.units;
    const unitIndex = units.findIndex(candidate => candidate.position === this.unit.position &&
      getOptionId(candidate.unit) === getOptionId(this.unit.unit));
    const unit = units[unitIndex];
    if (!unit) {
      throw new Error(`Cannot find unit ${JSON.stringify(this.unit)}`);
    }

    new UnitMatcher(unit.unit)
      .withAdditionals()
      .run((unit) => {
        const currentAdditional = unit.additionals[this.additionalIndex];

        let linkedElementEvent: LinkedElementModelEvent | undefined = undefined;

        const linkedElementsService = new LinkedElementsService(context, model, this.unit);
        if (currentAdditional !== null) {
          const childToBeReplaced = linkedElementsService.findInstalledChildElement(currentAdditional);
          if (childToBeReplaced) {
            if (childToBeReplaced.child && childToBeReplaced.child !== getOptionId(currentAdditional)) {
              return [model, ValidationResult.Error(`Child additional (id: ${getOptionId(childToBeReplaced)}) has own child (id: ${childToBeReplaced.child}) which is not his parent (id: ${getOptionId(currentAdditional)}.`)];
            }

            const childIndex = linkedElementsService.findAdditionalIndex(getOptionId(childToBeReplaced));

            if (childIndex !== undefined) {
              const unitIndex = model.units.findIndex(installedUnit => installedUnit.uiId === this.unit.uiId);
              if (unitIndex === -1) {
                throw new Error(`Couldn't find original unit in the model`);
              }

              linkedElementEvent = new LinkedElementModelEvent(LinkedElementModelEventType.REPLACE, model, childToBeReplaced);
              const firstCompatibleChild = (context.compatibleOptionValuesByPath(model,`units.${unitIndex}.unit.additionals.${childIndex}`, new FlatStructuralTransformer<IAdditionalAppliance>()) ?? [])
                .find(additional => !additional.model.child);
              unit.additionals[childIndex] = firstCompatibleChild ? firstCompatibleChild.model : null;
            }
          }
        }

        unit.additionals[this.additionalIndex] = this.additional;

        const childToBeAdded = linkedElementsService.findChildElement(this.additional);
        if (childToBeAdded && childToBeAdded._id) {
          if (childToBeAdded.model.child && childToBeAdded.model.child !== getOptionId(this.additional)) {
            return [model, ValidationResult.Error(`Child additional (id: ${getOptionId(childToBeAdded.model)}) has own child (id: ${childToBeAdded.model.child}) which is not his parent (id: ${getOptionId(this.additional)}.`)];
          }

          const childIndex = linkedElementsService.findAdditionalIndex(childToBeAdded._id);

          if (childIndex !== undefined) {
            unit.additionals[childIndex] = childToBeAdded.model;
            linkedElementEvent = new LinkedElementModelEvent(LinkedElementModelEventType.ADD, model, childToBeAdded.model);
          }
        }

        if (linkedElementEvent && !this.silent) {
          context.notifications.notify(LinkedElementModelEvent.EVENT_NAME, linkedElementEvent);
        }
      });
    return [model, ValidationResult.Valid()];
  }
}
