import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { INameImage } from '../../schema';

import './MaterialItem.scss';

export interface IProps {
  prefix?: string;
  material: INameImage;
  selected?: boolean;
  onClick?: (item: INameImage) => void;
  component?: React.ElementType;
}

export default class MaterialItem extends React.Component<IProps> {
  render() {
    const { prefix, material, selected, component: Component = "div", } = this.props;

    return (
      <Component
        className={classNames('material-item', selected && 'material-item__selected')}
        onClick={this.handleClick}
        tabIndex={0}
      >
        <div className="material-item__image-wrapper">
          <img className="material-item__image" alt={`${material.name} material preview`} src={material.image}/>
        </div>
        <h5 className="material-item__heading">
          <FormattedMessage id={`${prefix}${material.name}`}/>
        </h5>
      </Component>
    );
  }

  handleClick = () => {
    const { material, onClick } = this.props;

    if (onClick) {
      onClick(material);
    }
  };
}
