import { MutatorContext, Option, PModel, SinglePropertyMutator, ValidationResult } from '@canvas-logic/engine';
import { IKitchen, IPlate } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';

export class AddSkinalMutator extends KitchenBaseMutator {
  constructor(private skinal: Option<IPlate>) {
    super();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, model: PModel<IKitchen>): [PModel<IKitchen>, ValidationResult] {
    const mutator = new SinglePropertyMutator('skinal.plate', this.skinal);
    return context.mutate(model, mutator) as [PModel<IKitchen>, ValidationResult];
  }

}
