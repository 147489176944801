"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var option_1 = require("./option");

var engine_impl_1 = require("./engine-impl");

exports.deepCopy = function (src) {
  var shallowProperties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var setAlways = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (src === null) {
    return src;
  }

  if (src instanceof Date) {
    return new Date(src.getTime());
  }

  if (src instanceof Array) {
    var cp = [];
    src.forEach(function (v) {
      cp.push(v);
    });
    return cp.map(function (n) {
      return exports.deepCopy(n, shallowProperties);
    });
  }

  if (typeof src === 'object' && src !== {}) {
    if (src instanceof option_1.SourcedValue) {
      return src;
    }

    var _cp = Object.assign({}, src);

    _cp.__proto__ = src.__proto__;
    Object.keys(_cp).forEach(function (k) {
      if (!shallowProperties.includes(k)) {
        _cp[k] = exports.deepCopy(_cp[k], shallowProperties);
      }
    });

    if (setAlways || src.hasOwnProperty(engine_impl_1.unAvailabilitySymbol)) {
      _cp[engine_impl_1.unAvailabilitySymbol] = exports.deepCopy(src[engine_impl_1.unAvailabilitySymbol]);
    }

    return _cp;
  }

  return src;
};