import { unitElementGuard } from '../model/Model';
import { Engine, getOptionId, PModel } from '@canvas-logic/engine';
import DeserializeKitchenMutator from '../mutators/DeserializeKitchenMutator';
import { CompositeList, IAdditionalAppliance, IComposite, IKitchen, IMountedUnit, ISkinal, IWorktop } from '../schema';
import { OptionId, SerializedKitchen, SerializedKitchenLatest, SerializedUnitLatest } from './SerializedKitchen';
import { UnitMatcher } from './UnitMatcher';

export class KitchenSerializer {
  constructor(private engine: Engine, private model: IKitchen) {
  }

  serialize(): SerializedKitchenLatest {
    const json: SerializedKitchenLatest = {
      version: 4 as 4,
      upstand: getOptionId(this.model.upstand),
      worktop: this.serializeWorktop(this.model.worktop[0]),
      skinal: this.model.skinal? this.serializeSkinal(this.model.skinal): {optionId: null, count: -1},
      units: this.mapUnits(this.model.units)
    };
    console.log(json);
    return json;
  }

  serializeUnit(unit: IMountedUnit, position: number): SerializedUnitLatest {
    const additionals = new UnitMatcher(unit.unit)
      .withAdditionals()
      .map(u => u.additionals.map(additional => this.serializeAdditional(additional)), () => undefined);
    return {
      position,
      uiId: unit.uiId,
      unit: {
        openingSide: unitElementGuard(unit.unit) ? unit.unit.openingSide: undefined,
        optionId: getOptionId(unit.unit),
        additionals: additionals
      }
    };
  }

  private serializeAdditional(additional: IAdditionalAppliance | null): OptionId | null {
    return additional ? getOptionId(additional): null;
  }

  deserialize(state: SerializedKitchen): PModel<IKitchen> {
    //TODO: Replace
    const [model, validationResult] = this.engine.mutate(this.model, new DeserializeKitchenMutator(state as SerializedKitchenLatest));
    return model as PModel<IKitchen>;
  }

  private serializeComposite(composite: IComposite) {
    return {
      optionId: composite ? getOptionId(composite) : null,
      count: composite.amount
    };
  }

  private mapUnits(units: IMountedUnit[]): SerializedUnitLatest[] {
    return units.map(u => this.serializeUnit(u, u.position));
  }

  private serializeWorktop(worktop: IWorktop) {
    return {
      optionId: getOptionId(worktop),
      count: worktop.amount
    };
  }
  private serializeSkinal(skinal: ISkinal) {
    return {
      optionId: getOptionId(skinal),
      count: skinal.amount
    };
  }

  private serializePlints(plinths: CompositeList) {
    return plinths.map(plinth => {
      return {
        optionId: getOptionId(plinth),
        count: plinth.amount
      }
    })
  }
}
