import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { IMountedUnit, OpeningSide } from '../../schema';

import { unitElementGuard } from '../../model/Model';
import { OPEN_SIDE_PATH } from '../../model/ConfigurationState';

import ItemCard from '../ItemCard/ItemCard';
import Button from '../buttons/Button/Button';
import SelectorHandle from '../SelectorHandle/SelectorHandle';

import ValueSelector from '../ValueSelector/ValueSelector';
import { ElementViewStore } from '../../stores/ConfiguratorViews/ElementViewStore';
import Icon from '../Icon/Icon';
import RootStore from '../../stores/RootStore';

import './ElementDetails.scss';

interface IElementDetailsProps {
  unit: IMountedUnit;
  openOptions: OpeningSide[];
  store: ElementViewStore;
  onDelete?: () => void;
  className?: string;
}

@observer
export default class ElementDetails extends React.Component<IElementDetailsProps> {
  handleDeleteClick = () => {
    const { onDelete } = this.props;

    if (onDelete) {
      onDelete();
    }
  };

  get openOptions() {
    return this.props.openOptions.filter(item => item !== OpeningSide.NONE);
  }

  handleChangeWidth = (width: number) => {
    const { store, unit } = this.props;
    const unitToInsert = store.getRelatedUnitsFor(unit.unit).find(unit => unit.width === width);

    if (unitToInsert !== undefined) {
      store.replaceWithDifferentWidth(unit, unitToInsert)
    }
  }

  render() {
    const { unit, store, className } = this.props;
    const showOpeningSide = unitElementGuard(unit.unit) ? unit.unit.showOpeningSide : false;

    return (
      <div className={classNames('elements-card-in-details', className)}>
        <ItemCard item={unit.unit}/>
        <div className="elements-card-in-details__wrapper elements-card-in-details__wrapper--buttons">
          <Button
            className="button_with-icon"
            color='gray'
            fullWidth
            onClick={this.handleDeleteClick}
          >
            <Icon type="trash"/>
            <FormattedMessage id="ui.delete"/>
          </Button>
        </div>
        <div className="elements-card-in-details__wrapper">
          <ValueSelector
            label={RootStore.localization.formatMessage('ui.width')}
            value={unit.unit.width}
            availableValues={store.getRelatedUnitsFor(unit.unit).map(unit => unit.width)}
            onChange={this.handleChangeWidth}
          />
        </div>
        {unitElementGuard(unit.unit) && !!this.openOptions.length && unit.unit[OPEN_SIDE_PATH] !== OpeningSide.NONE &&
        <div className="elements-card-in-details__wrapper">
          {showOpeningSide && <SelectorHandle
            openOptions={this.openOptions}
            selectedMode={unit.unit[OPEN_SIDE_PATH] as any}
            onChange={mode => store.changeUnitHandleOpenSide(unit, mode)}
          />
          }
        </div>
        }
      </div>
    );
  }
}
