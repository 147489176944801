import * as React from 'react';
import classNames from 'classnames';

import './BottomSheet.scss';

function trackFinger(event: TouchEvent | MouseEvent, touchId: React.MutableRefObject<number | undefined>) {
  if (event instanceof TouchEvent && touchId.current !== undefined && event.changedTouches) {
    for (let i = 0; i < event.changedTouches.length; i += 1) {
      const touch = event.changedTouches[i];
      if (touch.identifier === touchId.current) {
        return {
          x: touch.clientX,
          y: touch.clientY,
        };
      }
    }
  } else if (event instanceof MouseEvent) {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }
  return false;
}

function getOwnerDocument(element: HTMLElement | null) {
  return element?.ownerDocument || document;
}

interface IProps {
  className?: string;
  onExpand: () => void;
}

 const BottomSheet: React.FC<IProps> = ({ className, children, onExpand }) => {
  const sheetRef = React.useRef<HTMLDivElement>(null);
  const handleRef = React.useRef<HTMLDivElement>(null);
  const touchId = React.useRef<number>();
  const dragStartPosition = React.useRef<number>(0);
  const [dragOffset, setDragOffset] = React.useState(0);
  const maxOffset = React.useRef<number>(0);

   const dragEasingFunction = React.useMemo(() => (x: number) => maxOffset.current * Math.tanh(x / maxOffset.current), []);

   const calculateSheetTransform = () => {
     const { current: sheet } = sheetRef;

     return getOwnerDocument(sheet).documentElement.clientHeight - dragStartPosition?.current + dragOffset;
   };

   const expandSheet = React.useCallback( () => {
     setDragOffset(Infinity);
     const { current: sheet } = sheetRef;
     if (sheet) {
       sheet.addEventListener('transitionend', onExpand, { once: true });
     }
   }, [setDragOffset]);

   const handleTouchStart = (event: TouchEvent) => {
    // Workaround as Safari has partial support for touchAction: 'none'.
    event.preventDefault();
    const touch = event.changedTouches[0];
    if (touch != null) {
      // A number that uniquely identifies the current finger in the touch session.
      touchId.current = touch.identifier;
    }

    const finger = trackFinger(event, touchId);
    if (finger) {
      dragStartPosition.current = finger.y;
    }

    const doc = getOwnerDocument(handleRef.current);
    doc.addEventListener('touchmove', handleTouchMove);
    doc.addEventListener('touchend', handleTouchEnd);
  };

  const handleTouchMove = (event: TouchEvent) => {
    const finger = trackFinger(event, touchId);
    if (finger) {
      const calculatedDragOffset = dragStartPosition?.current - finger.y;
      setDragOffset(calculatedDragOffset < 0 ? 0 : dragEasingFunction(calculatedDragOffset));
    }
  };

  const handleTouchEnd = (event: TouchEvent) => {
    const finger = trackFinger(event, touchId);

    if (!finger) {
      return;
    }

    touchId.current = undefined;
    if (dragStartPosition?.current - finger.y > maxOffset.current / 3) {
      expandSheet();
    } else {
      setDragOffset(0);
      dragStartPosition.current = 0;
    }

    const doc = getOwnerDocument(handleRef.current);
    doc.removeEventListener('touchmove', handleTouchMove);
    doc.removeEventListener('touchend', handleTouchEnd);
  };

  React.useEffect(() => {
    const { current: handle } = handleRef;
    if (handle !== null) {
      handle.addEventListener('touchstart', handleTouchStart)
    }

    maxOffset.current = getOwnerDocument(handle).documentElement.clientHeight * 0.2;

    return () => {
      if (handle !== null) {
        handle.removeEventListener('touchstart', handleTouchStart)
      }
    };
  }, [handleTouchStart]);

  const sheetStyle = {
    transform: (dragOffset !== 0 && dragOffset !== Infinity) ? `translate(-50%, calc(100% - ${calculateSheetTransform()}px))` : undefined,
    '--progress': dragOffset === Infinity ? 1 : (dragOffset / maxOffset.current) || 0,
  };

  return (
    <div
      className={classNames('bottom-sheet', {
        'bottom-sheet--idle': dragOffset === 0,
        'bottom-sheet--expanded': dragOffset === Infinity
      }, className)}
      onClick={expandSheet}
      tabIndex={0}
      style={sheetStyle}
      ref={sheetRef}
    >
      <div className='bottom-sheet__handle' ref={handleRef} />
      <div className='bottom-sheet__content'>
        {children}
      </div>
    </div>
  );
};

export default BottomSheet;
