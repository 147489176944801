import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import './SettingsItem.scss';

interface ISettingsItem {
  name: string;
  image: string;
}

export interface IProps {
  property: string;
  value: ISettingsItem;
  onChange: (property: string) => void;
}

@observer
export default class SettingsItem extends React.Component<IProps> {
  render() {
    const { property, value } = this.props;
    return (
      <li className="basic-settings-card" onClick={this.handleClick} tabIndex={0}>
        <h5 className="basic-settings-card__heading">
          <FormattedMessage id={`ui.settings.properties.${property}`}/>
        </h5>
        {!!value &&
        <>
          <div className="basic-settings-card__image-wrapper"
               key={value.image}
               style={{ backgroundImage: `url(${value.image})`, backgroundSize: 'contain' }}>
          </div>
          <p className="basic-settings-card__description">
            <FormattedMessage id={`content.property.${property}.${value.name}`}/>
          </p>
        </>}
      </li>
    );
  }

  handleClick = () => {
    const { property, onChange } = this.props;
    onChange(property)
  }
}
