import * as React from 'react';
import { Component } from 'react';
import classNames from 'classnames';

import IconButton from '../../../../components/buttons/IconButton/IconButton';

import './LocaleSwitchButton.scss';
import LocaleSelector from '../LocaleSelector/LocaleSelector';
import { Locale } from '../../../../stores/Localization';
import { IconName } from '../../../../components/Icon/Icon';
import RootStore from '../../../../stores/RootStore';

interface IProps {
  currentLocale: Locale;
  onSwitchLocale: (locale: Locale) => void;
}

interface IState {
  popupVisible: boolean;
}

class LocaleSwitchButton extends Component<IProps, IState> {
  state = {
    popupVisible: false,
  };

  popupRef = React.createRef<HTMLDivElement>();

  shouldShowLocaleSwitch = () => {
    return (process.env.REACT_APP_CL__FEATURE__LOCALE_SWITCH === 'ON' && !RootStore.isEmbedded) ||
      (process.env.REACT_APP_CL__FEATURE__LOCALE_SWITCH === 'PROGRESSIVE' && !RootStore.localeControlledExternally);
  }

  render() {
    if (this.shouldShowLocaleSwitch()) {
      const { popupVisible } = this.state;
      const { currentLocale, onSwitchLocale } = this.props;

      return (
        <div className='locale_switch__wrapper' onBlur={this.handleBlur}>
          <div
            className={classNames('locale_switch__popup', { 'locale_switch__popup--visible': popupVisible })}
            ref={this.popupRef}
            tabIndex={0}
          >
            <LocaleSelector value={currentLocale} onChange={onSwitchLocale} />
          </div>
          <IconButton
            icon={`lang_${Locale[currentLocale].toLowerCase()}` as IconName}
            altIcon="close"
            showAltIcon={popupVisible}
            onClick={this.handleClick}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  handleBlur = (event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      this.closePopup();
    }
  };

  closePopup = () => {
    this.setState({
      popupVisible: false
    })
  };

  handleClick = async () => {
    const { popupVisible } = this.state;

    if (popupVisible) {
      this.closePopup();
    } else {
      const { current: popup } = this.popupRef;

      if (popup) {
        popup.addEventListener('animationend', this.handlePopupTransitionEnd);
      }
      this.setState({
        popupVisible: true
      });
    }
  }
  handlePopupTransitionEnd = () => {
    const { current: popup } = this.popupRef;
    if (popup) {
      popup.removeEventListener('animationend', this.handlePopupTransitionEnd);
      popup.focus();
    }
  }
}

export default LocaleSwitchButton;
