import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import SmallButton from '../../../components/buttons/SmallButton/SmallButton';
import WidthFilter from '../../../components/ElementsList/WidthFilter';
import Icon from '../../../components/Icon/Icon';

import { isElementSkinal, SelectElementState, SelectElementViewStore } from '../../../stores/ConfiguratorViews/SelectElementViewStore';

import './SelectElement.scss';
import { upstandGuard } from '../../../model/Model';
import ItemCard from '../../../components/ItemCard/ItemCard';

interface IProps {
  store: SelectElementViewStore;
}

@observer
export default class SelectElement extends React.Component<IProps> {
  render() {
    const { store } = this.props;

    return (
      <div className={classNames('select-element', { 'select-element--placement': store.state === SelectElementState.PLACEMENT })}>
        <div className="select-element__panel">
          <SmallButton
            className="select-element__panel__button"
            color="transparent"
            onClick={store.handleBack}
          >
            <Icon type='leftArrow'/>
          </SmallButton>
          <FormattedMessage id="ui.elements.adding"/>
          <SmallButton
            className="select-element__panel__button"
            color="transparent"
            onClick={store.handleCancel}
          >
            <Icon type='close'/>
          </SmallButton>
        </div>
        <div className='select-element__container'>
          {store.availableWidths.length > 1 &&
          <div className="select-element__filter-panel">
            <WidthFilter
              active={store.widthFilter}
              width={store.availableWidths}
              onChange={store.applyFilter}
            />
          </div>
          }
          <ul className='select-element__list'>
            {store.filteredUnits
              .map(option => {
                let installed = 0;
                if (isElementSkinal(option)) {
                  installed = (store.skinal && store.skinal.variantId === option.model.variantId) ? store.skinal.amount : 0;
                } else {
                  installed = store.installedUnits.filter(item => item === option._id).length;
                }

                return (
                  <li className='select-element__item' key={option._id}>
                    <ItemCard item={option.model}
                              installed={installed}
                              onClick={() => store.handleSelectElement(option)}
                              isSelected={store.elementToInsert?._id === option._id}
                    />
                  </li>);
              })}
          </ul>
        </div>
      </div>
    );
  }
}
