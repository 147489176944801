import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Pill, { PillAlignment } from '../Pill/Pill';
import { Converter } from '../../helpers/Converter';

import './ValueSelector.scss';
import PillList from '../PillList/PillList';


interface IProps {
  label?: string;
  value: number;
  availableValues: number[];

  onChange: (value: number) => void;
}

export default class ValueSelector extends Component<IProps> {

  render() {
    const { label, value, availableValues, onChange } = this.props;

    const sortedValues = availableValues.slice().sort((a, b) => { return a - b; });

    return sortedValues.length > 0 && <div className="value-selector">
      {label && <span className="value-selector__label">
        {label}
      </span>}
      <PillList className="value-selector__pill-list">{sortedValues.map((availableValue, index) =>
        <li key={index}>
          <Pill
            className="value-selector__pill"
            active={availableValue === value}
            alignment={PillAlignment.Center}
            onClick={() => onChange(availableValue)}
          >
            <div>{Converter.mmToCm(availableValue)}</div>
            <div className="value-selector__measure"><FormattedMessage id="ui.units.cm"/></div>
          </Pill>
        </li>)}
      </PillList>
    </div>
  }
}
