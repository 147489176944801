import { IAdditionalAppliance, OpeningSide, ReportCategory, VariantId, Visibility } from '../schema';


export default abstract class TypeMatcher<T> {
  constructor(protected entity: T, protected satisfy: boolean = true) {
  }

  map<R>(mapper: (entity: T) => R, altMapper: () => R) {
    return (this.satisfy) ? mapper(this.entity) : altMapper();
  }

  run(match: (entity: T) => void, notMatched?: () => void) {
    if (this.satisfy) {
      match(this.entity);
    } else {
      notMatched && notMatched();
    }
    return this;
  };
}

export interface WithVisibility {
  visibility: Visibility
}

export interface WithArticleNumber {
  articleNumber: string
}

export interface WithAdditionals {
  additionals: (IAdditionalAppliance | null)[]
}

export interface WithOpeningSide {
  openingSide: OpeningSide
}

export interface WithReportCategory {
  reportCategory: ReportCategory
}

export interface WithVariantId {
  variantId: VariantId
}

export interface WithPrice {
  price: number
}
