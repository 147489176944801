import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import SmallButton from '../../../../src/components/buttons/SmallButton/SmallButton';
import Icon from '../../../../src/components/Icon/Icon';

import { EditWorktopViewStore } from '../../../stores/ConfiguratorViews/EditWorktopViewStore';
import MaterialItem from '../../../components/MaterialItem/MaterialItem';
import ValueSelector from '../../../components/ValueSelector/ValueSelector';
import RootStore from '../../../stores/RootStore';

import './EditWorktop.scss';
import Button from '../../../components/buttons/Button/Button';


interface IProps {
  store: EditWorktopViewStore;
}

@observer
export default class EditWorktop extends React.Component<IProps> {
  render() {
    const { store } = this.props;
    const { worktop, depths } = store;

    return (
      <div className="edit-worktop">
        <div className="edit-worktop__panel">
          <SmallButton
            color="transparent"
            onClick={store.handleBack}
          >
            <Icon type='leftArrow'/>
          </SmallButton>
          <FormattedMessage id={`ui.settings.properties.worktop`}/>
          <SmallButton
            color="transparent"
            disabled
          />
        </div>
        <div>
          <div className="edit-worktop__options">
            <ValueSelector
              label={RootStore.localization.formatMessage('ui.depth')}
              value={worktop.depth}
              availableValues={depths}
              onChange={store.changeWorktopDepth}
            />
          </div>

          <div className="edit-worktop__container">
            <h2 className="edit-worktop__heading">
              <FormattedMessage id="ui.settings.texture"/>
            </h2>
            <ul className="edit-worktop__list">
              {
                store.worktopMaterials.map(material => (
                  <MaterialItem
                    prefix="content.property.worktop."
                    material={material}
                    selected={material.name === worktop.name}
                    onClick={store.changeWorktopMaterial}
                    component="li"
                  />
                ))
              }
            </ul>
          </div>
        </div>
        <div className="edit-worktop__panel--bottom">
          <Button className="edit-worktop__add-button" onClick={store.handleAddComponent}>
            <Icon type='plus_24'/>
            <FormattedMessage id="ui.elements.add"/>
          </Button>
        </div>
      </div>
    );
  }
}
