import { unitElementGuard, upstandGuard } from '../model/Model';
import { PModel } from '@canvas-logic/engine';
import { IKitchen, IMountedUnit, INameImage, IUnitElement, UnitKind } from '../schema';

class LayoutService {
  unitsOnSameLine(unitType: UnitKind, nodeType: UnitKind) {
    return (unitType === UnitKind.tall && nodeType === UnitKind.bottom) ||
      (unitType === UnitKind.bottom && nodeType === UnitKind.tall) ||
      (unitType === UnitKind.top && nodeType === UnitKind.tall) ||
      unitType === nodeType
  }

  floorUnitsWidth(model: IKitchen) {
    return this.floorUnits(model).reduce((acc, next) => acc + next.unit.width, 0);
  }

  ceilUnitsWidth(model: IKitchen) {
    return this.ceilUnits(model).reduce((acc, next) => acc + next.unit.width, 0);
  }

  bottomUnitsWidth(model: IKitchen) {
    return this.bottomUnits(model).reduce((acc, next) => acc + next.unit.width, 0);
  }

  floorUnits(model: IKitchen) {
    return model.units.filter(unit => this.isFloorUnit(unit.unit.unitKind as UnitKind));
  }

  unitsSortedByPosition(units: IMountedUnit[]) {
    return units.sort((a, b) => (a.position > b.position) ? 1 : -1);
  }

  firstFloorUnit(model: IKitchen) {
    const sortedUnits = this.unitsSortedByPosition(this.floorUnits(model));
    return sortedUnits.length > 0 ? sortedUnits[0] : undefined;
  }

  topUnitsWidthWithoutPlaceholders(model: IKitchen) {
    const sorted = this.unitsSortedByPosition(this.topAndTallUnits(model)).reverse();
    const index = sorted.findIndex(unit => !unit.unit.view.startsWith('placeholder'));
    if (index < 0) {
      return 0;
    }
    let widthSum = 0;
    for (let i = index; i < sorted.length; i++) {
      const width = sorted[i]?.unit.width;
      widthSum += width;
    }
    return widthSum;
  }


  bottomUnits(model: IKitchen) {
    return model.units.filter(unit => this.isBottomUnit(unit.unit.unitKind as UnitKind));
  }

  notBottomUnits(model: IKitchen) {
    return model.units.filter(unit => !this.isBottomUnit(unit.unit.unitKind as UnitKind));
  }

  ceilUnits(model: IKitchen) {
    return model.units.filter(unit => unit.unit.unitKind === UnitKind.top);
  }

  tallUnits(model: IKitchen) {
    return model.units.filter(unit => unit.unit.unitKind === UnitKind.tall);
  }

  workTopUnit(model: IKitchen) {
    return model.worktop.find(worktop => worktop);
  }

  leftTallUnitsWidth(model: IKitchen) {
    const sorted = this.unitsSortedByPosition(model.units);
    let widthSum = 0;
    let i = 0;
    while (sorted[i]?.unit.unitKind === UnitKind.tall) {
      widthSum += sorted[i]?.unit.width;
      i++;
    }
    return widthSum;
  }

  topUnits(model: PModel<IKitchen>) {
    return model.units.filter(unit => unit.unit.unitKind === UnitKind.top);
  }

  topAndTallUnits(model: IKitchen) {
    return model.units.filter(unit => unit.unit.unitKind === UnitKind.top || unit.unit.unitKind === UnitKind.tall);
  }

  valid(model: IKitchen): boolean {

    const talls = this.tallUnits(model).map(u => u.position).sort();
    let twoSideTalls = false;
    for (let i = 0; i < talls.length; i++) {
      if (talls[i] !== i) {
        twoSideTalls = true;
      }
    }

    if (twoSideTalls) {
      return this.bottomUnitsWidth(model) >= this.ceilUnitsWidth(model);
    } else {
      return true;
    }
  }

  isFloorUnit(nodeType: UnitKind) {
    return nodeType === UnitKind.bottom || nodeType === UnitKind.tall;
  }

  isNotBottomUnit(nodeType: UnitKind) {
    return nodeType === UnitKind.top || nodeType === UnitKind.tall;
  }

  isBottomUnit(nodeType: UnitKind) {
    return nodeType === UnitKind.bottom;
  }

  isTopUnit(nodeType: UnitKind) {
    return nodeType === UnitKind.top;
  }

  hasStartingTall(units: IMountedUnit[]) {
    const sorted = units.filter(u => this.isFloorUnit(u.unit.unitKind as UnitKind)).sort((a, b) => a.position - b.position);
    return sorted.length > 0 && sorted[0].unit.unitKind === UnitKind.tall;
  }

  hasFinishingTall(units: IMountedUnit[]) {
    const sorted = units.filter(u => this.isFloorUnit(u.unit.unitKind as UnitKind)).sort((a, b) => b.position - a.position);
    return sorted.length > 0 && sorted[0].unit.unitKind === UnitKind.tall;
  }

  getStartingTalls(units: IMountedUnit[]): IMountedUnit[] {
    const sorted = units.slice().sort((a, b) => a.position - b.position);
    const result = [];
    let i = 0;
    while (i < sorted.length && sorted[i].unit.unitKind === UnitKind.tall) {
      result.push(sorted[i]);
      i++;
    }
    return result;
  }

  getFinishingTalls(units: IMountedUnit[]) {
    const sorted = units.slice().sort((a, b) => b.position - a.position);
    const result = [];
    let i = 0;
    while (i < sorted.length && sorted[i].unit.unitKind === UnitKind.tall) {
      result.unshift(sorted[i]);
      i++;
    }
    // Handles case when only tall units. In this case no finishing talls
    return result.length === units.length ? [] : result;
  }

  getLastUnit(units: IMountedUnit[]) {
    let maxPosition = -Infinity;
    let result = null;
    for (let unit of units) {
      if (unit.position > maxPosition) {
        maxPosition = unit.position;
        result = unit;
      }
    }
    return result;
  }

  getFirstUnit(units: IMountedUnit[]) {
    let minPosition = Infinity;
    let result = null;
    for (let unit of units) {
      if (unit.position < minPosition) {
        minPosition = unit.position;
        result = unit;
      }
    }
    return result;
  }

  getUpstandMaterial(model: IKitchen): INameImage | undefined {
    return this.hasUpstand(model) ? model.upstand : undefined;
  }

  /***
   * Updates unit positions, so units positions make a sequence.
   * There are two sequences: top units and floor units (tall and bottom). Initial value for top position is either
   * next value to the last starting tall, or 0 in case there is no tall units before it.
   * Floor units always start with 0.
   * @param model
   */
  normalizeKitchen(model: IKitchen): Map<number, number> {
    let lastFloorPosition = 0;
    let lastTopPosition = 0;
    const topUnits: IMountedUnit[] = [];
    const bottomUnits: IMountedUnit[] = [];
    const tallUnits: IMountedUnit[] = [];
    let startingTall = true;
    let positionMap = new Map<number, number>();
    // Store index to keep track on unit
    model.units.forEach((u, i) => (u as any).__index = i);

    model.units.sort((a, b) => a.position - b.position).forEach(unit => {
      switch (unit.unit.unitKind) {
        case 'top':
          startingTall = false;
          topUnits.push(unit);
          unit.position = lastTopPosition++;
          break;
        case 'bottom':
          startingTall = false;
          bottomUnits.push(unit);
          unit.position = lastFloorPosition++;
          break;
        case 'tall':
          tallUnits.push(unit);
          unit.position = lastFloorPosition++;
          if (startingTall) {
            lastTopPosition++;
          }
          break;
      }
    });
    (model as any).units = bottomUnits.concat(tallUnits, topUnits);
    // Build map, clear unit
    model.units.forEach(u => {
      const unit = u as any;
      positionMap.set(unit.__index, u.position);
      unit.__index = undefined;
    });
    return positionMap;
  }

  hasUpstand(model: IKitchen) {
    return model.units.some(unit => !!upstandGuard(unit.unit))
  }

  lastTopUnit(model: IKitchen): IMountedUnit | undefined {
    const topUnits = this.topUnits(model);
    return topUnits[topUnits.length - 1];
  }

  topUnitsWidth(model: IKitchen) {
    let sum = 0;
    const units = this.topUnits(model);
    for (let unit of units) {
      sum += unit.unit.width;
    }
    return sum;
  }

  wallWidth(model: IKitchen) {
    const floorWidth = this.floorUnitsWidth(model);
    let topAndTallWidth = 0;
    const wallAndTall = this.topAndTallUnits(model)
    for (let unit of wallAndTall) {
      topAndTallWidth += unit.unit.width;
    }
    return Math.max(floorWidth, topAndTallWidth);
  }
}


export const layoutService = new LayoutService();
