import * as React from 'react';
import classNames from 'classnames';

import './Button.scss';

interface IButtonProps {
  className?: string;
  color?: 'primary' | 'white' | 'gray' | 'transparent';
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

class Button extends React.Component<IButtonProps> {

  defaults = {
    onClick: noop
  };

  render() {
    const { color, className, disabled, onClick, fullWidth, children } = this.props;
    return (
      <button
        className={classNames('button', {
          'button_white': color === 'white',
          'button_gray': color === 'gray',
          'button_transparent': color === 'transparent',
          'button_full-width': fullWidth,
        }, className)}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
}

function noop() {

}

export default Button;
