import { render } from 'ejs';
import * as THREE from 'three';

type Source = HTMLElement | Element;
type Mapper = (element: Source, children: THREE.Object3D[]) => Promise<THREE.Object3D>;

export interface ITraversable {
  traverse(fn: (node: THREE.Object3D) => void): void;

  scene: THREE.Object3D;
}

export interface ILoader {
  load(path: string, successCallback: (resource: ITraversable) => void, progressCallback: () => void, failCallback: (error: ErrorEvent) => void): void;
}

export interface IDependencies {
  loader: ILoader;
}

const ROTATE_X = 'rotateX';
const ROTATE_Y = 'rotateY';
const ROTATE_Z = 'rotateZ';
const TRANSLATE_X = 'x';
const TRANSLATE_Y = 'y';
const TRANSLATE_Z = 'z';
const SCALE_X = 'scaleX';
const SCALE_Y = 'scaleY';
const SCALE_Z = 'scaleZ';
const URL = 'url';
const NAME = 'name';
const DATA = 'data';
const INCLUDE = 'include';

const copyAttributes = (from: Source, to: THREE.Object3D) => {
  const rotateX = from.getAttribute(ROTATE_X);
  if (rotateX) {
    to.rotation.x = parseFloat(rotateX);
  }
  const rotateY = from.getAttribute(ROTATE_Y);
  if (rotateY) {
    to.rotation.y = parseFloat(rotateY);
  }
  const rotateZ = from.getAttribute(ROTATE_Z);
  if (rotateZ) {
    to.rotation.z = parseFloat(rotateZ);
  }

  const translateX = from.getAttribute(TRANSLATE_X);
  if (translateX) {
    to.position.x = parseFloat(translateX);
  }

  const translateY = from.getAttribute(TRANSLATE_Y);
  if (translateY) {
    to.position.y = parseFloat(translateY);
  }
  const translateZ = from.getAttribute(TRANSLATE_Z);
  if (translateZ) {
    to.position.z = parseFloat(translateZ);
  }

  const scaleX = from.getAttribute(SCALE_X);
  if (scaleX) {
    to.scale.x = parseFloat(scaleX);
  }

  const scaleY = from.getAttribute(SCALE_Y);
  if (scaleY) {
    to.scale.y = parseFloat(scaleY);
  }

  const scaleZ = from.getAttribute(SCALE_Z);
  if (scaleZ) {
    to.scale.z = parseFloat(scaleZ);
  }

  const name = from.getAttribute(NAME);
  if (name) {
    to.name = name;
  }

  const userData = from.getAttribute(DATA);
  if (userData) {
    to.userData = JSON.parse(userData);
  }
};
const noop = () => {
};

/** Provides some helpers that can be used inside ejs template
 * For example, "serialize" - should be used to pass non-primitive values
 */
class Api {
  /**
   * Converts name to file-safe name. Replaces all spaces with _. Multiple spaces will be replaced with 1 _;
   * @param name - name of the file
   */
  fileName(name: string) {
    return name.split(' ').filter(v => v !== '').join('_');
  }

  serialize(obj: any) {
    return JSON.stringify(obj);
  }
}

const api = new Api();

export default class TemplateEngine {
  private readonly loader: ILoader;
  private readonly materials: Map<string, THREE.Material> = new Map<string, THREE.Material>();
  private readonly loadingMaterials: Promise<Map<string, THREE.Material>>;
  private cachedTemplates: Map<string, Promise<string>> = new Map<string, Promise<string>>();

  constructor(dependencies: IDependencies, private materialLib: string, private maxAnisotropy = 2) {
    this.loader = dependencies.loader;
    this.loadingMaterials = new Promise((resolve, reject) => {
      try {
        this.loader.load(materialLib, (resource: ITraversable) => this.saveMaterials(resolve, resource), noop, noop);
      } catch (e) {
        reject(e);
      }
    });
  }

  saveMaterials(resolve: any, resource: ITraversable) {
    resource.traverse((node: THREE.Object3D) => {
      if (node instanceof THREE.Mesh && node.material) {
        const material = node.material;
        if (Array.isArray(material)) {
          material.forEach(m => this.addMaterialToList(m));
        } else {
          this.addMaterialToList(material);
        }
      }
    });

    Promise.all([
      // this.addWood(),
      // this.addStainlessMetal(),
      // this.addWhiteRough()
    ])
      .then(() => {
        resolve(this.materials);
      });

  }

  resources: Map<string, Promise<ITraversable>> = new Map<string, Promise<ITraversable>>();

  async build(template: string, model: any): Promise<THREE.Object3D[]> {
    const nodes: Promise<THREE.Object3D>[] = [];
    const value = createModel(model);
    const result = render(template || '', value);
    if (!result) {
      return Promise.resolve([]);
    }

    await this.loadingMaterials;
    const root = document.createElement('root');
    root.innerHTML = result;
    const expandedResult: HTMLElement = await this.expandAllIncludes(root);

    for (let child of expandedResult.children) {
      nodes.push(this.mapTree(child, (element, children) => this.mapper(element, children)))
    }
    return await Promise.all(nodes);
  }

  async mapTree(element: Source, fn: Mapper): Promise<THREE.Object3D> {
    const children: THREE.Object3D[] = [];
    if (element.tagName.toLowerCase() !== 'model') {
      for (const child of element.children) {
        children.push(await this.mapTree(child, fn));
      }
    }
    return fn(element, children);
  }

  async mapper(element: Source, children: THREE.Object3D[]) {
    switch (element.tagName.toLowerCase()) {
      case 'node':
        return this.nodeMapper(element, children);
      case 'model':
        return this.modelMapper(element);
    }
    return Promise.reject(new Error(`Invalid tagName ${element.tagName}. Use either node or model `));
  };

  async nodeMapper(element: Source, children: THREE.Object3D[]) {
    const node = new THREE.Object3D();
    copyAttributes(element, node);
    // if (!children.every(child => (child instanceof THREE.Object3D))) {
    //   throw new Error('Not valid child for "node"')
    // }
    children.forEach(child => node.add(child));
    return Promise.resolve(node);
  };

  async modelMapper(element: Source) {
    const url = element.getAttribute(URL);
    if (!url) {
      return Promise.reject(new Error('Model should specify "url" attribute'));
    }
    if (!this.resources.has(url)) {
      this.resources.set(url, new Promise<ITraversable>((resolve, reject) => {
        try {
          this.loader.load(
            url,
            (resource: ITraversable) => {
              resolve(resource);

            },
            () => {
            },
            (error: ErrorEvent) => {
              reject(error);
            }
          );
        } catch (e) {
          return Promise.reject(e);
        }
      }))
    }
    const result = this.resources.get(url) as Promise<ITraversable>;
    return result
      .then(resource => {
        const root = new THREE.Object3D();
        for (let i = 0; i < resource.scene.children.length; i++) {
          root.children.push(resource.scene.children[i]);
        }
        filterTree(root, (node: THREE.Object3D) => {
          if (node instanceof THREE.Mesh) {
            if (!(node.name.startsWith('floor') || node.name.startsWith('wall') || node.name.startsWith('Placeholder')))
              node.castShadow = true;
            node.receiveShadow = true;
            if (node.name.startsWith('Placeholder')) {
              // node.material = new THREE.MeshBasicMaterial({color: 0xffffff})
            }
            this.updateNodeAttributes(node, element.children);
          }
          if (node.name.startsWith('boundary')) {
            node.visible = false;
          }
          return node instanceof THREE.Mesh || node instanceof THREE.Group;
        });
        const object = resource.scene;

        const result = new THREE.Object3D();
        result.copy(object, true);
        result.name = 'UniContainer';
        copyAttributes(element, result);
        return result;
      })
  };

  private updateNodeAttributes(node: THREE.Mesh, setters: HTMLCollection) {
    for (let i = 0; i < setters.length; i++) {
      const name     = setters[i].getAttribute('name'),
            material = setters[i].getAttribute('material');
      if (!name) {
        throw new Error(`Attribute name in 'item' is required`);
      }
      if (node.name.match(name)) {
        node.material = this.getMaterialByName(material);
        node.material.needsUpdate = true;
      }
    }
  }

  private getMaterialByName(material: string | null) {
    if (material) {
      const libMaterial = this.materials.get(material);
      if (libMaterial) {
        return libMaterial;
      }
    }

    switch (material) {
      case 'white':
        return new THREE.MeshBasicMaterial({ color: 0xffffff });
      case 'black':
        return new THREE.MeshBasicMaterial({ color: 0x000000 });
      default:
        return new THREE.MeshBasicMaterial({ color: 0x2194CE });
    }
  }

  getMaterial(materialName: string) {
    return this.materials.get(materialName);
  }

  private loadTexture(url: string) {
    return new Promise<THREE.Texture>((resolve, reject) => {
      new THREE.TextureLoader().load(url, resolve, noop, reject);
    });
  }

  private addWood() {
    return Promise.all([
      this.loadTexture('assets/models/textures/wood/Wood30_col.jpg'),
      this.loadTexture('assets/models/textures/wood/Wood30_disp.jpg'),
      this.loadTexture('assets/models/textures/wood/Wood30_nrm.jpg'),
      this.loadTexture('assets/models/textures/wood/Wood30_rgh.jpg')
    ]).then(([map, displacementMap, normalMap, roughnessMap]) => {
      map.encoding = THREE.sRGBEncoding;
      map.anisotropy = this.maxAnisotropy;
      const wood = new THREE.MeshStandardMaterial({
        map,
        bumpMap: displacementMap,
        // displacementMap,
        // normalMap,
        roughnessMap
      });
      wood.name = 'Wood';
      this.addMaterialToList(wood);

    });
  }

  private addWhiteRough() {
    return Promise.all([
      this.loadTexture('assets/models/textures/stainless_metal/stainless_nrm.png'),
      this.loadTexture('assets/models/textures/stainless_metal/stainless_metal_rgh.png')
    ]).then(([normalMap, roughnessMap]) => {
      // map.encoding = THREE.sRGBEncoding;
      // map.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      const material = new THREE.MeshStandardMaterial({
        color: 'white',
        // bumpMap: displacementMap,
        // displacementMap,
        // normalMap,
        roughnessMap
      });
      material.name = 'White_rough2';
      this.addMaterialToList(material);

    });
  }

  private addStainlessMetal() {
    return Promise.all([
      this.loadTexture('assets/models/textures/stainless_metal/stainless_metal_clr.png'),
      this.loadTexture('assets/models/textures/stainless_metal/stainless_metal_rgh.png')
    ]).then(([map, roughnessMap]) => {
      map.encoding = THREE.sRGBEncoding;
      map.anisotropy = this.maxAnisotropy;
      const stainless = new THREE.MeshStandardMaterial({
        map,
        // bumpMap: displacementMap,
        // displacementMap,
        // normalMap,
        // roughnessMap
      });
      stainless.metalness = 1;
      stainless.name = 'Stainless_metail';
      this.addMaterialToList(stainless);
    });
  }

  private addMaterialToList(material: THREE.Material) {
    if (material instanceof THREE.MeshStandardMaterial) {
      // material.lightMap = new THREE.TextureLoader().load('assets/models/textures/lightmap.png')
      if (material.map) {
        material.map.anisotropy = this.maxAnisotropy;
        material.map.encoding = THREE.sRGBEncoding;
      }

    }
    // material.flatShading = false;
    this.materials.set(material.name, material);
  }

  private async expandAllIncludes(root: HTMLElement): Promise<HTMLElement> {
    await traverseHTMLElement(root, async (element: HTMLElement) => {
      if (element.tagName === INCLUDE.toUpperCase()) {
        if (element.parentElement) {
          //Parses and loads template
          const path = element.getAttribute('path');
          const fallback = element.getAttribute('fallback');
          const modelJSON = element.getAttribute('model');
          const model = JSON.parse(modelJSON!);
          if (!path) {
            throw new Error('Error. Please, provide required attribute "path" for include');
          }
          const template = await this.fetchInclude(path, fallback);
          // Creates Html Tree
          const includeRoot = document.createElement('root');

          includeRoot.innerHTML = render(template, createModel(model));
          const cleanIncludeRoot = await this.expandAllIncludes(includeRoot);
          // Replaces include tag with its children
          if (cleanIncludeRoot.children.length) {
            let nextElement: Element = element as HTMLElement;
            const children = Array.from(cleanIncludeRoot.children);
            for (let child of children) {
              insertAfter(child, nextElement);
              nextElement = child;
            }
            element.parentElement.removeChild(element);
          } else {
            element.remove();
          }
        } else {
          console.error('Invalid include element! Cannot find parentElement');
        }
      }
    });
    return root;
  }


  private async fetchInclude(path: string | null, fallback: string | null): Promise<string> {
    let template = '';
    try {
      template = await this.fetchAndCache(path!);
    } catch (e) {
      console.error(e);
      if (fallback) {
        return await this.fetchAndCache(fallback);
      } else {
        throw new Error(`Cannot retrieve template ${path}. Check file name or provide fallback`);
      }
    }

    // Html file was loaded by webpack
    if (template.match(/<html/ig)) {
      if (fallback) {
        return await this.fetchAndCache(fallback);
      } else {
        throw new Error(`Cannot retrieve template ${path}. Check file name or provide fallback`);
      }
    } else {
      return template;
    }
  }

  private async fetchAndCache(path: string) {
    if (!this.cachedTemplates.has(path)) {
      const response = await fetch(path);
      if (response.ok) {
        this.cachedTemplates.set(path, response.text());
      } else {
        throw new Error(`Can't load ${path}, status:${response.status}`)
      }
    }
    return this.cachedTemplates.get(path)!;
  }
}

async function traverseHTMLElement(element: HTMLElement, fn: any) {
  const q: HTMLElement[] = [element];
  while (q.length > 0) {
    const next = q.pop();
    await fn(next);
    for (let child of next!.children) {
      q.push(child as HTMLElement);
    }
  }
}

function insertAfter(newNode: Element, referenceNode: Element) {
  referenceNode?.parentNode?.insertBefore(newNode, referenceNode.nextSibling);
}


/***
 * Enriches model with helper API
 * @param model
 */
function createModel(model: any) {
  model.__proto__ = api;
  return model;
}

function removeNode(node: THREE.Object3D) {
  if (node.parent) {
    node.parent.remove(node);
  }
}

function filterTree(node: THREE.Object3D, predicate: (candidate: THREE.Object3D) => boolean) {
  if (!node.children.length) {
    if (!predicate(node)) {
      removeNode(node);
    }
    return;
  }

  // keep root node
  if (!node.parent || predicate(node)) {
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];
      filterTree(child, predicate);
    }
  } else {
    removeNode(node);
  }
}
