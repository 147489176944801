import React from 'react';
import classNames from "classnames";
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import { OptionGroup } from '@canvas-logic/engine';
import Icon from '../Icon/Icon';

import './ElementGroup.scss';

interface IElementGroupProps {
  group: OptionGroup;
  className?: string;
  onClick: (id: string) => void;
}

export default class ElementGroup extends React.Component<IElementGroupProps> {

  render() {
    const { group, className } = this.props;
    const model = this.props.group.model as any;
    return (
      <li className={classNames('navigation__item', className)} onClick={this.clickHandler}>
        <div className="navigation__link">
          <span className="navigation__link-icon">
            {model.icon && (model.icon.indexOf('svg') > -1) && <i><ReactSVG src={model.icon} /></i>}
            {model.icon && (model.icon.indexOf('svg') < 0) && <Icon type={model.icon as any} />}
          </span>
          <h4 className="navigation__link-title">
            <FormattedMessage id={`content.${model.name}`} />
          </h4>
          <p className="navigation__link-text">
            <FormattedMessage id="ui.elements.amount.label" values={{ amount: group.children.length }} />
          </p>
          <Icon className="navigation__link-icon-arrow" type="leftArrow" />
        </div>
      </li>
    );
  }

  clickHandler = () => this.props.onClick(this.props.group._id || '')
}
