import * as React from 'react';
import classNames from 'classnames';

import { ReactComponent as Error } from './images/error.svg';
import { ReactComponent as Line } from './images/line.svg';
import { ReactComponent as Stand } from './images/stand.svg';
import { ReactComponent as Square } from './images/square.svg';
import './RenderArt.scss'

interface IProps {
  error?: boolean;
  className?: string;
}

const RenderArt: React.FC<IProps> = ({ error, className}) => {
  return (
    <div className={classNames('render-art', className)}>
      {error ?
        <div className='render-art__error'>
          <Error />
        </div>
        :
        <>
          <div className='render-art__stand'>
            <Stand />
          </div>
          <div className='render-art__square'>
            <Square />
          </div>
          <Line className='render-art__line' />
        </>
      }

    </div>
  );
};

export default RenderArt;
