import classNames from 'classnames';
import {observer} from 'mobx-react';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import {ConfiguratorStore} from '../../stores/ConfiguratorStore';
import {KitchenOption} from '../../stores/ConfiguratorViews/EditOptionViewStore';
import Icon from '../Icon/Icon';
import SettingsItem from '../SettingsItem/SettingsItem';

import './SettingsList.scss';
import {layoutService} from "../../services/LayoutService";

interface ISettingListProps {
  className?: string;
  model: ConfiguratorStore['model'];
  onChange: (option: KitchenOption) => void;
}

@observer
export default class SettingsList extends React.Component<ISettingListProps> {
  handleChangeWorktop = () => {
    const { onChange } = this.props;
    onChange(KitchenOption.WORKTOP);
  };

  handleChangeSkinal = () => {
    const { onChange } = this.props;
    onChange(KitchenOption.SKINAL);
  };

  handleChangeUpstandMaterial = () => {
    const {onChange} = this.props;
    onChange(KitchenOption.UPSTAND);
  }

  render() {
    const { className, model } = this.props;

    const worktop = model.worktop[0];
    const worktopValue = {name: worktop.name, image: worktop.itemImage};
    const skinalValue = model.skinal ? {name: model.skinal.name, image: model.skinal.itemImage}: false;
    const upstandValue = layoutService.getUpstandMaterial(model);


    return (
      <div className={classNames('settings', className)}>
        <h2 className="settings__heading">
          <Icon type="settings"/><FormattedMessage id="ui.settings.header"/>
        </h2>
        <ul className="settings__list">
          {worktopValue && <SettingsItem property="worktop" value={worktopValue} onChange={this.handleChangeWorktop}/>}
          {skinalValue && <SettingsItem property="skinal" value={skinalValue} onChange={this.handleChangeSkinal}/>}
          {upstandValue && <SettingsItem property="upstand" value={upstandValue} onChange={this.handleChangeUpstandMaterial}/>}
        </ul>
      </div>
    );
  }
}
