import * as React from 'react';

import { ReactComponent as Logo } from './images/logo.svg';

import './NobiliaLogo.scss';

interface IProps {
  className?: string;
}

const NobiliaLogo: React.FC<IProps> = ({ className }) => {
  return (
    <a href='https://www.nobilia-elements.de/' target='_blank' className={className}>
      <Logo className='nobilia-logo__image'/>
    </a>
  );
};

export default NobiliaLogo;
