import * as React from 'react';
import classNames from 'classnames';

import './PillList.scss';

interface IProps {
  className?: string;
}

class PillList extends React.Component<IProps> {
  render() {
    const { className, children } = this.props;

    return (
      <ul className={classNames('pill-list', className)}>
        {children}
      </ul>
    );
  }
}

export default PillList;
