import * as React from 'react';
import { ReactComponent as download } from './images/download.svg';
import { ReactComponent as facebook } from './images/fb.svg';
import { ReactComponent as linkedin } from './images/in.svg';
import { ReactComponent as instagram } from './images/inst.svg';
import { ReactComponent as telegram } from './images/tel.svg';
import { ReactComponent as vk } from './images/vk.svg';
import { ReactComponent as link } from './images/link.svg';

const icons = {
  download,
  facebook,
  linkedin,
  instagram,
  telegram,
  vk,
  link,
};

type SocialIconName = keyof typeof icons;

interface IProps {
  type: SocialIconName;
}

class ShareIcon extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { type } = this.props;
    const SVGIcon = icons[type];

    return (
      <SVGIcon />
    );
  }
}

export default ShareIcon;
