import * as React from 'react';
import classNames from 'classnames';
import QRCodeGenerator from 'qrcode';

import './QRCode.scss';

interface IProps {
  data?: string;
  className?: string;
}

const QRCode: React.FC<IProps> = ({ data, className }) => {
  const [codeURL, setCodeURL] = React.useState<string>('');

  React.useEffect(() => {
    if (data) {
      QRCodeGenerator.toDataURL(data).then(setCodeURL);
    } else {
      setCodeURL('');
    }
  }, [data, setCodeURL]);

  return (
    <img
      className={classNames('qr-code', className)}
      alt='QR-code'
      src={codeURL}
    />
  );
};

export default QRCode;
