import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { OpeningSide, ReportCalculation } from '../../schema';
import { IReportItem } from '../../model/ReportItem';
import Dimensions from '../Dimensions/Dimensions';
import Toggle from '../buttons/Toggle/Toggle';

import './ReportListCard.scss';

interface IProps {
  item: IReportItem;
  onChange?: (variantId: string, excluded: boolean, amount: number) => void;
}

class ReportListCard extends React.Component<IProps> {
  render() {
    const { item } = this.props;
    const showPrices = process.env.REACT_APP_CL__FEATURE__PRICING === 'ON';
    const hasToggle = item.reportCalculation !== ReportCalculation.fixed;
    const excluded = item.reportCalculation === ReportCalculation.deselected;
    return (
      <li className={classNames('cart-table__card', {
        'cart-table__card_excluded': excluded,
        'cart-table__card--print-invisible': !item.amount
      })}>
        <div className="cart-table__tdCell cart-table__tdCell_info">
          <div className="cart-table__img-wrapper">
            <img className="cart-table__img" src={item.item.itemImage} alt="Item image"/>
          </div>
          <h3 className="cart-table__title">
            <FormattedMessage id={item.item.name}/>
          </h3>
          <div className="cart-table__data">
            <p className="cart-table__vendor-code">
              <FormattedMessage id="ui.elements.articleNumber"/>: {item.item.articleNumber}
            </p>
            <p className="cart-table__vendor-code">
              <FormattedMessage id="ui.elements.typeNumber"/>: {item.item.typeNo}
            </p>
            <p className="cart-table__vendor-code">
              <FormattedMessage id="ui.elements.ean"/>: {item.calculatedEAN}
            </p>
            <Dimensions
              width={item.item.width}
              depth={item.item.depth}
              height={item.item.height}
            />
          </div>
          <p className="cart-table__description">
            {item.openingSide && item.openingSide !== OpeningSide.NONE &&
            <>
              <FormattedMessage id="ui.elements.open_side.label"/>:&nbsp;
              {item.openingSide === OpeningSide.L
                ? <FormattedMessage id="ui.elements.open_side.l"/>
                : <FormattedMessage id="ui.elements.open_side.r"/>
              }
              <br/>
            </>
            }
            <FormattedMessage id={item.item.description}/>
          </p>
        </div>

        <div className="cart-table__tdCell cart-table__tdCell_quantity">
          <div className="cart-table__thCell cart-table__subheader">
            <FormattedMessage id="ui.quantity"/>
          </div>
          {(item.item.changeQuantity && item.item.changeQuantity === 'yes')
            ? <input type="number" className="input__input" value={item.amount} onChange={this.changeAmount} min={0} disabled={excluded}/>
            : <span className="cart-table__tdCell__value">{item.amount}</span>
          }
        </div>
        {showPrices &&
        <>
          <div className="cart-table__tdCell cart-table__tdCell_price">
            <div className="cart-table__thCell cart-table__subheader">
              <FormattedMessage id="ui.report.price"/>
            </div>
            <span className="cart-table__tdCell__value">{item.amount === 1 ? this.subTotalPrice : item.item.price.toFixed(2)} €</span>
          </div>
          <div className="cart-table__tdCell cart-table__tdCell_subtotal">
            <div className="cart-table__thCell cart-table__subheader">
              <FormattedMessage id="ui.report.subtotal"/>
            </div>
            <span className="cart-table__tdCell__value">{this.subTotalPrice} €</span>
          </div>
        </>
        }
        {hasToggle && <div className="cart-table__toggle-wrapper">
          <Toggle checked={!excluded} onChange={this.toggleExcluded}/>
          <p className="cart-table__toggle-text">
            <FormattedMessage id={excluded ? 'ui.report.excluded' : 'ui.report.included'}/>
          </p>
        </div>}
      </li>
    );
  }

  get subTotalPrice() {
    const { item } = this.props;
    return (item.item.price * item.amount + item.item.basePrice).toFixed(2)
  }

  toggleExcluded = () => {
    const { item, onChange } = this.props;
    if (onChange) {
      const excluded = item.reportCalculation === ReportCalculation.deselected;

      onChange(item.calculatedVariantId, !excluded, item.amount);
    }
  };

  changeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    const { item, onChange } = this.props;
    if (onChange) {
      const value = Number.parseInt(event.target.value, 10);
      const excluded = item.reportCalculation === ReportCalculation.deselected;

      onChange(item.calculatedVariantId, excluded, value || 0);
    }
  }
}

export default ReportListCard;
