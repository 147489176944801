import * as React from 'react';
import { Component } from 'react';
import classNames from 'classnames';

import IconButton from '../../../../components/buttons/IconButton/IconButton';
import ARCode from '../../../../components/ARCode/ARCode';
import { CancellablePromiseError, makeCancelable } from '../../../../helpers/utils';

import './ARButton.scss';

interface IProps {
  getLink: () => Promise<string>;
}

interface IState {
  popupVisible: boolean;
  arLink: string;
}

class ARButton extends Component<IProps, IState> {
  state = {
    popupVisible: false,
    arLink: ''
  };

  popupRef = React.createRef<HTMLDivElement>();
  latestARLinkPromise: ReturnType<typeof makeCancelable>;

  render() {
    if (process.env.REACT_APP_CL__FEATURE_AR === 'ON') {
      const { arLink, popupVisible } = this.state;
      return (
        <div className='ar-button__wrapper' onBlur={this.handleBlur}>
          <div
            className={classNames('ar-button__popup', { 'ar-button__popup--visible': popupVisible })}
            ref={this.popupRef}
            tabIndex={0}
          >
            <ARCode arLink={arLink}/>
          </div>
          <IconButton
            icon="ar"
            altIcon="close"
            showAltIcon={popupVisible}
            onClick={this.handleClick}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  handleBlur = (event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      this.closePopup();
    }
  };

  closePopup = () => {
    this.latestARLinkPromise?.cancel();
    this.setState({
      popupVisible: false
    })
  };

  handleClick = async () => {
    const { popupVisible } = this.state;
    const { getLink } = this.props;
    if (popupVisible) {
      this.closePopup();
    } else {
      const { current: popup } = this.popupRef;

      if (popup) {
        popup.addEventListener('animationend', this.handlePopupTransitionEnd);
      }
      this.setState({
        arLink: '',
        popupVisible: true
      });
      const qrPromise = makeCancelable(getLink());
      qrPromise.promise
        .then((link) => {
          console.log(link);
          this.setState({
            arLink: link
          })
        })
        // Cancelled promise will throw an error when completed.
        // Catch it here silently, otherwise throw real error
        .catch((error) => {
          if (!(error instanceof CancellablePromiseError)) {
            throw error;
          }
        });
      this.latestARLinkPromise?.cancel();
      this.latestARLinkPromise = qrPromise;
    }
  }
  handlePopupTransitionEnd = () => {
    const { current: popup } = this.popupRef;
    if (popup) {
      popup.removeEventListener('animationend', this.handlePopupTransitionEnd);
      popup.focus();
    }
  }
}

export default ARButton;
