import { v4 } from 'uuid';

import { toTimeZonedISODate } from '../../../helpers/utils';
import { DeviceType } from '../../../stores/ConfiguratorStore';
import { IActionRecord } from '../trackers/ActionTracker';

const CLIENT_ID_KEY = 'CLIENT_ID';

/**
 * Handles communication between tracking server.
 *
 * For `recordActions` and `endSession` events we use `navigator.sendBeacon` to ensure it is functional during unload phase.
 */
export class BIRestService {
  /**
   * Session ID assigned by tracking service
   */
  private sessionId = null;
  /**
   * Session-independent user identifier
   */
  private readonly clientID: string;

  constructor(private readonly serverAddress = process.env.REACT_APP_CL__BI_SERVICE_URL) {
    let storedID = localStorage.getItem(CLIENT_ID_KEY);
    if (storedID === null) {
      storedID = v4();
      localStorage.setItem(CLIENT_ID_KEY, storedID);
    }

    this.clientID = storedID;
  }

  /**
   * @todo store initial request as promise to delay `recordActions` request until it is resolved or rejected.
   */
  async startSession(deviceType: DeviceType) {
    const urlToFetch = `${this.serverAddress}`;
    const payload = {
      ...this.getSessionData(),
      deviceType,
      start: toTimeZonedISODate(new Date()),
    };

    try {
      const result = await fetch(urlToFetch, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
      });

      const { id } = await result.json();
      this.sessionId = id;
    } catch (e) {
      console.error('Unable to start tracking session');
      console.error(e);
    }
  }

  async recordActions(actions: IActionRecord[]) {
    if (this.sessionId === null) {
      console.error('Attempt to update session without ID');
      return;
    }

    try {
      if (!navigator.sendBeacon) {
        return;
      }

      const urlToFetch = `${this.serverAddress}/${this.sessionId}`;
      navigator.sendBeacon(urlToFetch, JSON.stringify(actions));
    } catch (e) {
      console.error(e);
    }
  }

  async endSession() {
    if (this.sessionId === null) {
      console.error('Attempt to end session without ID');
      return;
    }

    try {
      if (!navigator.sendBeacon) {
        return;
      }

      const urlToFetch = `${this.serverAddress}/${this.sessionId}/end`;
      const payload = {
        localtime: toTimeZonedISODate(new Date()),
      };

      navigator.sendBeacon(urlToFetch, JSON.stringify(payload));
    } catch (e) {
      console.error(e);
    }
  }

  private getSessionData() {
    const { devicePixelRatio, screen: { availWidth, availHeight } } = window;

    const parentHref = (window.location != window.parent.location)
      ? document.referrer
      : document.location.href;

    const hostURL = new URL(parentHref).hostname;

    return {
      clientID: this.clientID,
      userAgent: navigator.userAgent,
      screenWidth: availWidth * devicePixelRatio,
      screenHeight: availHeight * devicePixelRatio,
      referer: document.referrer,
      host: hostURL,
    }
  }
}

export const biRestService = new BIRestService();
