import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Button from '../../../../components/buttons/Button/Button';
import Icon from '../../../../components/Icon/Icon';

import './CartLink.scss';

interface ICartLinkProps {
  price: number;
  beforeOpenReport(): void;
}

const CartLink: React.FC<ICartLinkProps> = ({ price, beforeOpenReport }) => {
  const history = useHistory();
  const showPrices = process.env.REACT_APP_CL__FEATURE__PRICING === 'ON';

  return (
    <div className="cart-link" tabIndex={0}>
      <Icon type="cart" className="cart-link__icon"/>
      {showPrices && <span className="cart-link__total">{price.toFixed(2)} €</span>}
      <Button
        className="cart-link__button"
        disabled={price === 0}
        onClick={async () => {
          await beforeOpenReport();
          history.push('/report');
        }}
      >
        <FormattedMessage id="ui.checkout"/>
        <Icon className="cart-link__button-icon" type="rightArrowSmall" />
      </Button>
    </div>
  );
};

export default CartLink;
