import * as THREE from 'three';
import { CSG } from '@hi-level/three-csg';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';

import TemplateEngine from '../model/TemplateEngine';
import { SelectElementViewStore } from '../stores/ConfiguratorViews/SelectElementViewStore';
import { GltfLoaderService } from './GltfLoaderService';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ActiveCamera } from '../model/ConfigurationState';
import { Converter } from '../helpers/Converter';
import { BoundaryPosition, hoodUnitGuard, InsertUnit, supportLegGuard, UIId, UnitInsertPosition, upstandGuard } from '../model/Model';
import { computed, reaction } from 'mobx';
import { layoutService } from './LayoutService';
import { ConfiguratorStore, isElementUnit, SelectedElementType } from '../stores/ConfiguratorStore';
import { Option } from '@canvas-logic/engine';
import { IMountedUnit, MountedUnitList, Unit, UnitKind } from '../schema';
import { Transition } from '../model/Transition';
import { ZoomService } from '../features/Zoom/ZoomService';

interface INodeClickEvent {
  stopPropagation(): void;
}

class NodeClickEvent implements INodeClickEvent {
  stopped = false;

  stopPropagation() {
    this.stopped = true;
  }
}

enum HorizontalAlign {
  left,
  right
}

enum Nodes {
  Skinal           = 'skinal',
  Unit             = 'unit',
  Button           = 'button',
  NaN              = '', // not a Node
  SkinalZoomButton = 'skinal_zoom_button',
  UnitZoomButton   = 'unit_zoom_button',
  ShiftLeftButton  = 'shift_left_button',
  ShiftRightButton = 'shift_right_button'
}

type Predicate = (node: THREE.Object3D) => boolean;
export type NodeClickEventHandler = (node: THREE.Object3D | null, event: INodeClickEvent) => void;

interface NodeClickListener {
  nodeName: string;
  handler: NodeClickEventHandler;
}

const DEBUG = false;
const SELECTION_BOX_COLOR = 0x29B1B1;
const WHITE_COLOR = '#FFFFFF';
const DIMENSIONS_COLOR = 'rgb(255,255,255)';
const DIMENSIONS_FONT_SIZE = '24px';
// @todo: why is MAX_HEIGHT < MIN_HEIGHT???
const MAX_KITCHEN_HEIGHT = 2.147;
const MIN_KITCHEN_HEIGHT = 2.383;
const BUTTON_DEPTH = 0.01;
const BUTTON_COLOR = '#15eaba';
const BUTTON_WIDTH = 0.085;
const BUTTON_WIDTH_MM = 85;
const BUTTON_SCALE = 2;
const PLINTH_HEIGHT = 0.15;
const WORKTOP_HEIGHT = 0.038;
const WORKTOP_DEPTH_600 = 0.6;
const BOTTOM_HEIGHT = 0.908;
const BOTTOM_BUTTON_Y = -0.61;
const MID_BUTTON_Y = 0.0035;
const TOP_BUTTON_Y = 0.716;
const TOP_CABINET_DEPTH = 350;
const PERSPECTIVE_BASE = new THREE.Vector3(1.7858, 0.6571, 5.9343);

interface IUnitUserData {
  position: number;
  data: {
    unitKind: UnitKind
    uiId: UIId;
  }
}

export default class KitchenViewer {
  private scene: THREE.Scene;
  private dimensionsScene: THREE.Scene;
  private transition: Transition;
  private zoomService: ZoomService;

  public get sceneToExport() {
    return this.rootNode;
  }

  private sceneCamera: THREE.PerspectiveCamera;
  private showRuler = false;
  private font: THREE.Font;
  private readonly ROOM_HEIGHT = 3.1944;
  private readonly ROOM_WIDTH = 12;
  private extendButton: THREE.Mesh;
  private addButton: THREE.Mesh;
  private arrowButton: THREE.Mesh;
  private zoomInButton: THREE.Mesh;
  private readonly emptySceneCameraPosition = new THREE.Vector3(0, 0, 4);
  private frontPosition = this.emptySceneCameraPosition;
  private perspectivePosition = new THREE.Vector3(0, 0, 0);
  niche: THREE.Mesh;
  private width: number;
  private height: number;

  private get camera() {
    return this.sceneCamera;
  }

  private renderer: THREE.WebGLRenderer;
  private dimensionsRenderer2D: CSS2DRenderer;
  private controls: TrackballControls;
  private loader = new GLTFLoader();
  private rootNode: THREE.Object3D;
  private sceneNode: THREE.Object3D;
  private interiorNodeLeft: THREE.Object3D;
  private interiorNodeRight: THREE.Object3D;
  private rulerNode: THREE.Object3D;
  private templateEngine: TemplateEngine;
  private raycaster: THREE.Raycaster;
  private mouse: THREE.Vector2;
  private selectionBox: Line2;
  private nodeClickListeners: NodeClickListener[] = [];
  private buttons: THREE.Object3D;
  private disposers: any[] = [];
  private placeholderMaterial: THREE.Material;

  constructor(private store: ConfiguratorStore, private element: HTMLCanvasElement) {
    this.width = element.offsetWidth;
    this.height = element.offsetHeight;
    this.zoomService = new ZoomService(this.loader, this);
  }

  async init() {
    const { store } = this;

    await store.init();
    await this.loadInternalModels();

    this.disposers[this.disposers.length] = reaction(() => [
        store.selectedElementType,
        store.selectedElement,
        store.pendingForScreenshot,
        store.elementToInsert,
        store.model.units.length,
        store.showRuler
      ],
      async () => {
        this.showRuler = store.showRuler;
        await this.rebuildScene(); // Scene needs to be rebuild, because in case we replace one unit with another

        /**
         * We have to wait for one render tick to redraw selection box properly.
         * @todo investigate this a little deeper
         */
        this.renderTick();
        switch (store.selectedElementType) {
          case SelectedElementType.NONE:
            this.hideSelectionBox();
            break;
          case SelectedElementType.Unit:
            if (isElementUnit(store.selectedElement)) {
              this.highlightSelectedUnit(store.selectedElement.uiId);
            }
            break;
          case SelectedElementType.Skinal:
            this.highlightSkinal();
            break;
        }

        if (store.elementToInsert) {
          this.renderTick(); // to update buttons, it needs to be rerendered
          this.renderAvailablePositions(store.elementToInsert);
        }
        this.resizeHandler();
        if (store.pendingForScreenshot) {
          this.resetViewport();

          store.updateCanvasUrl(this.element.toDataURL('image/png'), this.element.width, this.element.height);
          store.pendingForScreenshot = false;
        }
      }
    );

    window.addEventListener('resize', this.resizeHandler);
    await this.initViewPort();
    await this.rebuildScene();
    if (DEBUG) {
      this.renderLoop();
    }

    this.transition = new Transition([this.camera.position.x, this.camera.position.y, this.camera.position.z, 0, 0, 0], 700);
    this.transition.onStart(() => {
      if (store.showRuler) {
        this.showRuler = false;
        this.buildRuler();
        this.renderTick();
      }
    });
    this.transition.onFinish(() => {
      if (store.showRuler) {
        this.showRuler = true;
        this.buildRuler();
        this.renderTick();
      }
    });
    this.transition.onChange(([x, y, z, lookX, lookY, lookZ]) => {
      this.camera.position.x = x;
      this.camera.position.y = y;
      this.camera.position.z = z;
      this.camera.lookAt(lookX, lookY, lookZ);
      this.renderTick();
    });
    // Zoom handler
    // this.disposers[this.disposers.length] = reaction(() => store.selectedElement, (old) => {
    //   if (store.selectedElement) {
    //     if (isElementUnit(this.store.selectedElement)) {
    //       const id = this.store.selectedElement.uiId;
    //       if (this.store.isUnitPlaceholder(id)) {
    //         this.store.zoomOut();
    //       } else {
    //         this.zoomToUnit(id);
    //       }
    //     } else {
    //       this.zoomToSkinal();
    //     }
    //   }
    // });

    // @todo: consider analysis
    this.disposers[this.disposers.length] = reaction(() =>
        [store.zoomed, store.selectedElementType, store.selectedElement],
      () => {
        if (isElementUnit(this.store.selectedElement) && store.zoomed) {
          const id = this.store.selectedElement.uiId;
          this.zoomToUnit(id);
        } else if (!this.store.selectedElement) {
          this.store.zoomed = false;
        }
      });
    this.disposers[this.disposers.length] = reaction(() => [store.cameraView], () => {
      this.store.zoomOut();
      if (isElementUnit(this.store.selectedElement)) {
        const id = this.store.selectedElement.uiId;
        this.highlightSelectedUnit(id);
      }
      this.transitionCameraToViewPosition();
    });
    // this.animationLoop();
    setTimeout(() => {
      this.resizeHandler()
    })
  }

  dispose() {
    window.removeEventListener('resize', this.resizeHandler);
    this.disposeReactions();
  }

  async rebuildScene() {
    console.log('Rebuild Scene');
    if (!this.templateEngine) {
      return;
    }
    const { model, template, viewModel } = this.store;
    let nodes = await this.templateEngine.build(template, viewModel);
    this.rootNode.remove(...this.rootNode.children);
    this.interiorNodeLeft.remove(...this.interiorNodeLeft.children);
    this.interiorNodeRight.remove(...this.interiorNodeRight.children);
    this.buttons.remove(...this.buttons.children);

    const boundaries: THREE.Mesh[] = [];
    let worktop: THREE.Mesh = new THREE.Mesh();
    this.niche = new THREE.Mesh();
    const self = this;
    for (let node of nodes) {
      const nodeWidth = Converter.mmToMeters(node.userData?.data?.width);
      const isOpensToRight = node.userData?.data?.openingSide === 'R';
      node.traverse(function (sub: THREE.Object3D) {
        if (sub instanceof THREE.Mesh) {
          if (sub.name.startsWith('boundary')) {
            node.updateMatrixWorld();

            const vector = new THREE.Vector3();
            vector.setFromMatrixPosition(sub.matrixWorld);

            const clone = sub.clone();
            clone.position.setX(
              sub.parent?.parent?.scale.x === -1 ?
                vector.x - nodeWidth
                : vector.x);
            clone.position.setZ(sub.parent?.position.z ? sub.parent?.position.z : 0);
            clone.updateMatrix();
            boundaries.push(clone);
            clone.visible = true;
          }

          if (sub.name.startsWith(('worktop'))) {
            worktop = sub;
          }
          if (sub.name.startsWith('niche')) {
            self.niche = sub;
          }
          if (isOpensToRight && sub.name.startsWith('handle')) {
            node.updateMatrixWorld();
            node.children[0].scale.x = 1;
            sub.position.set(nodeWidth - sub.position.x, sub.position.y, sub.position.z);
          }
        }
      });
    }

    const worktopMat = this.cloneMaterial(worktop.material);

    const interiorNodes = nodes.filter(node => node.name === 'left_window' || node.name === 'right_door');
    interiorNodes.forEach(node => node.name.startsWith('left')
      ? this.interiorNodeLeft.add(node)
      : this.interiorNodeRight.add(node));

    nodes = nodes.filter(node => !interiorNodes.includes(node));
    this.rootNode.add(...nodes);

    this.updateCameras();
    const { center, size } = this.getKitchenBoundingBoxParameters(false);
    const floorUnitsWidth = Converter.mmToMeters(layoutService.floorUnitsWidth(model));
    const bottomUnitsWidth = Converter.mmToMeters(layoutService.bottomUnitsWidth(model));

    const worktopSize = this.getMeshSize(worktop);
    const nicheSize = this.getMeshSize(this.niche);

    worktop.scale.setX((floorUnitsWidth / worktopSize.x));
    worktop.updateMatrix();

    this.niche.scale.setX((bottomUnitsWidth / nicheSize.x));
    this.niche.updateMatrix();

    const firstUnit = layoutService.firstFloorUnit(model);
    if (firstUnit?.unit.unitKind === UnitKind.tall) {
      this.niche.position.setX(Converter.mmToMeters(layoutService.leftTallUnitsWidth(model)));
    }

    const updateWorktop = () => {
      const oldWorktop = worktop;
      if (boundaries.length > 0) {
        let meshToSubtract = boundaries
          .map(CSG.fromMesh)
          .reduce((acc, next) => acc.union(next));
        worktop.updateMatrix();
        worktop = CSG.toMesh(CSG.fromMesh(worktop).subtract(meshToSubtract), worktop.matrix);
        worktop.geometry = new THREE.BufferGeometry().fromGeometry(<THREE.Geometry>worktop.geometry);
        (<THREE.BufferGeometry>worktop.geometry).deleteAttribute('color');
        worktop.name = oldWorktop.name;
        (worktopMat as any)?.map?.repeat?.setX((floorUnitsWidth / worktopSize.x));
        worktop.material = worktopMat;
        oldWorktop.visible = false;
        worktop.castShadow = true;
      }
      if (bottomUnitsWidth > 0) {
        oldWorktop.parent!.add(worktop)
      } else {
        worktop.visible = false;
        this.rootNode.children = nodes.filter(node => node.name != 'worktop');
      }
    }

    updateWorktop();

    if (bottomUnitsWidth === 0) {
      this.rootNode.children = nodes.filter(node => node.name != 'skinal');
    }

    if ((this.niche.material as any)?.map?.rotation === 0) {
      (this.niche.material as any)?.map?.repeat?.setX((bottomUnitsWidth / nicheSize.x));
    } else {
      (this.niche.material as any)?.map?.repeat?.setY((bottomUnitsWidth / nicheSize.x));
    }

    this.alignKitchen(center, size);
    this.buildRuler();

    this.findUnitsByUserData(data =>
      this.store.isUnitPlaceholder(data.data.uiId)
    ).forEach(p => p.visible = false);

    if (DEBUG) {
      const helper = new THREE.BoxHelper(this.rootNode);
      this.scene.add(helper);
    }
  }

  private buildRuler() {
    this.dimensionsScene.remove(...this.dimensionsScene.children);
    this.rulerNode.remove(...this.rulerNode.children);

    const bbox = new THREE.Box3();
    bbox.setFromObject(this.sceneNode);
    const size = new THREE.Vector3();
    bbox.getSize(size);

    if (this.showRuler && size.x > 0) {
      const center = new THREE.Vector3();
      bbox.getCenter(center);

      const sizeY = parseFloat(size.y.toFixed(3));
      const widthValue = Converter.metresToMm2Digit(size.x);
      const heightValue = Converter.metresToMm(size.y >= MAX_KITCHEN_HEIGHT ? MAX_KITCHEN_HEIGHT : ((size.y > 1.5) ? size.y : BOTTOM_HEIGHT));
      const yOffset = sizeY >= (MAX_KITCHEN_HEIGHT) ? sizeY - MAX_KITCHEN_HEIGHT : ((sizeY > 1.5) ? 0 : sizeY - BOTTOM_HEIGHT);

      this.add2DLabel(new THREE.Vector3(bbox.min.x - 0.05, center.y, bbox.max.z), heightValue + ' mm', -90);
      this.add2DLabel(new THREE.Vector3(center.x, bbox.min.y - 0.05, bbox.max.z), widthValue + ' mm');

      this.add3DLine([bbox.min.x, bbox.min.y, bbox.max.z], [bbox.min.x, bbox.max.y - yOffset, bbox.max.z], 0.0015);
      this.add3DLine([bbox.min.x, bbox.min.y, bbox.max.z], [bbox.min.x, bbox.min.y, bbox.min.z], 0.002, 0.2);
      this.add3DLine([bbox.min.x, bbox.max.y - yOffset, bbox.max.z], [bbox.min.x, bbox.max.y - yOffset, bbox.min.z], 0.002, 0.2);
      this.add3DLine([bbox.min.x, bbox.min.y, bbox.max.z], [bbox.max.x, bbox.min.y, bbox.max.z], 0.002);
      this.add3DLine([bbox.max.x, bbox.min.y, bbox.max.z], [bbox.max.x, bbox.min.y, bbox.min.z], 0.002,0.2);
    }
  }

  private add3DLine(from: number[], to: number[], width: number, opacity = 0.65) {
    const geometry = new LineGeometry().setPositions([...from, ...to]);
    const material = new LineMaterial({color: 0xffffff, linewidth: width, transparent: true});
    material.uniforms.opacity.value = opacity;
    const line = new Line2(geometry, material);
    this.rulerNode.add(line);
  }

  private add2DLabel(position: THREE.Vector3, content: string, rotation = 0) {
    const text = document.createElement('div');
    text.textContent = content;
    text.style.fontSize = DIMENSIONS_FONT_SIZE;
    text.style.color = DIMENSIONS_COLOR;
    text.style.transform = `rotate(${rotation}deg)`;
    const wrap = document.createElement('div');
    wrap.appendChild(text);
    const label = new CSS2DObject(wrap);
    label.position.copy(position);
    this.dimensionsScene.add(label);
  }

  @computed
  get currentCamera() {
    return this.store.cameraView;
  }

  resetViewport() {
    this.store.zoomed = false;
    this.store.cameraView = ActiveCamera.Front;
    this.updateCameras(true);
  }

  updateCameras(instant: boolean = false) {
    this.updateCameraPositions();

    if (this.transition) {
      this.transitionCameraToViewPosition(instant);
    }
  }

  cloneMaterial(material: THREE.Material | THREE.Material[]) {
    const clonedMaterial = (material as any).clone();
    if ((material as any).map) {
      (clonedMaterial as any).map = (material as any).map.clone();
      (clonedMaterial as any).map.needsUpdate = true;
    }
    return clonedMaterial;
  }

  getMeshSize(mesh: THREE.Mesh | THREE.ShapeBufferGeometry) {
    let meshBox: THREE.Box3;
    if (mesh instanceof THREE.ShapeBufferGeometry) {
      meshBox = new THREE.Box3().setFromObject(new THREE.Mesh(mesh));
    } else {
      meshBox = new THREE.Box3().setFromObject(mesh);
    }
    const meshSize = new THREE.Vector3();
    meshBox.getSize(meshSize);
    return meshSize;
  }

  private async initViewPort() {
    this.initScene();
    this.initCamera();
    await this.initRenderer();
    this.initControls();
    this.initLights();
    this.initHandler();
  }

  private initScene() {
    this.scene = new THREE.Scene();
    this.sceneNode = new THREE.Object3D();
    this.sceneNode.name = 'Scene';
    this.scene.add(this.sceneNode);
    // this.scene.position.y = 0.5;
    this.scene.name = 'Kitchen Room';
    this.interiorNodeLeft = new THREE.Object3D();
    this.interiorNodeLeft.name = 'Interior_Left';
    this.scene.add(this.interiorNodeLeft);
    this.interiorNodeRight = new THREE.Object3D();
    this.interiorNodeRight.name = 'Interior_Right';
    this.scene.add(this.interiorNodeRight);
    this.rulerNode = new THREE.Object3D();
    this.rulerNode.name = 'Ruler';
    this.scene.add(this.rulerNode);
    (window as any).scene = this.scene; // for chrome extension
    (window as any).THREE = THREE; // export for chrome extension
    this.scene.background = new THREE.Color(0xb5b4b2);
    this.rootNode = new THREE.Object3D();
    this.rootNode.name = 'Root';
    this.sceneNode.add(this.rootNode);
    this.buttons = new THREE.Object3D();
    this.buttons.name = 'buttons';
    this.sceneNode.add(this.buttons);

    this.dimensionsScene = new THREE.Scene();

    this.placeholderMaterial = new THREE.MeshBasicMaterial({ color: BUTTON_COLOR, opacity: 0.8 });
    this.placeholderMaterial.transparent = true;
    if (DEBUG) {
      const axesHelper = new THREE.AxesHelper(1);
      this.scene.add(axesHelper);
    }
  }

  private initCamera() {
    const { width, height } = this;
    this.sceneCamera = new THREE.PerspectiveCamera(30, width / height, 1, 100);
    this.sceneCamera.position.z = 6;
  }

  private initRenderer() {
    const { width, height, element } = this;

    this.dimensionsRenderer2D = new CSS2DRenderer();
    this.dimensionsRenderer2D.setSize(width, height);
    this.dimensionsRenderer2D.domElement.style.position = 'absolute';
    this.dimensionsRenderer2D.domElement.style.top = '0px';
    this.dimensionsRenderer2D.domElement.style.pointerEvents = 'none';
    document.getElementsByClassName('model-viewer')[0].appendChild(this.dimensionsRenderer2D.domElement);

    this.renderer = new THREE.WebGLRenderer({ canvas: element, antialias: true, preserveDrawingBuffer: true });
    this.templateEngine = new TemplateEngine({ loader: new GltfLoaderService() },
      'assets/models/materials.gltf',
      this.renderer.capabilities.getMaxAnisotropy());
    this.renderer.domElement = element;

    this.renderer.gammaFactor = 2.2;
    this.renderer.outputEncoding = THREE.GammaEncoding;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    const pMREMGenerator = new THREE.PMREMGenerator(this.renderer);
    pMREMGenerator.compileEquirectangularShader();

    return new Promise((resolve, reject) => {
      new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .setPath('assets/models/environment/')
        .load('studio008small.hdr', texture => {
          const envMap = pMREMGenerator.fromEquirectangular(texture).texture;
          // this.scene.background = envMap;
          this.scene.environment = envMap;

          texture.dispose();
          pMREMGenerator.dispose();

          resolve();
        }, () => {
        }, reject);
    });
  }

  private initControls() {
    this.controls = new TrackballControls(this.camera, this.renderer.domElement);
    this.controls.rotateSpeed = 4;
    this.controls.zoomSpeed = 1.2;
    this.controls.panSpeed = 0.8;
  };

  private initLights() {
    (window as any).lights = [];
    this.addDirectionalLight();
    this.addHemiLight();
    this.addAmbientLight();
  }

  private renderLoop() {
    requestAnimationFrame(() => this.renderLoop());
    this.renderTick();
  }

  private renderTick() {
    console.log('Render');
    this.renderer.render(this.scene, this.camera);
    this.dimensionsRenderer2D.render(this.dimensionsScene, this.camera);
    if (DEBUG) {
      this.controls && this.controls.update();
    }
  }

  private initHandler() {
    this.raycaster = new THREE.Raycaster(); // create once
    this.mouse = new THREE.Vector2(); // create once
    this.selectionBox = new Line2();
    this.selectionBox.name = 'Selection Box';
    this.scene.add(this.selectionBox);
    this.element.addEventListener('touchstart', this.onTouch);
    this.element.addEventListener('click', this.onClick);
    this.disposers.push(() => {
      this.element.removeEventListener('touchstart', this.onTouch);
      this.element.removeEventListener('click', this.onClick);
    });
    this.addNodeClickEventHandler(Nodes.Button, (item, event) => {
      if (!item) {
        return;
      }
      const { store } = this;
      const position = item.userData.value;
      if (store.isInPlacementMode) {
        (store.currentView as SelectElementViewStore).insertSelectedUnitAt(position);
      }
      event.stopPropagation();
    });

    this.addNodeClickEventHandler(Nodes.ShiftLeftButton, (item, event) => {
      this.store.shiftSelectedToLeft();
      event.stopPropagation();
    });
    this.addNodeClickEventHandler(Nodes.ShiftRightButton, (item, event) => {
      this.store.shiftSelectedToRight();
      event.stopPropagation();
    });
    this.zoomService.addNodeClickListener(Nodes.UnitZoomButton, (item, event) => {
      this.store.zoomIn();
      event.stopPropagation();
    });
    this.zoomService.addNodeClickListener(Nodes.SkinalZoomButton, (item, event) => {
      this.store.zoomIn();
      event.stopPropagation();
      if (item) {
        this.zoomToSkinal();
      }
    });
    this.addNodeClickEventHandler(Nodes.Unit, (node, event) => {
      event.stopPropagation();
      this.store.toggleUnit(node!.userData.data.uiId);
      if (this.store.selectedElement == null) {
        this.store.zoomOut();
      } else if (node && this.store.zoomed) {
        const id = (node.userData as IUnitUserData).data.uiId;
        if (this.store.isUnitPlaceholder(id)) {
          this.store.zoomOut();
        } else {
          // this.zoomToUnit(id);
        }
      }
    });

    this.addNodeClickEventHandler(Nodes.Skinal, (item, event) => {
      event.stopPropagation();
      this.store.handleSkinalClick();
      if (this.store.selectedElement == null) {
        this.store.zoomOut();
      } else if (this.store.zoomed) {
        this.zoomToSkinal();
      }
    });

    this.addNodeClickEventHandler(Nodes.NaN, (node, event) => {
      this.store.handleEmptySpaceClick();
      event.stopPropagation();
    });
  }

  private onClick = (event: MouseEvent) => {
    const x = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
    const y = -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
    this.clickHandler({ x, y });
  };

  private clickHandler = ({ x, y }: { x: number, y: number }) => {
    /**
     * Making sure we reset our focus state when user touches scene
     */
    if (document?.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }

    try {
      this.mouse.x = x;
      this.mouse.y = y;

      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.sceneNode.children, true);
      const clickEvent = new NodeClickEvent();
      for (let { nodeName, handler } of this.nodeClickListeners) {
        if (nodeName === '') {
          handler(null, clickEvent);
        }
        let node: THREE.Object3D | null = null;
        for (let i = 0; i < intersects.length; i++) {
          let obj: THREE.Object3D | null = intersects[i].object;
          while (obj && obj.name !== nodeName) {
            obj = obj.parent;
          }
          if (obj && obj.name === nodeName) {
            node = obj;
            break;
          }
        }
        if (node) {
          handler(node, clickEvent);
          if (clickEvent.stopped) {
            return;
          }
        }
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  private onTouch = (event: TouchEvent) => {
    event.preventDefault();

    const rect = this.element.getBoundingClientRect();
    const offsetX = event.targetTouches[0].pageX - rect.left;
    const x = (offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
    const offsetY = event.targetTouches[0].pageY - rect.top;
    const y = -(offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
    this.clickHandler({ x, y });
  };

  addNodeClickEventHandler(nodeName: string, handler: NodeClickEventHandler) {
    this.nodeClickListeners.push({ nodeName, handler });
  }

  private findUnitsByUserData(criteria: (userData: IUnitUserData) => boolean): THREE.Object3D[] {
    return this.findNodes(node => {
      return node.name === Nodes.Unit && criteria(node.userData as IUnitUserData)
    });
  }

  private findNodes(criteria: Predicate): THREE.Object3D[] {
    const result: THREE.Object3D[] = [];
    this.scene.traverse(node => {
      if (criteria(node)) {
        result.push(node)
      }
    });
    return result;
  }

  /***
   * Renders buttons to append element between two units after node element. Vertical align: center of node element;
   * @param node - starting point element
   */
  private renderAfter(node: THREE.Object3D, nextUnitWidth: number, position: UnitInsertPosition, appendType: UnitKind, horizontal: HorizontalAlign = HorizontalAlign.right) {
    const button = this.makeExtendButton();
    const unit = node.userData.data;
    const unitKind = unit.unitKind;
    const width = Converter.mmToMeters(node.userData.data.width);

    button.name = Nodes.Button;
    button.userData = { value: position };
    this.buttons.add(button);
    const box = new THREE.Box3();
    box.setFromObject(node);
    let size: THREE.Vector3 = new THREE.Vector3();
    box.getSize(size);
    // size.setX(width);
    box.getCenter(button.position);

    if (unitKind === UnitKind.tall) {
      switch (appendType) {
        case UnitKind.tall:
          this.buttonXPos(nextUnitWidth, button, unit);
          break;
        case UnitKind.top:
          button.position.y = TOP_BUTTON_Y;
          break;
        case UnitKind.bottom:
          button.position.y = BOTTOM_BUTTON_Y;
          if (nextUnitWidth < BUTTON_WIDTH_MM) {
            button.position.x = button.position.x - BUTTON_WIDTH;
          }
          break;
      }
    }

    if (unitKind === UnitKind.top) {
      this.buttonXPos(nextUnitWidth, button, unit);
      button.position.y = TOP_BUTTON_Y;
    }

    if (unitKind === UnitKind.bottom) {
      switch (appendType) {
        case UnitKind.bottom:
          button.position.y = BOTTOM_BUTTON_Y;
          this.buttonXPos(nextUnitWidth, button, unit);
          break;
        case UnitKind.tall:
          button.position.y = MID_BUTTON_Y;
          break;
      }
    }

    // Workaround for Support Leg to properly display + button
    const isSupportLeg = unitKind === UnitKind.bottom && unit.groupByWidth === 'SL';
    const xShift = isSupportLeg ? (width - 0.06) : size.x / 2;
    if (horizontal === HorizontalAlign.right) {
      button.position.x += xShift;
    } else {
      button.position.x -= xShift;
    }
    button.position.z = box.max.z + BUTTON_DEPTH * BUTTON_SCALE;
  }

  private buttonXPos(nextUnitWidth: number, button: THREE.Mesh, unit: any) {
    if (nextUnitWidth < BUTTON_WIDTH_MM) {
      button.position.x = button.position.x - BUTTON_WIDTH;
    } else {
      if (unit.width < BUTTON_WIDTH_MM) {
        button.position.x = button.position.x + BUTTON_WIDTH;
      }
    }
  }

  private renderLeft(node: THREE.Object3D, unit: Unit, position: UnitInsertPosition) {
    this.renderBoundary(node, unit, position, 'left');
  }

  private renderRight(node: THREE.Object3D, unit: Unit, position: UnitInsertPosition) {
    const {userData} = node;
    let zOffset = 0;
    if (unit.width < BUTTON_WIDTH_MM) {
      const notBottomUnits = layoutService.notBottomUnits(this.store.model);
      const nearestTowardsExtendDirectionUnit = notBottomUnits.find(unit => unit.position === userData.position + 2);
      if (nearestTowardsExtendDirectionUnit?.unit.unitKind === UnitKind.tall) {
        const nearestUnitDepth = nearestTowardsExtendDirectionUnit?.unit.depth || 0;
        zOffset = (nearestUnitDepth > 0 ? Converter.mmToMeters(nearestUnitDepth - userData.data.depth) : 0) + this.worktopDepthOffset();
      }
    }
    this.renderBoundary(node, unit, position, 'right', zOffset);
  }

  private worktopDepthOffset() {
    const worktopDepth = layoutService.workTopUnit(this.store.model)?.depth || 0;
    return Converter.mmToMeters(worktopDepth) - WORKTOP_DEPTH_600;
  }

  private renderBoundary(node: THREE.Object3D, unit: Unit, position: UnitInsertPosition, boundary: BoundaryPosition, zOffset: number = 0) {
    const nodeBBox = new THREE.Box3();
    nodeBBox.setFromObject(node);
    if (DEBUG) {
      const bboxHelper = new THREE.BoxHelper(node);
      this.buttons.add(bboxHelper);
    }

    const placeholderSize = this.getUnitSize(unit);
    const placeholder = this.makePlaceholder(placeholderSize, upstandGuard(unit) || unit.unitKind === UnitKind.top, zOffset);

    if (unit.unitKind === UnitKind.top) {
      placeholder.position.y += KitchenViewer.getTopPlaceholderYOffset(unit);
    }

    let nodeSize: THREE.Vector3 = new THREE.Vector3();
    nodeBBox.getSize(nodeSize);
    const button = placeholder.clone();
    button.name = Nodes.Button;
    this.buttons.add(button);
    const center = new THREE.Vector3();
    nodeBBox.getCenter(center);
    if (boundary === 'left') {
      button.position.x = center.x - 0.5 * (nodeSize.x + placeholderSize.x) + 0.00001; // To fix visual bug
    } else {
      button.position.x = center.x + 0.5 * (nodeSize.x + placeholderSize.x) + 0.00001; // To fix visual bug
    }
    button.userData = { value: position }
  }

  private renderAvailablePositions(unit: Option<Unit>) {
    const unitType = unit.model.unitKind as any; // unitType of selected unit

    const units = this.findUnitsByUserData(userData => {
      const nodeType = userData.data.unitKind;
      if (unitType === 'top') {
        return nodeType === 'top';
      } else {
        return layoutService.isFloorUnit(nodeType);
      }
    });
    units.sort((a, b) => a.userData.position - b.userData.position);
    switch (unitType) {
      case UnitKind.top: {
        this.renderTopButtons(units, unit.model);
        break;
      }
      case UnitKind.bottom: {
        this.renderBottomButtons(units, unit.model);
        break;
      }
      case UnitKind.tall: {
        this.renderTallButtons(units, unit.model);
        break;
      }
    }
  }

  private getUnitSize(unit: Unit): THREE.Vector3 {
    const { width, height, depth } = unit;
    const size = new THREE.Vector3(
      Converter.mmToMeters(width),
      Converter.mmToMeters(height),
      Converter.mmToMeters(depth)
    );

    switch (unit.unitKind) {
      case UnitKind.top: {
        break;
      }
      case UnitKind.bottom: {
        if (upstandGuard(unit) || supportLegGuard(unit)) {
          size.setY(size.y + WORKTOP_HEIGHT);
        } else {
          size.setY(size.y + PLINTH_HEIGHT + WORKTOP_HEIGHT);
        }
        break;
      }
      case UnitKind.tall: {
        if (!upstandGuard(unit)) {
          size.setY(size.y + PLINTH_HEIGHT);
        }
        break;
      }
    }
    return size;
  }

  private updateCameraPositions() {
    const boundingBox = this.selectionBox?.visible
      ? this.getKitchenBoundingBoxParameters(false)
      : this.getKitchenBoundingBoxParameters(true);
    this.updatePerspectivePosition(boundingBox.bbox);
    this.updateFrontPosition(boundingBox.size.x, boundingBox.size.y, boundingBox.size.z);
  }

  private updateFrontPosition(boxWidth: number, boxHeight: number, boxDepth: number) {
    const height = Math.max(boxHeight, boxWidth / this.camera.aspect); // use the biggest dimension to fit whole kitchen and preserve aspect ration
    const tgA = Math.tan(this.sceneCamera.fov / 2 * THREE.MathUtils.DEG2RAD);
    const dh = boxDepth * tgA;
    const z = (this.camera.aspect < 1 ? 0.6 : 0.5) * height / tgA + dh;
    if (boxWidth > 0 && boxHeight > 0 && boxDepth > 0) {
      this.frontPosition = new THREE.Vector3(0, 0, z + boxDepth + 1);
    } else {
      this.frontPosition = this.emptySceneCameraPosition;
    }
  }

  private updatePerspectivePosition(boundingBox: THREE.Box3) {
    let sphere = new THREE.Sphere();
    boundingBox.getBoundingSphere(sphere);
    const r = sphere.radius * (this.camera.aspect < 1 ? 1.55 : 0.85);
    if (r > 0) {
      const h = r / Math.sin(this.sceneCamera.fov / 2 * THREE.MathUtils.DEG2RAD) + 0.5;
      const dir = new THREE.Vector3().subVectors(PERSPECTIVE_BASE, sphere.center);
      this.perspectivePosition = new THREE.Vector3().addVectors(sphere.center, dir.setLength(h));
    } else {
      this.perspectivePosition = this.emptySceneCameraPosition;
    }
  }

  private getKitchenBoundingBoxParameters(withButtons: boolean) {
    const bbox = new THREE.Box3();

    if (withButtons) {
      bbox.setFromObject(this.sceneNode);
    } else {
      bbox.setFromObject(this.rootNode);
    }
    const size = new THREE.Vector3();
    bbox.getSize(size);
    // handles case when hood is install. Reduce height of the kitchen to use as much as possible free space
    if (size.y > MAX_KITCHEN_HEIGHT) {
      bbox.max.add(new THREE.Vector3(0, MAX_KITCHEN_HEIGHT - size.y, 0));
      bbox.getSize(size);
    } else {
      if (size.y < MIN_KITCHEN_HEIGHT) {
        const units = this.store.model.units;
        const topUnitsOnly = units.filter((u: IMountedUnit) => u.unit.unitKind === UnitKind.top).length === units.length;
        const hasHood = units.some((u: IMountedUnit) => hoodUnitGuard(u.unit));
        const noTopUnits = units.filter((u: IMountedUnit) => u.unit.unitKind === UnitKind.top).length === 0;
        // Handles case when no bottom or tall elements
        if (topUnitsOnly) {
          if (hasHood) {
            // until we don't have a proper way to get height differences between hoods and wall cabinets,
            // there will be such constants, as '0.24'
            bbox.max.add(new THREE.Vector3(0, -0.24, 0));
            bbox.min.add(new THREE.Vector3(0, size.y - MIN_KITCHEN_HEIGHT, 0));
          } else {
            bbox.min.add(new THREE.Vector3(0, size.y - MIN_KITCHEN_HEIGHT + 0.24, 0));
          }
        } else if (noTopUnits) {
          bbox.max.add(new THREE.Vector3(0, MIN_KITCHEN_HEIGHT - size.y, 0));
        }

      }
    }
    const center = new THREE.Vector3();
    bbox.getCenter(center);
    bbox.getSize(size);
    // size.y = Math.min(size.y, MAX_KITCHEN_HEIGHT);
    return { size, center, bbox };
  }

  private alignKitchen(center: THREE.Vector3, size: THREE.Vector3) {
    this.rootNode.position.x += -center.x; // + size.x / 2 - size.x / 2;
    this.rootNode.position.y += -center.y; // + size.y / 2 - size.y / 2; // this.ROOM_HEIGHT / 2;
    this.interiorNodeLeft.position.x += -center.x;
    this.interiorNodeLeft.position.y = -size.y / 2 - 0.01;
    this.interiorNodeRight.position.x += center.x;
    this.interiorNodeRight.position.y = -size.y / 2;
  }

  private addAmbientLight() {
    const ambientLight = new THREE.AmbientLight(WHITE_COLOR, 0.4);
    ambientLight.name = 'Reflection Light';
    this.scene.add(ambientLight);
  }

  private addHemiLight() {
    const light = new THREE.HemisphereLight(0xffffff, 0x000000, 1);
    light.name = 'Hemisphere Light';
    this.scene.add(light);
  }

  private addDirectionalLight() {
    const light = new THREE.DirectionalLight(WHITE_COLOR, 1.3);

    light.name = 'Window Light';
    light.castShadow = true;
    light.shadow.mapSize.width = 256;
    light.shadow.mapSize.height = 256;
    light.shadow.bias = -0.00395;

    light.position.set(2.5, 5, 22);
    light.target.position.set(0, 0, 0);

    light.shadow.camera.near = 1;
    light.shadow.camera.far = this.ROOM_WIDTH * 2;

    light.shadow.camera.top = this.ROOM_HEIGHT;
    light.shadow.camera.bottom = -this.ROOM_HEIGHT;
    light.shadow.camera.left = -this.ROOM_HEIGHT;
    light.shadow.camera.right = this.ROOM_HEIGHT;

    if (DEBUG) {
      var shadowHelper = new THREE.CameraHelper(light.shadow.camera);
      var helper = new THREE.DirectionalLightHelper(light, 5);
      this.scene.add(helper);
      this.scene.add(shadowHelper);
    }

    this.scene.add(light);
    this.scene.add(light.target);
    (window as any).lights.push(light);
  }

  private async loadInternalModels() {
    const magnifierModel = this.zoomService.loadMagnifierModel();
    return Promise.all(
      [
        this.loadInternalModel('add_button'),
        this.loadInternalModel('extend_button'),
        this.loadInternalModel('arrow_button'),
        magnifierModel
      ])
      .then((models: any) => {
        const [addButton, extendButton, arrowButton, zoomInButton, font] = models;
        this.font = font;
        this.extendButton = extendButton;
        this.addButton = addButton;
        this.arrowButton = arrowButton;
        this.zoomInButton = zoomInButton;
        this.arrowButton.rotation.x = Math.PI / 2;
        this.zoomInButton.scale.multiplyScalar(0.1);
        this.extendButton.rotation.x = Math.PI / 2;
        this.addButton.rotation.x = Math.PI / 2;
      })
  }

  private async loadInternalModel(name: string, firstMesh: boolean = true) {
    return new Promise((resolve, reject) => {
      this.loader.load(`assets/models/${name}.gltf`, (gltf: GLTF) => {
        if (firstMesh) {
          gltf.scene.traverse(o => {
            if (o instanceof THREE.Mesh) {
              resolve(o);
            }
          });
        } else {
          resolve(gltf.scene);
        }
      }, noop, reject);
    });
  }

  private makePlaceholder(placeholderSize: THREE.Vector3, applyWorktopOffset: boolean = false, zOffset: number = 0): THREE.Object3D {
    const { x, y, z } = placeholderSize;
    const placeholder = new THREE.Object3D();
    const geometry = new THREE.BoxGeometry(x, y, z);
    const box = new THREE.Mesh(geometry, this.placeholderMaterial);
    const addButton = this.makeAddButton();
    addButton.position.z = z / 2 + zOffset;
    placeholder.add(box);
    placeholder.add(addButton);
    const bbox = this.getKitchenBoundingBoxParameters(false);
    placeholder.position.y = (y - bbox.size.y) / 2;
    placeholder.position.z = z / 2 + (!applyWorktopOffset ? this.worktopDepthOffset() : 0);
    return placeholder;
  }

  private makeExtendButton() {
    const button = new THREE.Mesh(this.extendButton.geometry, this.extendButton.material);
    button.scale.copy(this.extendButton.scale);
    button.scale.multiplyScalar(BUTTON_SCALE);
    button.rotation.copy(this.extendButton.rotation);
    return button;
  }

  private makeAddButton() {
    const button = new THREE.Mesh(this.addButton.geometry, this.addButton.material);
    button.scale.copy(this.addButton.scale);
    button.scale.multiplyScalar(BUTTON_SCALE);
    button.rotation.copy(this.addButton.rotation);
    return button;
  }

  private makeArrowButton() {
    const button = new THREE.Mesh(this.arrowButton.geometry, this.arrowButton.material);
    button.scale.copy(this.arrowButton.scale);
    button.scale.multiplyScalar(BUTTON_SCALE);
    button.rotation.copy(this.arrowButton.rotation);
    return button;
  }

  private highlightSelectedUnit(id: UIId | undefined) {
    const nodes = this.findUnitsByUserData(userData => userData.data.uiId === id);
    this.selectionBox.visible = false;
    if (nodes.length) {
      const node = nodes[0];
      const bbox = new THREE.Box3();
      bbox.setFromObject(node);
      let size: THREE.Vector3 = new THREE.Vector3();
      bbox.getSize(size);
      const center = new THREE.Vector3();
      bbox.getCenter(center);
      this.drawSelectionBox(center, size);
      this.selectionBox.visible = true;
      this.buttons.remove(...this.buttons.children);
      this.renderShiftButtons(center, size, node.userData.data.width, node);
      if (id) {
        this.renderUnitZoomButton(id, center, size);
      }
    }
  }

  private highlightSkinal() {
    const skinal = this.sceneNode.getObjectByName(Nodes.Skinal);
    this.selectionBox.visible = false;
    if (skinal) {
      const bbox = new THREE.Box3();
      bbox.setFromObject(skinal);
      let size: THREE.Vector3 = new THREE.Vector3();
      bbox.getSize(size);
      const center = new THREE.Vector3();
      bbox.getCenter(center);
      this.drawSelectionBox(center, size);
      this.selectionBox.visible = true;
      this.renderSkinalZoomButton(center, size);
    }
    this.renderer.render(this.scene, this.camera);
  }

  private hideSelectionBox() {
    if (this.selectionBox) {
      this.selectionBox.visible = false;
    }
  }

  private resizeHandler = () => {
    const parent = this.element.parentElement;
    if (parent) {
      this.height = parent.clientHeight; //innerHeight;
      this.width = parent.clientWidth; //window.innerWidth - 345; // 345 is sidebar width
      this.renderer.setSize(this.width, this.height);
      this.dimensionsRenderer2D.setSize(this.width, this.height);
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
      this.updateCameras();
      this.renderTick();
    }
  };

  private renderTopButtons(units: THREE.Object3D[], unit: Unit) {
    const { store } = this;
    const topUnits = layoutService.topUnits(this.store.model);
    if (!store.isInPlacementMode) {
      return;
    }
    const viewStore = store.currentView as SelectElementViewStore;
    const left = units[0];
    if (!left) {
      return;
    }
    // Render Left
    const startingTalls = layoutService.getStartingTalls(store.model.units);
    const hasStartingTall = startingTalls.length > 0;
    if (hasStartingTall) {
      const lastStartingTall = layoutService.getLastUnit(startingTalls)!;
      const position = InsertUnit.After(lastStartingTall.position);
      if (viewStore.isPositionForSelectedValid(position)) {
        let lastStartingTallNode = this.findUnitsByUserData(userData => userData.data.uiId === lastStartingTall.uiId)[0];
        this.renderAfter(lastStartingTallNode, BUTTON_WIDTH_MM, position, UnitKind.top, HorizontalAlign.right);
      }
    } else {
      const position = InsertUnit.Left();
      if (viewStore.isPositionForSelectedValid(position)) {
        this.renderLeft(left, unit, position);
      }
    }

    // Render right
    const right = units.pop();
    if (!right) {
      return;
    }
    // Right for top is always after last
    const position = InsertUnit.Right();
    if (viewStore.isPositionForSelectedValid(position)) {
      this.renderRight(right, unit, position);
    }
    // }
    // Render between
    this.renderBetween(units, viewStore, topUnits, UnitKind.top);
  }

  private renderBetween(units: THREE.Object3D[], viewStore: SelectElementViewStore, allUnits: MountedUnitList, unitKind: UnitKind) {
    for (let i = 0; i < units.length; i++) {
      const currentUnit = units[i];
      const position = InsertUnit.After(currentUnit.userData.position);
      if (viewStore.isPositionForSelectedValid(position)) {
        this.renderAfter(currentUnit,
            (i + 1) < units.length ? units[i + 1].userData.data.width : allUnits[allUnits.length - 1].unit.width,
            position,
            unitKind,
            HorizontalAlign.right);
      }
    }
  }

  private renderBottomButtons(units: THREE.Object3D[], unit: Unit) {
    const { store } = this;
    const floorUnits = layoutService.floorUnits(store.model);
    const left = units[0];
    if (!left) {
      return;
    }

    if (!store.isInPlacementMode) {
      return;
    }
    const viewStore = store.currentView as SelectElementViewStore;
    // Render Left
    const startingTalls = layoutService.getStartingTalls(store.model.units);
    const hasStartingTall = startingTalls.length > 0;
    if (hasStartingTall) {
      const lastStartingTall = layoutService.getLastUnit(startingTalls)!;
      const position = InsertUnit.After(lastStartingTall.position);
      if (viewStore.isPositionForSelectedValid(position)) {
        let lastStartingTallNode = this.findUnitsByUserData(userData => userData.data.uiId === lastStartingTall.uiId)[0];
        this.renderAfter(lastStartingTallNode, units[position.after + 1].userData.data.width, position, UnitKind.bottom, HorizontalAlign.right);
      }
    } else {
      const position = InsertUnit.Left();
      if (viewStore.isPositionForSelectedValid(position)) {
        this.renderLeft(left, unit, position);
      }
    }

    // Render right
    const right = units.pop();
    if (!right) {
      return;
    }
    const finishingTalls = layoutService.getFinishingTalls(store.model.units);
    const hasFinishingTall = finishingTalls.length > 0;
    if (hasFinishingTall) {
      const firstFinishingTall = layoutService.getFirstUnit(finishingTalls)!;
      const position = InsertUnit.After(firstFinishingTall.position);
      if (viewStore.isPositionForSelectedValid(position)) {
        let firstFinishingTallNode = this.findUnitsByUserData(userData => userData.data.unitKind === UnitKind.tall
          && userData.position === firstFinishingTall.position)[0];
        this.renderAfter(firstFinishingTallNode, BUTTON_WIDTH_MM, position, UnitKind.bottom, HorizontalAlign.right);
      }
    } else {
      const position = InsertUnit.Right();
      if (viewStore.isPositionForSelectedValid(position)) {
        this.renderRight(right, unit, position);
      }
    }
    // Render between
    this.renderBetween(units, viewStore, floorUnits, UnitKind.bottom);
  }

  private renderTallButtons(units: THREE.Object3D[], unit: Unit) {
    const { store } = this;
    if (!store.isInPlacementMode) {
      return;
    }
    const viewStore = store.currentView as SelectElementViewStore;
    // Starting talls
    const startingTalls = layoutService.getStartingTalls(store.model.units);
    if (startingTalls.length === 0) {
      const position = InsertUnit.Left();
      if (viewStore.isPositionForSelectedValid(position)) {
        const bottomUnits = layoutService.bottomUnits(store.model);
        const topUnits = layoutService.topUnits(store.model);
        if (bottomUnits.length > 0) {
          const firstBottomUnit = layoutService.getFirstUnit(bottomUnits)!;
          const firstBottomNode = this.findUnitsByUserData(userData => userData.data && userData.data.uiId === firstBottomUnit.uiId)[0];
          this.renderLeft(firstBottomNode, unit, position)
        } else if (topUnits.length > 0) {
          const firstTopUnit = layoutService.getFirstUnit(topUnits)!;
          const firstTopNode = this.findUnitsByUserData(userData => userData.data.uiId === firstTopUnit.uiId)[0];
          this.renderLeft(firstTopNode, unit, position);
        }
      }

    } else if (startingTalls.length > 0) {
      // Render left
      const position = InsertUnit.Left();
      const left = units[0];
      if (viewStore.isPositionForSelectedValid(position)) {
        this.renderLeft(left, unit, position);
      }
      // Render right
      const lastStartingTall = layoutService.getLastUnit(startingTalls)!;
      const lastStartingTallNode = units.find(unit => unit.userData.data.uiId === lastStartingTall.uiId);
      const hasUnitsAfterLastStartingTall = startingTalls.length !== store.model.units.length;
      if (hasUnitsAfterLastStartingTall) {
        const position = InsertUnit.After(lastStartingTall.position);
        if (viewStore.isPositionForSelectedValid(position)) {
          this.renderAfter(lastStartingTallNode!, BUTTON_WIDTH_MM, position, UnitKind.tall, HorizontalAlign.right);
        }
      } else {
        const position = InsertUnit.Right();
        if (viewStore.isPositionForSelectedValid(position)) {
          this.renderRight(lastStartingTallNode!, unit, position);
        }
      }
      // Render between
      for (let i = 0; i < startingTalls.length - 1; i++) {
        const tallNode = units.find(unit => unit.userData.data.uiId === startingTalls[i].uiId)!;
        const position = InsertUnit.After(startingTalls[i].position);
        if (viewStore.isPositionForSelectedValid(position)) {
          this.renderAfter(tallNode, units[position.after + 1].userData.data.width, position, UnitKind.tall);
        }
      }
    }
    // Only tall units is handled as starting units, no need to handle them twice as finishing talls
    if (startingTalls.length === store.model.units.length) {
      return;
    }
    // Finishing talls
    const finishingTalls = layoutService.getFinishingTalls(store.model.units);
    if (finishingTalls.length === 0) {
      const position = InsertUnit.Right();
      if (viewStore.isPositionForSelectedValid(position)) {
        const bottomUnits = layoutService.bottomUnits(store.model);
        if (bottomUnits.length > 0) {
          const lastBottomNode = this.findUnitsByUserData(userData => userData.data.uiId === layoutService.getLastUnit(bottomUnits)!.uiId)[0];
          this.renderRight(lastBottomNode, unit, position)
        } else {
          console.error('Unexpected state');
        }
      }
    } else if (finishingTalls.length > 0) {
      // Render left
      const bottomUnits = layoutService.bottomUnits(store.model);
      if (bottomUnits.length > 0) {
        const lastBottomUnit = layoutService.getLastUnit(bottomUnits)!;
        const position = InsertUnit.After(lastBottomUnit.position);
        if (viewStore.isPositionForSelectedValid(position)) {
          const lastBottomNode = this.findUnitsByUserData(userData => userData.data.uiId === lastBottomUnit.uiId)[0];
          this.renderAfter(lastBottomNode, BUTTON_WIDTH_MM, position, UnitKind.tall);
        }
      }
      // Render right
      const lastFinishing = layoutService.getLastUnit(finishingTalls)!;
      const position = InsertUnit.Right();
      if (viewStore.isPositionForSelectedValid(position)) {
        const lastFinishingTallNode = units.find(unit => unit.userData.data.uiId === lastFinishing.uiId)!;
        this.renderRight(lastFinishingTallNode, unit, position);
      }
      // Render between
      for (let i = 0; i < finishingTalls.length - 1; i++) {
        const tall = finishingTalls[i];
        const tallNode = units.find(unit => unit.userData.data.uiId === tall.uiId)!;
        const position = InsertUnit.After(tall.position);
        if (viewStore.isPositionForSelectedValid(position)) {
          this.renderAfter(tallNode, BUTTON_WIDTH_MM, position, UnitKind.tall);
        }
      }
    }
  }

  private disposeReactions() {
    this.disposers.forEach(d => d());
  }

  private drawSelectionBox(center: THREE.Vector3, size: THREE.Vector3) {
    var points = [];
    const delta = 0.01;
    points.push(center.x - 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z + 0.5 * size.z);
    points.push(center.x - 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z + 0.5 * size.z);
    points.push(center.x + 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z + 0.5 * size.z);
    points.push(center.x + 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z + 0.5 * size.z);
    points.push(center.x - 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z + 0.5 * size.z);

    points.push(center.x - 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z - 0.5 * size.z);
    points.push(center.x - 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z - 0.5 * size.z);
    points.push(center.x + 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z - 0.5 * size.z);
    points.push(center.x + 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z - 0.5 * size.z);
    points.push(center.x - 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z - 0.5 * size.z);

    points.push(center.x - 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z + 0.5 * size.z);
    points.push(center.x - 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z - 0.5 * size.z);
    points.push(center.x + 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z - 0.5 * size.z);
    points.push(center.x + 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z + 0.5 * size.z);
    points.push(center.x - 0.5 * size.x, center.y + 0.5 * size.y + delta, center.z + 0.5 * size.z);


    points.push(center.x - 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z + 0.5 * size.z);
    points.push(center.x - 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z - 0.5 * size.z);
    points.push(center.x + 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z - 0.5 * size.z);
    points.push(center.x + 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z + 0.5 * size.z);
    points.push(center.x - 0.5 * size.x, center.y - 0.5 * size.y + delta, center.z + 0.5 * size.z);

    const geometry = new LineGeometry().setPositions(points);
    const material = new LineMaterial({ color: SELECTION_BOX_COLOR, linewidth: 0.003 });

    this.selectionBox.geometry = geometry;
    this.selectionBox.scale.set(1, 1, 1);
    this.selectionBox.material = material;
  }

  private renderShiftButtons(center: THREE.Vector3, size: THREE.Vector3, width: number, node: THREE.Object3D) {
    if (!this.store.shiftButtonVisible) {
      return;
    }
    if (this.store.canSelectedShiftToRight()) {
      const rightButton = this.makeArrowButton();
      rightButton.name = Nodes.ShiftRightButton;
      rightButton.position.x = center.x + size.x / 2 + (width < BUTTON_WIDTH_MM ? BUTTON_WIDTH : 0);
      rightButton.position.z = size.z + BUTTON_DEPTH * BUTTON_SCALE + this.shiftButtonZOffset(node, false);
      rightButton.position.y = center.y;
      this.buttons.add(rightButton);
    }
    if (this.store.canSelectedShiftToLeft()) {
      const leftButton = this.makeArrowButton();
      leftButton.name = Nodes.ShiftLeftButton;
      leftButton.rotation.y = Math.PI;
      leftButton.position.x = center.x - size.x / 2 - (width < BUTTON_WIDTH_MM ? BUTTON_WIDTH : 0);
      leftButton.position.z = size.z + BUTTON_DEPTH * BUTTON_SCALE + this.shiftButtonZOffset(node, true);
      leftButton.position.y = center.y;
      this.buttons.add(leftButton);
    }
  }

  private shiftButtonZOffset(node: THREE.Object3D, isLefButton: boolean) {
    const {position, data} = node.userData;
    if (data.unitKind === UnitKind.top) {
      const notBottomUnits = layoutService.notBottomUnits(this.store.model);
      const nearestTowardsShiftDirectionUnit = notBottomUnits.find(unit => unit.position === position + (1 * (isLefButton ? -1 : 1)));
      const nextAfterNearestUnit = notBottomUnits.find(unit => isLefButton ? unit.position === position - 2 : unit.position >= position + 2);
      if (nextAfterNearestUnit?.unit.unitKind === UnitKind.tall && (nearestTowardsShiftDirectionUnit?.unit.width || 0) < BUTTON_WIDTH_MM) {
        return Converter.mmToMeters((nextAfterNearestUnit?.unit.depth || 0) - data.depth) + this.worktopDepthOffset();
      }
      if ((nearestTowardsShiftDirectionUnit?.unit.depth || 0) > TOP_CABINET_DEPTH) {
        return Converter.mmToMeters((nearestTowardsShiftDirectionUnit?.unit.depth || 0) - data.depth);
      }
    }
    return data.unitKind === UnitKind.bottom ? this.worktopDepthOffset() : 0;
  }

  /***
   * Returns y position based on type of the unit. Hood units have different Y position from ElementUnit
   */
  private static getTopPlaceholderYOffset(unit: Unit) {
    if (hoodUnitGuard(unit)) {
      return 1.6;
    } else {
      return 1.4275;
    }
  }

  private transitionCameraToViewPosition(instant: boolean = false) {
    if (this.store.zoomed) {
      return;
    }

    const position = this.currentCamera === ActiveCamera.Front ? this.frontPosition : this.perspectivePosition;

    if (instant) {
      this.camera.position.x = position.x;
      this.camera.position.y = position.y;
      this.camera.position.z = position.z;
      this.camera.lookAt(0, 0, 0);
      this.renderTick();
    } else {
      this.transition.transitionTo([position.x, position.y, position.z, 0, 0, 0], 1500);
    }
  }

  private renderUnitZoomButton(id: UIId, center: THREE.Vector3, size: THREE.Vector3) {
    if (this.store.zoomed) {
      return;
    }
    if (this.store.isUnitPlaceholder(id)
      || this.store.isUnitFittingStrip(id)
      || this.store.isUnitUpstand(id)) {
      return;
    }
    const zoomButton = this.zoomInButton;
    zoomButton.name = Nodes.UnitZoomButton;
    zoomButton.userData.id = id;
    zoomButton.position.x = center.x;
    zoomButton.position.z = center.z + size.z / 2 + BUTTON_DEPTH;
    zoomButton.position.y = center.y;
    this.buttons.add(zoomButton);
  }

  private renderSkinalZoomButton(center: THREE.Vector3, size: THREE.Vector3) {
    if (this.store.zoomed) {
      return;
    }
    const zoomButton = this.zoomInButton;
    zoomButton.name = Nodes.SkinalZoomButton;
    zoomButton.position.x = center.x;
    zoomButton.position.z = center.z + size.z / 2 + BUTTON_DEPTH;
    zoomButton.position.y = center.y;
    this.buttons.add(zoomButton);
  }

  private zoomToUnit(id: UIId) {
    const node = this.findUnitsByUserData(node => node.data.uiId === id)[0];
    const center = new THREE.Vector3();
    const nodeBBox = new THREE.Box3();
    nodeBBox.setFromObject(node);
    nodeBBox.getCenter(center);
    const unitTransitionMatcher = (match: { left: () => void, right: () => void, other: () => void }) => {
      if (this.store.isUnitLeftUpstand(id) && this.store.isUnitRightUpstand(id)) {
        match.other();
      } else if (this.store.isUnitLeftUpstand(id)) {
        match.left();
      } else if (this.store.isUnitRightUpstand(id)) {
        match.right();
      } else {
        match.other();
      }
    };
    switch ((node.userData as IUnitUserData).data.unitKind) {
      case UnitKind.bottom:
        unitTransitionMatcher({
          left: () => this.transition.transitionTo([center.x - 1, center.y + 2, center.z, center.x, center.y, center.z], 700),
          right: () => this.transition.transitionTo([center.x + 1, center.y + 2, center.z, center.x, center.y, center.z], 700),
          other: () => this.transition.transitionTo([center.x, center.y + 2, center.z + 2, center.x, center.y, center.z], 700)
        });
        break;
      case UnitKind.tall:
        unitTransitionMatcher({
          left: () => this.transition.transitionTo([center.x - 1, center.y + 2, center.z, center.x, center.y, center.z], 700),
          right: () => this.transition.transitionTo([center.x + 1, center.y + 2, center.z, center.x, center.y, center.z], 700),
          other: () => this.transition.transitionTo([center.x, center.y, center.z + 4, center.x, center.y, center.z], 700)
        });
        break;
      case UnitKind.top:
        unitTransitionMatcher({
          left: () => this.transition.transitionTo([center.x - 1, center.y + 1, center.z, center.x, center.y, center.z], 700),
          right: () => this.transition.transitionTo([center.x + 1, center.y + 1, center.z, center.x, center.y, center.z], 700),
          other: () => this.transition.transitionTo([center.x, center.y, center.z + 2, center.x, center.y, center.z], 700)
        });
        break;
    }
    this.highlightSelectedUnit(id)
  }

  private zoomToSkinal() {
    this.buttons.remove(...this.buttons.children);
    const node = this.findNodes(node => node.name === Nodes.Skinal)[0];
    const center = new THREE.Vector3();
    const nodeBBox = new THREE.Box3();
    nodeBBox.setFromObject(node);
    nodeBBox.getCenter(center);
    this.transition.transitionTo([center.x, center.y, center.z + 2, center.x, center.y, center.z], 700);
  }
}

function noop() {
}
