import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Converter } from '../../helpers/Converter';

import './Dimensions.scss';

interface IDimensionsProps {
  width: number;
  height: number;
  depth: number;
  className?: string;
}

const Dimensions: React.FC<IDimensionsProps> = ({width, height, depth, className}) => {
  return (
    <ul className={classNames("dimensions", className)}>
      {!!width && <li className="dimensions__item">
          <FormattedMessage id="ui.elements.dimensions.w" />: {Converter.mmToCm(width)}</li>}
      {!!depth && <li className="dimensions__item">
          <FormattedMessage id="ui.elements.dimensions.d" />: {Converter.mmToCm(depth)}</li>}
      {!!height && <li className="dimensions__item">
          <FormattedMessage id="ui.elements.dimensions.h" />: {Converter.mmToCm(height)}</li>}
    </ul>
  );
};

export default Dimensions;
