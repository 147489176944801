import * as React from 'react';
import classNames from 'classnames';

import './Tooltip.scss';

interface IProps {
  className?: string;
}

const Tooltip: React.FC<IProps> = ({ className, children }) => {
  return (
    <div className={classNames('tooltip', className)}>
      {children}
    </div>
  );
};

export default Tooltip;
