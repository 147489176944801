import { action, observable, computed } from 'mobx';

import Localization, { Locale } from './Localization';
import { YamlDatasetService } from '@canvas-logic/engine';
import { MessageConfiguration, NotificationID, NotificationsStore } from './NotificationsStore';
import { ConfiguratorStore } from './ConfiguratorStore';
import { queryString } from '../services/queryString';
import ActionTracker from '../features/ActionTracker/trackers/ActionTracker';
import ActionTrackerFactory from '../features/ActionTracker/trackers/ActionTrackerFactory';
import EmbeddingService from '../services/EmbeddingService';

export class RootStore {
  notificationsStore = new NotificationsStore();
  configurator: ConfiguratorStore;
  actionTracker: ActionTracker;
  private embeddingService = new EmbeddingService();

  get location() {
    return this._location;
  }

  _location: Location;

  localization: Localization;
  @observable isLoading = true;

  private datasetService: YamlDatasetService;

  constructor() {
    this.setupLocalization();
  }

  @action.bound
  async init() {
    this.isLoading = true;

    await this.localization.init();

    await this.embeddingService.awaitBootstrapping();
    this.embeddingService.subscribeToLocaleChange(locale => {
      if (Localization.isLocaleSupported(locale)) {
        this.localization.changeLanguage(Locale[locale]);
        this.localization.externalControl = true;
      } else {
        console.warn(`Attempt to set unsupported locale (${locale})`);
      }
    });

    this.embeddingService.sendReady();
    this._location = await this.embeddingService.getLocation();

    this.datasetService = await loadYamls();
    const params = queryString(document.location.search);
    this.configurator = new ConfiguratorStore(this, this.datasetService, 'noTracking' in params);
    this.actionTracker = ActionTrackerFactory.create(this.configurator);
    this.isLoading = false;

    this.embeddingService.subscribeToActionsControl(this.actionTracker.playActions);

    window.addEventListener('beforeunload', () => {
      this.actionTracker.quit();
    })
  }

  /**
   * Locale selection logic:
   *
   * 1. Locale switch is disabled
   * 1.1. Look for the hardcoded locale (from .build* config)
   * 1.2. If hardcoded locale is not supported, use preferred by browser locale.
   * 1.3. If preferred locale is not supported, use default one.
   *
   * 2. Locale switch is enabled
   * 2.1. Standalone configurator (no iframe)
   * 2.1.1. Look for stored locale (local storage).
   * 2.1.2. If no locale is stored, use preferred by browser locale.
   * 2.1.3. If preferred locale is not supported, use default one.
   * 2.2. Embedded configurator
   * 2.2.1. Set preferred by browser locale.
   * 2.2.2. If preferred locale is not supported, use default one.
   * 2.2.3. Wait for host window to set locale via postMessage interface.
   *
   * @see EmbeddingService.subscribeToLocaleChange
   * @private
   */
  private setupLocalization() {
    const noLocaleSwitch = process.env.REACT_APP_CL__FEATURE__LOCALE_SWITCH === 'OFF';

    let preferredLocale = this.embeddingService.isEmbedded ? Localization.BrowserAwareLocale : Localization.PreferredUserLocale;
    if (noLocaleSwitch) {
      const hardcodedLocale = process.env.REACT_APP_CL__WEBSITE_LANG;

      if (Localization.isLocaleSupported(hardcodedLocale)) {
        preferredLocale = Locale[hardcodedLocale];
      } else {
        console.warn(`Unsupported locale: ${hardcodedLocale}`);
      }
    }
    this.localization = new Localization(preferredLocale);

    if (!this.embeddingService.isEmbedded && !noLocaleSwitch) {
      this.localization.setLocaleSwitchListener(locale => {
        localStorage.setItem(Localization.KEY_LOCALE, locale);
      });
    }
  }

  async fetchTemplate() {
    const result = await fetch('assets/templates/kitchen.ejs');
    return await result.text();
  }

  /**
   * @todo consider making this private after introduction of Notification constructor factory methods
   */
  async notification(message: MessageConfiguration) {
    return this.notificationsStore.showNotification(message);
  }

  muteNotification(notificationId: NotificationID) {
    this.notificationsStore.muteNotification(notificationId);
  }

  get isEmbedded() {
    return this.embeddingService.isEmbedded;
  }

  @computed
  get localeControlledExternally() {
    return this.localization.externalControl;
  }
}

async function loadYamls(): Promise<YamlDatasetService> {
  try {
    const [schema, loadNobOptions, plugins] = await Promise.all([
      fetch('assets/dataset/nobilia.schema.yml').then(r => r.text()),

      Promise.all([
        fetch('assets/dataset/101_nobilia.additional_appliances.options.yml').then(r => r.text()),
        fetch('assets/dataset/111_nobilia.additional_appliances.optionGroups.yml').then(r => r.text()),
        fetch('assets/dataset/201_nobilia.options.yml').then(r => r.text()),
        fetch('assets/dataset/211_nobilia.optionGroups.yml').then(r => r.text()),
        fetch('assets/dataset/nobilia.products.yml').then(r => r.text())]
      ),

      Promise.all([
        fetch('assets/plugins/KitchenPlugin.js').then(r => r.text())
      ])
    ]);
    return new YamlDatasetService(schema, loadNobOptions, plugins)
  } catch (error) {
    throw new Error('Error downloading one or more files:' + error);
  }

}

export default new RootStore();
