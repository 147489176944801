import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IReportItem } from '../../model/ReportItem';
import ReportListCard from '../ReportListCard/ReportListCard';
import { ReportCalculation } from '../../schema';

import './ReportList.scss';

export interface IProps {
  id?: string;
  items: IReportItem[];
  title: JSX.Element;
  total: JSX.Element;
  onChange?: (variantId: string, excluded: boolean, amount: number) => void;
}

class ReportList extends React.Component<IProps> {
  render() {
    const { id, items, title, total, onChange } = this.props;
    const showPrices = process.env.REACT_APP_CL__FEATURE__PRICING === 'ON';

    return (
      <div className="cart-table">
        <div className="cart-table__header">
          <h2 id={id} className="cart-table__thCell cart-table__thCell_title">
            {title}: {this.amount}
          </h2>
          <div className="cart-table__thCell cart-table__thCell_quantity">
            <FormattedMessage id="ui.quantity"/>
          </div>
          {showPrices &&
          <>
            <div className="cart-table__thCell cart-table__thCell_price">
              <FormattedMessage id="ui.report.price"/>
            </div>
            <div className="cart-table__thCell cart-table__thCell_subtotal">
              <FormattedMessage id="ui.report.subtotal"/>
            </div>
          </>
          }
        </div>
        <ul className="cart-table__main">
          {
            items.map((item: IReportItem) =>
              <ReportListCard key={item.calculatedVariantId} item={item} onChange={onChange}/>)
          }
        </ul>
        {showPrices &&
        <div className="cart-table__footer">
          <p className="cart-table__footer-total">{total}</p>
          <div className="cart-table__footer-price">{this.totalPrice.toFixed(2)} €
            <div className="cart-table__footer-vat">
              <FormattedMessage id="ui.report.summary.vat" values={{ sum: ReportList.countVat(this.totalPrice).toFixed(2) }}/>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }

  get totalPrice() {
    return this.props.items.reduce((accumulator, item) => {
      const excluded = item.reportCalculation === ReportCalculation.deselected;
      return accumulator +
        (excluded ?
          0
          : ((item.item.price * item.amount) + item.item.basePrice));
    }, 0);
  }

  get amount() {
    return this.props.items.reduce((accumulator, item) => {
      const excluded = item.reportCalculation === ReportCalculation.deselected;
      return accumulator + (excluded ? 0 : item.amount)
    }, 0);
  }

  static countVat(sum: number) {
    return (sum / 1.19) * 0.19
  }
}

export default ReportList;
