import * as React from 'react';
import { observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ReportStore } from '../../stores/ReportStore';

import Icon from '../../components/Icon/Icon';
import Button from '../../components/buttons/Button/Button';
import ReportList from '../../components/ReportList/ReportList';
import ReportMountingService from '../../components/ReportMountingService/ReportMountingService';
import ReportTotal from '../../components/ReportTotal/ReportTotal';

import { RenderStatus } from '../../features/PRRender/PRRenderService';
import CanvasLogicLogo from '../../components/CanvasLogicLogo/CanvasLogicLogo';
import NobiliaLogo from '../../components/NobiliaLogo/NobiliaLogo';
import HQLoader from '../../features/PRRender/components/HQLoader/HQLoader';
import Dialog, { DialogContent, DialogControls } from '../../components/Dialog/Dialog';
import Checkbox from '../../components/Checkbox/Checkbox';

import './Report.scss';
import NotificationContainer from '../../components/Notification/NotificationContainer';
import RootStore from '../../stores/RootStore';

@observer
export default class Report extends React.Component {
  private readonly reportStore: ReportStore;

  constructor(props: any) {
    super(props);
    this.reportStore = new ReportStore();
  }

  render() {
    const { canvasURL } = this.reportStore.kitchen;
    const renderStatus = this.reportStore.renderService.status;

    if (!canvasURL) {
      return <Redirect to='/configurator'/>
    }
    const lowImageClasses = classNames('cart__image', { 'cart__image--invisible': this.reportStore.imageUrl !== '' });
    const highImageClasses = classNames('cart__image', { 'cart__image--invisible': this.reportStore.imageUrl === '' });

    return (
      <div className="cart">
        <div className="cart__top-bar">
          <div className="cart__top-bar__wrapper cart__wrapper">
            <Link to="/" className="cart__link-back" onClick={this.reportStore.handleBackToConfiguration}>
              <Icon type={'leftArrow'}/>
              <FormattedMessage id="ui.report.back"/>
            </Link>
            <Button color="transparent" className="button_with-icon cart__top-bar-button" onClick={this.reportStore.handlePrintProject}>
              <Icon type={'print'}/>
              <FormattedMessage id="ui.report.print"/>
            </Button>
          </div>
        </div>
        <div className="cart__inner cart__wrapper">
          <div className="cart__image-container">
            <div className="cart__image-wrapper">
              <img
                className={lowImageClasses}
                src={canvasURL}
                alt="Kitchen image"
              />
              <img
                className={highImageClasses}
                src={this.reportStore.imageUrl}
                alt="Hi-res kitchen image"
              />
              {this.reportStore.imageUrl === ''
              && <HQLoader className='cart__nobilia-render-loader' error={renderStatus === RenderStatus.ERROR}/>}
              {this.reportStore.imageUrl === ''
              && <>
                {process.env.REACT_APP_CL__FEATURE__LOGO === 'ON' && <NobiliaLogo className='cart__nobilia-logo'/>}
                <CanvasLogicLogo className='cart__cl-logo'/>
              </>
              }
            </div>
          </div>
        </div>
        <div className="cart__report">
          <div className="cart__report-container">
            <div className="cart__wrapper">
              {this.reportStore.reportGroups.map((group, index, allGroups) =>
                <ReportList
                  key={group.name}
                  id={group.name}
                  items={group.items}
                  title={<FormattedMessage id={`ui.report.units.${group.name}`}/>}
                  total={<FormattedMessage id={`ui.report.units.${group.name}_total`}/>}
                  onChange={this.reportStore.changeReportItem(allGroups)}
                />
              )}
              <div className={classNames(
                'cart__mounting-service',
                { 'cart__mounting-service--print-invisible': !this.reportStore.selectedMountingVariantId }
              )}
              >
                <ReportMountingService
                  options={this.reportStore.mountingOptions}
                  selectedMountingVariantId={this.reportStore.selectedMountingVariantId}
                  onChange={this.reportStore.setSelectedMountingOption}
                />
              </div>
              <div className="cart__total-header">
                <FormattedMessage id="ui.report.summary.total" tagName="h3"/>
              </div>
            </div>
          </div>
          <ReportTotal
            classes={{
              root: 'cart__total-bar',
              wrapper: 'cart__wrapper'
            }}
            items={this.totalItems}
            total={this.reportStore.totalSum}
            onCheckout={this.reportStore.checkout}
          />
        </div>
        {this.reportStore.dialogVisible &&
        <div className="cart__dialog-wrapper">
          <Dialog className="cart__dialog">
            <DialogContent>
              <FormattedMessage id="ui.report.dialog_reset"/>
            </DialogContent>
            <Checkbox
              label={<FormattedMessage id="ui.notifications.mute"/>}
              checked={this.reportStore.doNotShowDialogAgain}
              onChange={this.reportStore.toggleDoNotShowDialogAgain}
            />
            <DialogControls className="cart_dialog-controls">
              <Link to="/" onClick={this.reportStore.handleBackToConfiguration} className="cart__dialog-button">
                <Button color="white">
                  <FormattedMessage id="ui.proceed"/>
                </Button>
              </Link>
              <Button color="white" onClick={this.reportStore.toggleDialog} className="cart__dialog-button">
                <FormattedMessage id="ui.cancel"/>
              </Button>
            </DialogControls>
          </Dialog>
        </div>
        }
        <NotificationContainer alertStore={RootStore.notificationsStore} />
      </div>
    );
  }

  get totalItems() {
    const showPrices = process.env.REACT_APP_CL__FEATURE__PRICING === 'ON';
    return this.reportStore.reportGroups.map(group => {
      const amount = group.items.reduce(this.reportStore.totalCount, 0);
      return (
        <HashLink to={`#${group.name}`}>
          <FormattedMessage id={`ui.report.units.${group.name}`}/>
          {showPrices && `: ${amount}`}
        </HashLink>
      );
    });
  }
}
