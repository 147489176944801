import { FlatStructuralTransformer, MutatorContext, PModel, ValidationResult } from '@canvas-logic/engine';
import { IKitchen, IWorktop } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';

export class ChangeWorktopMaterialMutator extends KitchenBaseMutator {

  constructor(private name: string) {
    super();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, origin: PModel<IKitchen>): [IKitchen, ValidationResult] {
    const worktops = context.optionValuesByPath(origin, 'worktop', new FlatStructuralTransformer<IWorktop>()) ?? [];
    for (let i = 0; i < origin.worktop.length; i++) {
      const worktop = origin.worktop[i];
      const newWorktop = worktops.find(w => w.model.depth === worktop.depth && w.model.name === this.name);
      if (newWorktop) {
        origin.worktop[i] = newWorktop.model;
      } else return [origin, ValidationResult.Error('Invalid depth')];
    }
    return [origin, ValidationResult.Valid()];
  }
}
