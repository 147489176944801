import * as React from 'react';
import classNames from 'classnames';

import { ReactComponent as Square } from './images/square.svg';

import './ARLoader.scss';

interface IProps {
  className?: string;
}

const ARLoader: React.FC<IProps> = ({ className, children }) => {
  return (
    <div className={classNames('ar-loader', className)}>
      <Square className="ar-loader__loading-icon"/>
      {children}
    </div>
  );
};

export default ARLoader;
