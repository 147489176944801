import * as React from 'react';
import { Component } from 'react';
import classNames from 'classnames';

import './Sidebar.scss';

interface IProps {
  className?: string;
}

export default class Sidebar extends Component<IProps> {
  render() {
    return (
      <aside className={classNames('sidebar', this.props.className)}>
        {this.props.children}
      </aside>
    );
  }
}
