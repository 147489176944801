import {
  Engine,
  getTypeRef,
  getOptionId,
  IdentityStructuralTransformer,
  ModelMutator,
  Option,
  OptionGroup,
  PModel,
  POption,
  POptionGroup,
  Product,
  YamlDatasetService
} from '@canvas-logic/engine';

import { Object3D } from 'three';
import { Schema } from '@canvas-logic/engine-data';
import { action, computed, observable } from 'mobx';
import { queryString } from '../services/queryString'

import { assertUnreachable } from '../helpers/utils';
import { ActiveCamera } from '../model/ConfigurationState';
import {
  fittingStripGuard,
  IMountedAdditional,
  placeholderGuard,
  SearchEntitiesByComponents,
  ShareImageParams,
  ShareOn,
  skinalGuard,
  UIId,
  upstandGuard
} from '../model/Model';
import { ViewModel } from '../model/ViewModel';
import { ChangeKitchenMaterialMutator } from '../mutators/ChangeKitchenMaterialMutator';
import { UpdateCalculationsMutator } from '../mutators/UpdateCalculationsMutator';
import { KitchenSerializer } from '../services/KitchenSerializer';
import { EditOptionViewStore, KitchenOption } from './ConfiguratorViews/EditOptionViewStore';
import { ElementViewStore } from './ConfiguratorViews/ElementViewStore';

import { OverviewViewStore } from './ConfiguratorViews/OverviewViewStore';
import { SelectAdditionalViewStore } from './ConfiguratorViews/SelectAdditionalViewStore';
import { SelectCategoryViewStore } from './ConfiguratorViews/SelectCategoryViewStore';
import { SelectElementState, SelectElementViewStore } from './ConfiguratorViews/SelectElementViewStore';
import { RootStore } from './RootStore';
import { ShiftType, ShiftUnitMutator } from '../mutators/ShiftUnitMutator';
import gzip from 'gzip-js';
import { NotificationID, NotificationType } from './NotificationsStore';
import { layoutService } from '../services/LayoutService';
import { UnitMatcher } from '../services/UnitMatcher';
import { domainService } from '../services/DomainService';
import { IAdditionalAppliance, IKitchen, IMountedUnit, ISkinal, ReportCalculation, Unit, UnitKind } from '../schema';
import { DeleteUnitMutator } from '../mutators/DeleteUnitMutator';
import { DeleteSkinalMutator } from '../mutators/DeleteSkinalMutator';
import { ISerializedKitchenV4, SerializedKitchenLatest } from '../services/SerializedKitchen';
import { ArService } from '../features/AR/ArService';
import { ShareLinkService } from '../features/ShareLink/ShareLinkService';
import { engineUtils } from '../services/EngineUtils';
import {
  ConfiguratorAction,
  DeselectSelectedElementAction,
  SelectUnitAction,
  UnselectUnitAction,
  ZoomInAction,
  ZoomOutAction
} from '../features/ActionTracker/actions/Action';
import { EditWorktopViewStore } from './ConfiguratorViews/EditWorktopViewStore';
import LinkedElementModelEvent, { LinkedElementModelEventType } from '../helpers/LinkedElementModelEvent';


export interface IConfiguratorState {
  selectedElementType: SelectedElementType;
  selectedUnitUiId: UIId | null;
  zoomed: boolean;
  deviceType: DeviceType;
  model: ISerializedKitchenV4;
}

export enum ConfigurationViewStoreName {
  OVERVIEW          = 'overview',
  EDIT_OPTION       = 'editOption',
  EDIT_WORKTOP      = 'editWorktop',
  SELECT_ADDITIONAL = 'selectAdditional',
  SELECT_CATEGORY   = 'selectCategory',
  SELECT_ELEMENT    = 'selectElement',
  ELEMENT_VIEW      = 'elementView'
}

export enum DeviceType {
  DESKTOP,
  HANDLET
}

export type SelectedElement = ISkinal | IMountedUnit | null;

export enum SelectedElementType {
  NONE,
  Unit,
  Skinal
}

export interface ViewStore {
  readonly name: ConfigurationViewStoreName;
}

type ConfiguratorParams = {
  v: string;
  link: string;
  showPrices: string;
}

export class ConfiguratorStore {
  @observable private _sideBarOpened: boolean = true;
  @observable currentView: ViewStore = new OverviewViewStore(this.rootStore, this);
  @observable loaded = false;
  @observable template: string;
  @observable cameraView: ActiveCamera = ActiveCamera.Front;
  @observable pendingForScreenshot: boolean;
  @observable selectedUnitUiId: UIId | null = null;
  @observable selectedElementType: SelectedElementType = SelectedElementType.NONE;
  @observable deviceType: DeviceType = DeviceType.DESKTOP;
  @observable showRuler = false;
  @observable showRender: boolean;
  @observable zoomed = false;
  private static version = 2;
  public scene: Object3D;
  private canvasSize: { width: number; height: number };
  private initActionSent = false;

  @computed get shiftButtonVisible() {
    return this.selectedElement && isElementUnit(this.selectedElement) && !this.zoomed;
  }

  @computed get elementToInsert() {
    return this.currentView instanceof SelectElementViewStore ? this.currentView.elementToInsert : undefined;
  }

  @observable.ref public model: PModel<IKitchen>;

  @observable private _canvasURL: string;
  private readonly _engine: Engine;
  private readonly schema: Schema;
  private readonly product: Product;
  private makeScreenshotResolver: any;
  private readonly _propertyValuesByPath: (model: PModel, propertyPath: string) => Array<POption | POptionGroup> | number[] | string[] | boolean[] | undefined;

  get engine() {
    return this._engine;
  }

  @computed
  get canvasURL() {
    const serializer = new KitchenSerializer(this.engine, this.model);
    const json = serializer.serialize();
    const str = JSON.stringify(json);
    const link = btoa(str);
    console.log(link);

    return this._canvasURL;
  }

  get propertyValuesByPath() {
    return this._propertyValuesByPath;
  }

  @computed
  public get selectedElement(): ISkinal | IMountedUnit | null {
    switch (this.selectedElementType) {
      case SelectedElementType.NONE:
        return null;
      case SelectedElementType.Unit:
        return this.model.units.find((mountedUnit: IMountedUnit) => mountedUnit.uiId === this.selectedUnitUiId) ?? null;
      case SelectedElementType.Skinal:
        return this.model.skinal ?? null;
      default:
        return assertUnreachable(this.selectedElementType, 'Invalid SelectedElementType');
    }
  }

  @computed
  public get viewModel(): ViewModel {
    return new ViewModel(this.model);
  }

  @computed
  get totalPrice() {
    const units = this.model.units;
    const additionals = this.mountedAdditionals;
    const worktop = this.model.worktop[0];
    const plinth = this.model.plinths;
    const mountingSet = this.model.mountingSet;
    const skinal = this.model.skinal;

    let price = units.reduce((accumulator: number, item: IMountedUnit) => accumulator +
      new UnitMatcher(item.unit)
        .withPrice()
        .map(u => u.price, () => 0), 0);
    price = additionals.reduce((accumulator: number, item: IMountedAdditional) => accumulator + item.additional.price, price);
    if (worktop && worktop.amount > 0) {
      price += worktop.basePrice + worktop.price * worktop.amount;
    }

    plinth.forEach(plinth => {
      if (plinth.reportCalculation === ReportCalculation.selected && plinth.amount > 0) {
        price += plinth.basePrice + plinth.price * plinth.amount;
      }
    });
    if (mountingSet && mountingSet.amount > 0) {
      price += mountingSet.basePrice + mountingSet.price * mountingSet.amount;
    }
    if (skinal && skinal.amount > 0) {
      price += skinal.basePrice + skinal.price * skinal.amount;
    }

    if (units.some(u => u.unit.unitKind === 'bottom')) {
      const WAPNid = 'WAPN400_accessory';
      const WAPN = this.model.accessories.find(a => getOptionId(a) === WAPNid);
      price += WAPN?.price ?? 0;

      const supportLegIds = ['sl45', 'sl60', 'sl90', 'sl100', 'sl120', 'sl140'];
      const supportLegNum = units.filter(u => supportLegIds.includes(getOptionId(u.unit))).length;
      price += supportLegNum ? (this.model.supportLegMounting.price * supportLegNum) : 0; 
    }

    return price;
  }

  @computed
  get mountedAdditionals(): IMountedAdditional[] {
    const units = this.model.units as IMountedUnit[];
    return units.flatMap(unit =>
      domainService.unitAdditionals(unit.unit)
        .filter(additional => !!additional)
        .map(additional => ({ unit, additional: additional as IAdditionalAppliance })));
  }

  @computed
  get worktop() {
    return this.model.worktop[0];

  }

  constructor(
    private rootStore: RootStore,
    public service: YamlDatasetService,
    private noTracking: boolean
  ) {
    console.info('Configurator version: ', ConfiguratorStore.version);
    this._engine = new Engine();
    this.schema = service.getProductSchema('', {}, '') as Schema;
    const searchParams = queryString(this.rootStore.location.search);
    if (searchParams['productId']) {
      this.product = service.products[searchParams.productId!] as Product;
    } else {
      this.product = service.products[1] as Product;
    }
    this._propertyValuesByPath = this.engine.propertyValuesByPath.bind(this.engine);
    this.loadModel();

    this.engine.notifications.subscribe<LinkedElementModelEvent>(LinkedElementModelEvent.EVENT_NAME, event => {
      let messageId;
      switch (event.type) {
        case LinkedElementModelEventType.ADD:
          messageId = 'ui.report.child_included';
          break;
        case LinkedElementModelEventType.REMOVE:
          messageId = 'ui.report.child_excluded';
          break;
        case LinkedElementModelEventType.REPLACE:
          messageId = 'ui.report.child_replaced';
          break;
        default:
          return;
      }
      this.rootStore.notification({
        content: {
          raw: this.rootStore.localization.formatMessage(
            messageId,
            { child: this.rootStore.localization.formatMessage(event.element.name) }
          )
        },
        type: NotificationType.INFO,
        modal: true,
      });
    });

    document.body.addEventListener('keydown', this.handleHotKey);
  }

  private handleHotKey = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Delete':
        e.preventDefault();
        if (this.selectedElement) {
          if (isElementUnit(this.selectedElement)) {
            this.deleteSelectedUnitElement();
          } else {
            this.deleteSkinal();
          }
        }
        break;
      case 'Escape':
        e.preventDefault();
        if (this.selectedElement) {
          this.handleEmptySpaceClick();
        }
        break;
      case 'ArrowLeft':
        e.preventDefault();
        if (this.canSelectedShiftToLeft()) {
          this.shiftSelectedToLeft();
        }
        break;

      case 'ArrowRight':
        e.preventDefault();

        if (this.canSelectedShiftToRight()) {
          this.shiftSelectedToRight();
        }
        break;
    }
  };

  @action.bound
  handleRenderClick() {
    this.showRender = true;
  }

  @action.bound
  handleRenderClose() {
    this.showRender = false;
  }

  async init() {
    if (!this.template) {
      this.template = await this.rootStore.fetchTemplate();
      if (!this.noTracking && !this.initActionSent) {
        this.rootStore.actionTracker.initConfigurator(this.serialize());
        this.initActionSent = true;
      }
    }
  }

  @action.bound
  configuratorLoaded() {
    this.loaded = true;
  }

  @action.bound
  toggleCameraView() {
    this.cameraView = this.cameraView === ActiveCamera.Front ? ActiveCamera.Perspective : ActiveCamera.Front;
  }

  @action.bound
  toggleSidebar(open?: boolean) {
    if (!open && this.deviceType === DeviceType.DESKTOP) {
      return;
    }
    this._sideBarOpened = open ?? !this.sideBarOpened;
  }

  @computed
  get sideBarOpened() {
    return this._sideBarOpened;
  }

  @action.bound
  setDeviceType(type: DeviceType) {
    this.deviceType = type;
    if (type === DeviceType.DESKTOP && !this._sideBarOpened) {
      this.toggleSidebar(true);
    } else if (type === DeviceType.HANDLET && this.currentView.name === ConfigurationViewStoreName.OVERVIEW) {
      this.toggleSidebar(false);
    }
  }

  @action.bound
  toggleRuler() {
    this.showRuler = !this.showRuler;
  }

  @action.bound
  async handleBeforeOpenReport() {
    await this.makeScreenshot();
    this.rootStore.actionTracker.openReport();
  }

  @action.bound
  async makeScreenshot() {
    this.pendingForScreenshot = true;
    //Reset scene before making screenshot
    this.navigateTo(ConfigurationViewStoreName.OVERVIEW);
    this.toggleSidebar(false);
    return new Promise((resolve: (url: string) => void) => {
      this.makeScreenshotResolver = resolve;
    });
  }

  @action.bound
  async backToConfiguration() {
    this.rootStore.actionTracker.backToConfiguration();
  }

  @action.bound
  private deselectSelectedElement() {
    const action = this.rootStore.actionTracker.deselectSelectedElement();
    this.applyDeselectAction(action);
  }

  @action.bound
  toggleUnit(uiId: UIId | null) {
    //Deselect
    if (!uiId || this.selectedUnitUiId === uiId) {
      const action = this.rootStore.actionTracker.unselectUnit(uiId);
      this.applyUnselectUnit(action);
    } else {
      const action = this.rootStore.actionTracker.selectUnit(uiId);
      this.applySelectUnitAction(action);
    }
  }

  applyDeselectAction(action: DeselectSelectedElementAction) {
    this.selectedElementType = SelectedElementType.NONE;
    this.selectedUnitUiId = null;
  }

  applySelectUnitAction(action: SelectUnitAction) {
    if (this.currentView.name !== ConfigurationViewStoreName.ELEMENT_VIEW) {
      this.currentView = new ElementViewStore(this.rootStore, this);
    }
    this.selectedElementType = SelectedElementType.Unit;
    this.selectedUnitUiId = action.uiId;
    this.toggleSidebar(true);
  }

  @action.bound
  handleSkinalClick() {
    if (this.selectedElementType === SelectedElementType.Skinal) {
      this.toggleUnit(null);
    } else {
      this.selectSkinal();
    }
  }

  @action.bound
  selectSkinal() {
    this.navigateToElementView();
    this.selectedUnitUiId = null;
    this.selectedElementType = SelectedElementType.Skinal;
    this.toggleSidebar(true);
  }

  @action.bound
  shareDirectLink() {
    const json = new KitchenSerializer(this.engine, this.model as IKitchen).serialize();
    return this.makeSharableLink(json);
  }

  @action.bound
  updateCanvasUrl(url: string, width: number, height: number) {
    this._canvasURL = url;
    this.canvasSize = { width, height };
    if (this.makeScreenshotResolver) {
      this.makeScreenshotResolver(url);
    }
  }

  /**
   * @todo improve link generation for features
   * @see FeatureContext
   */
  private makeSharableLink(json: SerializedKitchenLatest) {
    const gzipValue = this.getKitchenHash();
    const options = [];
    options.push({ name: 'v', value: ConfiguratorStore.version });
    options.push({ name: 'link', value: gzipValue });
    //
    let link = '?' +
      options.map(({ name, value }) => `${name}=${value}`)
        .join('&');
    const location = this.rootStore.location;
    return location.origin + (location.pathname !== '/' ? location.pathname : '') + link;
  }

  getKitchenHash() {
    const json = new KitchenSerializer(this.engine, this.model as IKitchen).serialize();
    const str = JSON.stringify(json);
    const gzipped = gzip.zip(str);
    const gzippedStr = gzipped.map(u => String.fromCharCode(u)).join('');
    const gzipValue = btoa(gzippedStr);
    return gzipValue;
  }

  private loadFromLink() {
    let params = queryString(window.location.search);
    let parentParams = queryString(this.rootStore.location.search);
    // Top-shelf location query parameter overrides iframe parameter
    const currentParams: ConfiguratorParams = parentParams.link ? parentParams : params;
    if (currentParams.link) {
      let json = {} as any;
      switch (currentParams.v) {
        case '2':
          // Gzipped url
          const data = Array.from(atob(currentParams.link)).map(u => u.charCodeAt(0));
          const unzipped = gzip.unzip(data);
          const unzippedString = unzipped.map(d => String.fromCharCode(d)).join('');
          json = JSON.parse(unzippedString);
          break;
        default:
          json = JSON.parse(atob(currentParams.link));
          break;
      }
      // const json = (JSON.parse(atob(link as any)));
      this.model = new KitchenSerializer(this.engine, this.model as PModel<IKitchen>).deserialize(json);
    } else {
      // Update calculation for default product
      const mutator = new UpdateCalculationsMutator();
      this.applyMutator(mutator);
    }
  }

  private static extendWithUiId(model: IKitchen) {
    // Inherit all Plugin extension methods
    const newModel: any = Object.assign({}, model);
    newModel.__proto__ = (model as any).__proto__;
    return newModel;
  }

  async applyMutator(mutator: ModelMutator) {
    const [newModel, validationResult] = this.engine.mutate(this.model, mutator);
    layoutService.normalizeKitchen(newModel as IKitchen); // Update unit positions to be ordered
    if (validationResult.isValid) {
      this.model = ConfiguratorStore.extendWithUiId(newModel as IKitchen);
      return true;
    } else {
      if (validationResult.errorMessage === 'ui.error.not_rigid_worktop') {
        throw new Error(validationResult.errorMessage);
      }
      await this.rootStore.notification({
        id: NotificationID.MUTATOR_ERROR,
        type: NotificationType.INFO,
        modal: true,
        content: {
          messageID: validationResult.errorMessage,
        }
      });
      return false;
    }
  }

  @computed
  get availableGroups() {
    return this.engine.propertyValuesByPath(this.model, 'units.unit', new IdentityStructuralTransformer(), ['upstand']) as unknown as OptionGroup[];
  }

  @action.bound
  handleAddItem() {
    this.navigateTo(ConfigurationViewStoreName.SELECT_CATEGORY);
    this.zoomOut();
  }

  @action.bound
  handleEmptySpaceClick() {
    this.zoomOut();
    if (this.selectedElement) {
      this.toggleUnit(null);
    } else if (this.currentView.name !== ConfigurationViewStoreName.OVERVIEW) {
      this.currentView = new OverviewViewStore(this.rootStore, this);
      this.toggleSidebar(false);
    }
  }

  @action.bound
  changeKitchenMaterial(setting: string, value: Option) {
    let mutator = new ChangeKitchenMaterialMutator(setting, value);
    this.applyMutator(mutator);
  }

  /** Navigation **/
  @action.bound
  navigateTo(viewName: ConfigurationViewStoreName) {
    if (!this.sideBarOpened) {
      this.toggleSidebar(true);
    }

    // @todo: view store should not be re-created if current view is the same. mobx won't like that.
    // @todo: maybe we should add a corresponding check

    switch (viewName) {
      case ConfigurationViewStoreName.OVERVIEW: {
        this.currentView = new OverviewViewStore(this.rootStore, this);
        this.deselectSelectedElement();
        break;
      }
      case ConfigurationViewStoreName.EDIT_OPTION: {
        this.currentView = new EditOptionViewStore(this.rootStore, this, KitchenOption.WORKTOP);
        break;
      }

      case ConfigurationViewStoreName.EDIT_WORKTOP: {
        this.currentView = new EditWorktopViewStore(this.rootStore, this);
        break;
      }

      case ConfigurationViewStoreName.SELECT_CATEGORY: {
        this.currentView = new SelectCategoryViewStore(this.rootStore, this);
        this.zoomOut();
        this.deselectSelectedElement();
        break;
      }
      case ConfigurationViewStoreName.SELECT_ELEMENT: {
        this.currentView = new SelectElementViewStore(this.rootStore, this, '');
        break;
      }
      case ConfigurationViewStoreName.SELECT_ADDITIONAL: {
        this.currentView = new SelectAdditionalViewStore(this.rootStore, this, 0);
        break;
      }
      case ConfigurationViewStoreName.ELEMENT_VIEW: {
        this.navigateToElementView(false);
        break;
      }
      default:
        assertUnreachable(viewName, 'Invalid ConfigurationViewStoreName');
    }
  }

  @action.bound
  navigateToSelectElement(category: string) {
    if (!this.sideBarOpened) {
      this.toggleSidebar(true);
    }

    this.currentView = new SelectElementViewStore(this.rootStore, this, category);
  }

  @action.bound
  navigateToElementView(expanded?: boolean) {
    if (this.currentView.name !== ConfigurationViewStoreName.ELEMENT_VIEW) {
      this.currentView = new ElementViewStore(this.rootStore, this, expanded);
    }
  }

  @action.bound
  navigateToSelectAdditional(slot: number) {
    if (!this.sideBarOpened) {
      this.toggleSidebar(true);
    }

    this.currentView = new SelectAdditionalViewStore(this.rootStore, this, slot);
  }

  @action.bound
  navigateToEditOption(property: KitchenOption, filterName: string = '') {
    if (!this.sideBarOpened) {
      this.toggleSidebar(true);
    }

    const store = new EditOptionViewStore(this.rootStore, this, property);
    if (filterName) {
      store.setJsFilter(filterName);
    }
    this.currentView = store;
  }

  @computed
  get isInPlacementMode() {
    return this.currentView instanceof SelectElementViewStore && this.currentView.state === SelectElementState.PLACEMENT;
  }

  canSelectedShiftToRight() {
    if (!this.shiftButtonVisible || !isElementUnit(this.selectedElement)) {
      return false;
    }
    const mutator = new ShiftUnitMutator(this.selectedElement, ShiftType.Right);
    const result = this.engine.mutate(this.model, mutator);
    return result[1].isValid;
  }

  canSelectedShiftToLeft() {
    if (!this.shiftButtonVisible || !isElementUnit(this.selectedElement)) {
      return false;
    }
    const mutator = new ShiftUnitMutator(this.selectedElement, ShiftType.Left);
    const result = this.engine.mutate(this.model, mutator);
    return result[1].isValid;
  }

  @action.bound
  shiftSelectedToLeft() {
    if (!isElementUnit(this.selectedElement)) {
      return;
    }
    const { uiId, unit } = this.selectedElement;
    this.rootStore.actionTracker.shiftUnit(uiId, unit, ShiftType.Left);
    const mutator = new ShiftUnitMutator(this.selectedElement, ShiftType.Left);
    this.applyMutator(mutator);
  }

  @action.bound
  shiftSelectedToRight() {
    if (!isElementUnit(this.selectedElement)) {
      return;
    }
    const { uiId, unit } = this.selectedElement;
    this.rootStore.actionTracker.shiftUnit(uiId, unit, ShiftType.Right);
    const mutator = new ShiftUnitMutator(this.selectedElement, ShiftType.Right);
    this.applyMutator(mutator);
  }

  entitiesByComponents: SearchEntitiesByComponents = (model, components) => this.engine.entitiesByComponents(model, components);

  getReportItems() {
    return this.entitiesByComponents(this.model, ['Cart']);
  }

  @action.bound
  async generateARLink() {
    return new ArService(this)
      .generateArLink();
  }

  @action.bound
  deleteSelectedUnitElement() {
    if (!isElementUnit(this.selectedElement)) {
      return;
    }
    const { uiId, position, unit } = this.selectedElement;
    this.deleteUnitElement(uiId, position, unit);
    this.rootStore.actionTracker.removeUnit(
      uiId,
      position,
      unit
    );
  }

  @action.bound
  async deleteUnitElement(uiId: UIId, position: number, unit: Unit) {
    let mutator = new DeleteUnitMutator({
      uiId: uiId,
      position: position,
      unit: unit
    });

    try {
      await this.applyMutator(mutator);
      this.toggleUnit(null);
      this.zoomed = false;
    } catch (e) {
      if (e.message === 'ui.error.not_rigid_worktop') {
        await this.rootStore.notification({
          id: NotificationID.MUTATOR_ERROR,
          type: NotificationType.INFO,
          modal: true,
          content: {
            messageID: 'ui.error.remove_support_leg',
          }
        });
      }
    }
  }

  deleteSkinal() {
    const { skinal } = this.model;
    if (skinal) {
      const mutator = new DeleteSkinalMutator();
      this.applyMutator(mutator);
      this.rootStore.actionTracker.removeSkinal(skinal);
    }
    this.navigateTo(ConfigurationViewStoreName.OVERVIEW);
  }

  @action.bound
  private loadModel() {
    this.model = this.engine.initByProduct(this.schema, this.product) as PModel<IKitchen>;
    this.loadFromLink();
    this.model = ConfiguratorStore.extendWithUiId(this.model as IKitchen);
  }

  isUnitPlaceholder(uiId: UIId) {
    const unit = this.model.units.find(u => u.uiId === uiId);
    return !!unit && placeholderGuard(unit.unit);
  }

  isUnitFittingStrip(uiId: UIId) {
    const unit = this.model.units.find(u => u.uiId === uiId);
    return !!unit && fittingStripGuard(unit.unit);
  }

  isUnitLeftUpstand(uiId: UIId) {
    const upstandCandidate = this.model.units.find(u => u.uiId === uiId);
    if (upstandCandidate && upstandGuard(upstandCandidate.unit)) {
      const prevUnitPosition = upstandCandidate.position - 1;
      const prevUnit = this.model.units.find(u =>
          u.position === prevUnitPosition
          && this.isCorrespondingUnitType(upstandCandidate.unit.unitKind, u.unit.unitKind));
      return !prevUnit;
    } else {
      return false;
    }
  }

  isUnitRightUpstand(uiId: UIId) {
    const upstandCandidate = this.model.units.find(u => u.uiId === uiId);
    if (upstandCandidate && upstandGuard(upstandCandidate.unit)) {
      const nextUnitPosition = upstandCandidate.position + 1;
      const nextUnit = this.model.units.find(u =>
          u.position === nextUnitPosition
          && this.isCorrespondingUnitType(upstandCandidate.unit.unitKind, u.unit.unitKind));
      return !nextUnit;
    } else {
      return false;
    }
  }

  private isCorrespondingUnitType(upstandKind: UnitKind, neighborUnitKind: UnitKind) {
    return layoutService.isTopUnit(upstandKind)
        ? layoutService.isNotBottomUnit(neighborUnitKind)
        : layoutService.isFloorUnit(neighborUnitKind)
  }

  zoomIn() {
    if (!this.zoomed) {
      const selectedElement = this.selectedElement;

      if (!selectedElement) {
        throw new Error('Attempt to zoom in while no element is selected');
      }

      let action;

      if (!skinalGuard(selectedElement)) {
        action = this.rootStore.actionTracker.zoomIn(selectedElement.unit, selectedElement.uiId);
      } else {
        action = this.rootStore.actionTracker.zoomIn(selectedElement);
      }

      this.applyZoomInAction(action);
    }
  }

  zoomOut() {
    if (this.zoomed) {
      const action = this.rootStore.actionTracker.zoomOut();
      this.applyZoomOutAction(action);
    }
  }

  @action.bound
  shareOn(target: ShareOn, imageUrl?: string, params?: ShareImageParams) {
    new ShareLinkService(this)
      .shareOn(target, imageUrl, params);
  }

  getUnitByOptionId(optionId: string): Unit | null {
    const options = this.engine.propertyValuesByPath(this.model, 'units.unit') as POption[];
    const candidates = engineUtils.flattenOptions(options);
    const option = candidates
      .find(u => u._id === optionId);

    return option?.model as Unit;
  }

  serialize(): IConfiguratorState {
    const serializer = new KitchenSerializer(this.engine, this.model);
    const model = serializer.serialize();
    return {
      model,
      selectedElementType: this.selectedElementType,
      selectedUnitUiId: this.selectedUnitUiId,
      zoomed: this.zoomed,
      deviceType: this.deviceType,
    };
  }

  deserialize(state: IConfiguratorState) {
    this.model = new KitchenSerializer(
      this.engine,
      this.model as PModel<IKitchen>)
      .deserialize(state.model);
    this.selectedElementType = state.selectedElementType;
    this.selectedUnitUiId = state.selectedUnitUiId;
    this.zoomed = state.zoomed;
  }

  getOptionById<T>(optionId: string) {
    return this.service.options[optionId] as Option<T>;
  }

  async applyAction(action: ConfiguratorAction) {
    switch (action.name) {
      case 'SelectUnitAction': {
        this.applySelectUnitAction(action);
        break;
      }
      case 'DeselectSelectedElement': {
        this.applyDeselectAction(action);
        break;
      }

      case 'UnselectUnit': {
        this.applyUnselectUnit(action);
        break;
      }
      case 'AddUnit': {
        if (this.currentView instanceof SelectElementViewStore) {
          this.currentView.applyInsertUnitAtAction(action);
        }
        break;
      }

      case 'ZoomIn': {
        this.applyZoomInAction(action);
        break;
      }

      case 'ZoomOut': {
        this.applyZoomOutAction(action);
        break;
      }

      case 'InitAction': {
        // this.deserialize(action.state.configuratorStore);
        break;
      }
    }
  }

  private applyUnselectUnit(action: UnselectUnitAction) {
    this.currentView = new OverviewViewStore(this.rootStore, this);
    this.deselectSelectedElement();
    this.toggleSidebar(false);
  }

  private applyZoomInAction(action: ZoomInAction) {
    this.zoomed = true;
  }

  private applyZoomOutAction(action: ZoomOutAction) {
    this.zoomed = false
  }

  getUnitOptionById(id: string) {
    return engineUtils.flattenOptions(this.engine.propertyValuesByPath(this.model, 'units.unit') as (POption | POptionGroup)[])
      .find(unit => unit._id === id) as Option<Unit>;
  }

  isUnitUpstand(id: UIId) {
    const unit = this.model.units.find(u => u.uiId === id);
    return !!unit && upstandGuard(unit.unit);
  }

  navigateToEditWorktop() {
    this.currentView = new EditWorktopViewStore(this.rootStore, this);
  }
}

export function isElementUnit(element: SelectedElement): element is IMountedUnit {
  return element !== null && typeof (element as IMountedUnit).position !== 'undefined';
}

export function isElementSkinal(element: SelectedElement): element is ISkinal {
  return element !== null && getTypeRef(element) === 'Skinal';
}
