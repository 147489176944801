import * as React from "react";
import { observer } from "mobx-react";

import { NotificationsStore } from "../../stores/NotificationsStore";
import Notification from "./Notification";

import './NotificationContainer.scss';

interface IProps {
  alertStore: NotificationsStore;
}

@observer
export default class NotificationContainer extends React.Component<IProps> {

  render() {
    const { alertStore } = this.props;
    const notification = alertStore.currentNotification;

    return (
      notification ?
        <div className="notification__wrapper">
          {notification.modal && <div className="notification__mask"/>}
          <Notification
            type={notification.type}
            content={notification.content}
            canBeMuted={notification.canBeMuted}
            onClose={alertStore.onClose}
          />
        </div>
      : null
    );
  }
}
