import { Option, OptionGroup, PModel, SinglePropertyMutator } from '@canvas-logic/engine';
import { action, computed, observable } from 'mobx';

import { ManageUnitOptionsMutator } from '../../mutators/ManageUnitOptionsMutator';
import { RemoveAdditionalMutator } from '../../mutators/RemoveAdditionalMutator';
import { ReplaceUnitMutator } from '../../mutators/ReplaceUnitMutator';
import { ConfigurationViewStoreName, ConfiguratorStore, DeviceType, isElementUnit, ViewStore } from '../ConfiguratorStore';
import { IAdditionalAppliance, IMountedUnit, ISkinal, OpeningSide, Unit } from '../../schema';
import { RootStore } from '../RootStore';
import { UnitMatcher } from '../../services/UnitMatcher';
import { NotificationID, NotificationType } from '../NotificationsStore';

export enum ElementViewState {
  COLLAPSED,
  EXPANDED
}

export class ElementViewStore implements ViewStore {
  name = ConfigurationViewStoreName.ELEMENT_VIEW;
  @observable viewState: ElementViewState;

  constructor(private rootStore: RootStore, private configuratorStore: ConfiguratorStore, initialExpanded?: boolean) {
    this.viewState = initialExpanded ? ElementViewState.EXPANDED : ElementViewState.COLLAPSED;
  }

  @computed get selectedElement() {
    return this.configuratorStore.selectedElement;
  }

  get propertyValuesByPath() {
    return this.configuratorStore.propertyValuesByPath;
  }

  get availableUnitGroups(): OptionGroup[] {
    return this.configuratorStore.availableGroups;
  }

  getRelatedUnitsFor(unit: Unit) {
    if (!unit.groupByWidth) {
      return [];
    }

    return this.availableUnitGroups
      .flatMap((u: any) => u.children)
      .map((u: Option<Unit>) => u.model)
      .filter((u: Unit) => u.groupByWidth === unit.groupByWidth);
  }

  getEmptyAdditionalForSelectedUnit(index: number) {
    const selectedElement = this.selectedElement;
    if (!isElementUnit(selectedElement)) {
      return '';
    }
    const unitIndex = this.configuratorStore.model.units.findIndex(unit => unit.position === selectedElement.position &&
      unit.unit.unitKind === selectedElement.unit.unitKind)
    const additionals = this.configuratorStore.engine.propertyValuesByPath(
      this.configuratorStore.model,
      `units.${unitIndex}.unit.additionals.${index}`) ?? [] as any[];
    if (additionals.length) {
      const result: IAdditionalAppliance = additionals[0].model as IAdditionalAppliance;
      return { ...result, description: '', name: '', itemImage: '' }
    }
  }

  @computed
  get shouldDrawMainContent() {
    return this.viewState === ElementViewState.EXPANDED || this.configuratorStore.deviceType === DeviceType.DESKTOP;
  }

  @action.bound
  handleExpandView() {
    this.toggleState(ElementViewState.EXPANDED);
  }

  @action.bound
  toggleState(newState: ElementViewState) {
    this.viewState = newState;
  }

  @action.bound
  handleBack() {
    if (this.viewState === ElementViewState.EXPANDED && this.configuratorStore.deviceType === DeviceType.HANDLET) {
      this.toggleState(ElementViewState.COLLAPSED);
    } else {
      this.configuratorStore.navigateTo(ConfigurationViewStoreName.OVERVIEW);
    }
  }

  @action.bound
  handleCancel() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.OVERVIEW);
    this.deselectElement();
  }

  @action.bound
  handleSelectAdditional(slot: number) {
    this.configuratorStore.navigateToSelectAdditional(slot);
  }

  @action.bound
  handleOpenSidebar() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.OVERVIEW);
  }

  @action.bound
  handleAddElement() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.SELECT_CATEGORY);
  }

  @action.bound
  deselectElement() {
    this.configuratorStore.toggleUnit(null);
    this.configuratorStore.toggleSidebar(false);
  }

  @action.bound
  deleteSelectedUnit() {
    this.configuratorStore.deleteSelectedUnitElement();
  }

  /**
   * Event tracking wrapper to ensure other replace operations won't be tracked as width change.
   *
   * @see replaceUnitWith
   */
  @action.bound
  replaceWithDifferentWidth(mountedUnit: IMountedUnit, unitToInsert: Unit) {
    const { uiId, unit } = mountedUnit;
    this.rootStore.actionTracker.changeWidth(uiId, unit, unitToInsert.width);

    this.replaceUnitWith(mountedUnit, unitToInsert);
  }

  @action.bound
  async replaceUnitWith(unit: IMountedUnit, unitToInsert: Unit) {
    const mutator = new ReplaceUnitMutator(unit, unitToInsert as PModel<Unit>);

    try {
      await this.configuratorStore.applyMutator(mutator);
      const newSelectedUnit = this.configuratorStore.model.units.find(newUnit => newUnit.position === unit.position && newUnit.unit.unitKind === unit.unit.unitKind);
      this.configuratorStore.toggleUnit(null);
      if (newSelectedUnit) {
        this.configuratorStore.toggleUnit(newSelectedUnit.uiId);
      }
    } catch (e) {
      switch (e.message) {
        case 'ui.error.not_rigid_worktop':
          await this.rootStore.notification({
            id: NotificationID.MUTATOR_ERROR,
            type: NotificationType.INFO,
            modal: true,
            content: {
              messageID: 'ui.error.incorrect_dimension',
            }
          });
          break;
        case 'ui.error.worktop_unreachable':
          await this.rootStore.notification({
            id: NotificationID.MUTATOR_ERROR,
            type: NotificationType.INFO,
            modal: true,
            content: {
              messageID: 'ui.error.worktop_unreachable',
            }
          });
          break;
      }
    }
  }

  @action.bound
  changeUnitHandleOpenSide(mountedUnit: IMountedUnit, mode: OpeningSide) {
    const { uiId, unit } = mountedUnit;
    this.rootStore.actionTracker.changeHandleOpeningSide(uiId, unit, mode);
    const mutator = new ManageUnitOptionsMutator(mountedUnit as any, 'openingSide', mode);
    this.configuratorStore.applyMutator(mutator);
  }

  @action.bound
  removeAdditional(additionalIndex: number) {
    const selectedElement = this.selectedElement;
    if (isElementUnit(selectedElement)) {
      this.trackRemoveAdditional(selectedElement, additionalIndex);

      const mutator = new RemoveAdditionalMutator(selectedElement, additionalIndex);
      this.configuratorStore.applyMutator(mutator);
    }
  }

  @computed
  get selectedOption() {
    return this.configuratorStore.model.skinal;
  }

  @computed
  get availableOptions() {
    return this.configuratorStore.propertyValuesByPath(this.configuratorStore.model, 'skinal.plate') as Option[] | OptionGroup[];
  }

  getUnitMaterials() {
    return this.configuratorStore.propertyValuesByPath(this.selectedElement as any, `unit.material.value`) as Option[] | OptionGroup[];
  }

  @action.bound
  changeKitchenMaterial(option: Option) {
    this.rootStore.actionTracker.changeSkinalMaterial((option as Option<ISkinal>).model);
    this.configuratorStore.changeKitchenMaterial('skinal.plate', option)
  }

  @action.bound
  changeUnitMaterial(value: Option) {
    if (this.selectedElement && isElementUnit(this.selectedElement)) {
      const element = this.selectedElement;
      const idx = this.configuratorStore.model.units.findIndex(u => u.uiId === element.uiId);
      let mutator = new SinglePropertyMutator(`units.${idx}.unit.material.value`, value);
      this.configuratorStore.applyMutator(mutator);
    }
  }

  @action.bound
  deleteSkinal() {
    this.configuratorStore.deleteSkinal();
  }

  private trackRemoveAdditional(mountedUnit: IMountedUnit, additionalIndex: number) {
    new UnitMatcher(mountedUnit.unit)
      .withAdditionals()
      .run(unit => {
        const additional = unit.additionals[additionalIndex];
        if (additional) {
          this.rootStore.actionTracker.removeAdditional(mountedUnit.uiId, additional);
        }
      });
  }
}
