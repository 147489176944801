import { queryValue } from './ModelWalker';
import { flatMap } from '../helpers/utils';
import { UnitMatcher } from './UnitMatcher';
import { IDynamicVariantId, IKitchen, Unit } from '../schema';

export default class DomainService {
  allUnitsAdditionals(model: IKitchen) {
    return flatMap(model.units, unit => {
      return new UnitMatcher(unit.unit)
        .withAdditionals()
        .map(cabinet => {
            return cabinet.additionals.filter(additional => !!additional)
          },
          () => [])
    });
  }
  unitAdditionals(unit: Unit) {
   return new UnitMatcher(unit)
     .withAdditionals()
     .map(u => u.additionals, () => []);
  }
  getVariantId(model: IKitchen, variantId: IDynamicVariantId | undefined, carrier: any) {
    let result = '';
    if (variantId) {
      const path = variantId.fieldName;
      const fieldValue = queryValue(model, carrier, path);
      if (fieldValue) {
        const pair = variantId.values.find(keyValue => keyValue.key === fieldValue);
        if (pair) {
          result = pair.value;
        } else {
          console.warn(`[VaraintId]: ${fieldValue} value not found in `, variantId);
        }
      } else {
        console.warn(`[VariantId]: ${path} not found in `, carrier);
      }
    }
    return result;
  }
}

export const domainService = new DomainService();