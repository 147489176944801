import { getTypeRef, IEntitySearchResult, Model } from '@canvas-logic/engine';
import {
  IAdditionalAppliance,
  ICart,
  IComposite,
  IDimension,
  IDynamicVariantId,
  IFittingStrip,
  IHoodUnit,
  IMountedUnit,
  INameImage,
  IPrice,
  ISkinal,
  IStaticVariantId,
  IUnitElement,
  IUnitPlaceholder,
  IUpstand,
  IWorktop,
  VariantId,
} from '../schema';

export type BoundaryPosition = 'left' | 'right';

export type UnitInsertPosition = BoundaryPosition | AfterPosition;

export class InsertUnit {
  static Left(): BoundaryPosition {
    return 'left'
  }

  static Right(): BoundaryPosition {
    return 'right'
  }

  static After(position: number): AfterPosition {
    return new AfterPosition(position)
  }
}

export class AfterPosition {
  constructor(public after: number) {
  }
}

export type UIId = number;

export const EMPTY_UIID = 0;

export interface IMountedAdditional {
  unit: IMountedUnit;
  additional: IAdditionalAppliance;
}

export function unitElementGuard(unit: any): unit is IUnitElement {
  return getTypeRef(unit) === 'UnitElement';
}
export function hoodUnitGuard(unit: any): unit is IHoodUnit {
  return getTypeRef(unit) === 'HoodUnit';
}
export function upstandGuard(unit: any): unit is IUpstand {
  return getTypeRef(unit) === 'Upstand';
}
export function supportLegGuard(unit: any): unit is IUnitElement {
  return unitElementGuard(unit) && unit.groupByWidth === 'SL';
}
export function placeholderGuard(unit: any): unit is IUnitPlaceholder {
  return getTypeRef(unit) === 'UnitPlaceholder';
}

export function fittingStripGuard(unit: any): unit is IFittingStrip {
  return getTypeRef(unit) === 'FittingStrip';
}
// or IPlate?
export function skinalGuard(item: any): item is ISkinal {
  return getTypeRef(item) === 'Skinal';
}

export function worktopGuard(item: any): item is IWorktop {
  return getTypeRef(item) === 'Worktop';
}

export function additionalGuard(unit: any): unit is IAdditionalAppliance {
  return getTypeRef(unit) === 'AdditionalAppliance';
}

export function nameImageGuard(item: any): item is INameImage {
  return getTypeRef(item) === 'NameImage';
}

export function compositeGuard(item: any): item is IComposite {
  return getTypeRef(item) === 'Composite';
}

export function staticVariantIdGuard(variantId: VariantId): variantId is IStaticVariantId {
  return getTypeRef(variantId) === 'StaticVariantId';
}
export function dynamicVariantIdGuard(variantId: IDynamicVariantId): variantId is IDynamicVariantId {
  return getTypeRef(variantId) === 'DynamicVariantId';
}
export type Components = {
  Cart: ICart,
  Dimension: IDimension,
  Price: IPrice
}
export type SearchEntitiesByComponents =
  <T extends keyof Components>(model: Model, components: T[]) => IEntitySearchResult<UnionToIntersection<Components[T]>>[];

type UnionToIntersection<U> =
  (U extends any ? (k: U) => void : never) extends ((k: infer I)=>void) ? I: never

export enum ShareOn {
  FB
}

export type ShareImageParams = {
  width: number;
  height: number;
}

export type MountingOption = {
  name: string;
  reportCategory: string;
  variantId: string;
  EAN: string;
  price: number;
}
