import { MutatorContext, PModel, ValidationResult } from '@canvas-logic/engine';
import { collectInformation } from './utils';
import { IKitchen, IMountedUnit } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';

export class DeleteUnitMutator extends KitchenBaseMutator {
  constructor(private selectedNobiliaUnit: IMountedUnit) {
    super();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, model: IKitchen): [PModel<IKitchen>, ValidationResult] {
    let units = model.units;
    if (this.selectedNobiliaUnit.unit.unitKind === 'bottom') {
      let info = collectInformation(units);
      if (info.tallLastWidth > 0 && info.bottomWidth - this.selectedNobiliaUnit.unit.width < info.topWidth) {
        return [model, ValidationResult.Error('ui.error.remove_top_intersection')];
      }
    }

    for (let i = 0; i < units.length; i++) {
      let unit = units[i];
      if (unit.unit.unitKind === this.selectedNobiliaUnit.unit.unitKind && unit.position === this.selectedNobiliaUnit.position) {
        units.splice(i, 1);
        break;
      }
    }
    units.filter(u => (this.selectedNobiliaUnit.unit.unitKind === 'top' ? u.unit.unitKind === 'top' : u.unit.unitKind !== 'top') && u.position > this.selectedNobiliaUnit.position).forEach(
      u => {
        u.position--;
      }
    );
    return [model, ValidationResult.Valid()];
  }
}
