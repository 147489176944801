export function queryString(search: string) {
    if (search[0] == '?') {
        search = search.slice(1);
    }

    return search.split('&')
        .map(
            pair => pair.split('='))
        .reduce((acc, next) => {
            acc[next[0]] = next[1];
            return acc;
        }, {} as any)
}