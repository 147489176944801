import { MutatorContext, Option, PModel, SinglePropertyMutator, ValidationResult } from '@canvas-logic/engine';
import { KitchenBaseMutator } from './KitchenBaseMutator';
import { IKitchen } from '../schema';

export class ChangeKitchenMaterialMutator extends KitchenBaseMutator {
  constructor(private setting: string, private value: Option | Option[]) {
    super();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, model: PModel<IKitchen>): [PModel<IKitchen>, ValidationResult] {
    const mutator = new SinglePropertyMutator(this.setting, this.value);
    return mutator.mutate(context, typeRef, model) as [PModel<IKitchen>, ValidationResult];
  }
}
