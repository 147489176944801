import { Option } from '@canvas-logic/engine';
import { action, computed, observable } from 'mobx';
import { ChangeUnitAdditionalMutator } from '../../mutators/ChangeUnitAdditionalMutator';
import { ConfigurationViewStoreName, ConfiguratorStore, DeviceType, ViewStore } from '../ConfiguratorStore';
import { RootStore } from '../RootStore';
import { domainService } from '../../services/DomainService';
import { IAdditionalAppliance, IMountedUnit } from '../../schema';
import { UnitMatcher } from '../../services/UnitMatcher';

export class SelectAdditionalViewStore implements ViewStore {
  name = ConfigurationViewStoreName.SELECT_ADDITIONAL;
  @observable readonly additionalSlot: number;

  constructor(
    private rootStore: RootStore,
    private configuratorStore: ConfiguratorStore,
    additionalSlot: number) {
    this.additionalSlot = additionalSlot;
  }

  @action.bound
  deselectElement() {
    this.configuratorStore.toggleUnit(null);
    this.configuratorStore.toggleSidebar(false);
  }

  @action.bound
  handleBack() {
    this.configuratorStore.navigateToElementView(true);
  }

  @action.bound
  handleCancel() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.OVERVIEW);
    this.deselectElement();
  }

  @action.bound
  handleAddComponent() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.SELECT_CATEGORY);
  }

  @computed get selectedElement(): IMountedUnit {
    return this.configuratorStore.selectedElement as IMountedUnit;
  }

  @computed get selectedAdditional() {
    return domainService.unitAdditionals(this.selectedElement.unit)[this.additionalSlot];
  }

  getAdditionals(): Option<IAdditionalAppliance>[] {
    // TODO: Change IUnit to PModel<IUnit> in UI
    const result = new UnitMatcher(this.selectedElement.unit)
      .withAdditionals()
      .map(u => {
        return this.configuratorStore.engine.propertyValuesByPath(u, `additionals.${this.additionalSlot}`);
      }, () => []);
    return result as any;
  }

  canChangeAdditionTo(additional: IAdditionalAppliance) {
    const mutator = new ChangeUnitAdditionalMutator(this.selectedElement, this.additionalSlot, additional);
    const [newModel, validationResult] = this.configuratorStore.engine.mutate(this.configuratorStore.model, mutator);
    return validationResult;
  }

  @action.bound
  changeAdditional(additional: Option<IAdditionalAppliance>) {
    if (!this.selectedAdditional) {
      this.rootStore.actionTracker.addAdditional(this.selectedElement.uiId, additional.model);
    } else {
      this.rootStore.actionTracker.replaceAdditional(this.selectedElement.uiId, additional.model);
    }

    const mutator = new ChangeUnitAdditionalMutator(this.selectedElement, this.additionalSlot, additional.model, false);
    this.configuratorStore.applyMutator(mutator);
    if (this.configuratorStore.deviceType === DeviceType.HANDLET) {
      this.configuratorStore.navigateToElementView();
    }
  }
}
