import React from 'react';
import classNames from 'classnames';

import './Icon.scss';

import {ReactComponent as elements} from './icons/icon-elements.svg';
import {ReactComponent as settings} from './icons/icon-settings.svg';
import {ReactComponent as leftArrow} from './icons/icon-left-arrow.svg';
import {ReactComponent as rightArrowSmall} from './icons/icon-right-arrow--small.svg';
import {ReactComponent as leftArrowSmall} from './icons/icon-left-arrow--small.svg';
import {ReactComponent as deopDown} from './icons/icon-deop-down.svg';
import {ReactComponent as close} from './icons/icon-close.svg';
import {ReactComponent as cart} from './icons/icon-cart.svg';
import {ReactComponent as ar} from './icons/icon-ar.svg';
import {ReactComponent as camera} from './icons/icon-camera.svg';
import {ReactComponent as rulerOff} from './icons/icon-ruler-off.svg';
import {ReactComponent as rulerOn} from './icons/icon-ruler-on.svg';
import {ReactComponent as ruler} from './icons/icon-ruler.svg';
import {ReactComponent as share} from './icons/icon-share.svg';
import {ReactComponent as pdf} from './icons/icon-pdf.svg';
import {ReactComponent as print} from './icons/icon-print.svg';
import {ReactComponent as trash} from './icons/icon24-trash.svg';
import {ReactComponent as menu} from './icons/icon-menu.svg';
import {ReactComponent as plus} from './icons/icon-plus.svg';
import {ReactComponent as plus_24} from './icons/icon-plus_24.svg';
import {ReactComponent as opening_right} from './icons/icon-opening-right.svg';
import {ReactComponent as opening_left} from './icons/icon-opening-left.svg';
import {ReactComponent as more} from './icons/icon-more.svg';
import {ReactComponent as lang_de} from './icons/icon-lang-de.svg';
import {ReactComponent as lang_en} from './icons/icon-lang-en.svg';
import {ReactComponent as lang_fr} from './icons/icon-lang-fr.svg';
import {ReactComponent as lang_nl} from './icons/icon-lang-nl.svg';

const icons = {
  elements,
  settings,
  leftArrow,
  rightArrowSmall,
  leftArrowSmall,
  deopDown,
  close,
  cart,
  ar,
  camera,
  rulerOff,
  rulerOn,
  ruler,
  share,
  pdf,
  print,
  trash,
  menu,
  plus,
  plus_24,
  opening_right,
  opening_left,
  more,
  lang_de,
  lang_en,
  lang_fr,
  lang_nl
};

export type IconName = keyof typeof icons;

interface Props {
  type?: IconName;
  className?: string;
  onClick?: () => void;
}

const Icon = ({ type, className, ...props }: Props) => {
  const SVGIcon = type ? icons[type] : null;

  return (
    SVGIcon && (
      <i className={classNames('icon', className)} {...props}>
        <SVGIcon className="svg"/>
      </i>
    )
  );
};

export default Icon;
