import React from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';

import './Toggle.scss';

interface IToggleProps {
  className?: string;
  checked?: boolean;
  color?: 'default' | 'gray';
  onChange?: () => void;
}

const Toggle: React.FC<IToggleProps> = ({ className, checked, color, onChange }) => {
  const id = React.useMemo(() => v4(), [v4]);

  return (
    <div className={classNames('toggle', className, {
      'toggle--gray': color === 'gray'
    })}>
      <input
        id={id}
        type="checkbox"
        className="toggle__input"
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="toggle__label"/>
    </div>
  );
};

export default Toggle;
