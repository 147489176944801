import { action, computed } from 'mobx';
import { FlatStructuralTransformer, Option, OptionGroup } from '@canvas-logic/engine';
import { assertUnreachable } from '../../helpers/utils';
import { ConfigurationViewStoreName, ConfiguratorStore, ViewStore } from '../ConfiguratorStore';
import { RootStore } from '../RootStore';
import { INameImage, ISkinal, IWorktop } from '../../schema';

export enum KitchenOption {
  WORKTOP = 'worktop',
  SKINAL  = 'skinal',
  UPSTAND = 'upstand'
}

export class EditOptionViewStore implements ViewStore {
  name = ConfigurationViewStoreName.EDIT_OPTION;
  private jsFilterName: string;

  constructor(private rootStore: RootStore,
              private configuratorStore: ConfiguratorStore,
              private readonly _property: KitchenOption) {

  }

  get editingProperty() {
    return this._property;
  }

  @computed
  get selectedOption() {
    switch (this.editingProperty) {
      case KitchenOption.SKINAL:
        return this.configuratorStore.model.skinal;
      case KitchenOption.WORKTOP:
        return this.configuratorStore.model.worktop;
      case KitchenOption.UPSTAND:
        return this.configuratorStore.model.upstand;
      default:
        return assertUnreachable(this.editingProperty, 'Invalid KitchenOption');
    }
  }

  @computed
  get availableOptions() {
    if (this.jsFilterName) {
      return this.configuratorStore.engine.propertyValuesByPath(this.configuratorStore.model, this.editingProperty, new FlatStructuralTransformer(), [this.jsFilterName]);
    } else {
      return this.configuratorStore.propertyValuesByPath(this.configuratorStore.model, this.editingProperty) as Option[] | OptionGroup[];
    }
  }

  @action.bound
  changeKitchenMaterial(option: Option) {
    /**
     * @todo consider rewrite along with ElementViewStore::changeKitchenMaterial
     */
    switch (this.editingProperty) {
      case KitchenOption.SKINAL:
        this.rootStore.actionTracker.changeSkinalMaterial((option as Option<ISkinal>).model);
        break;
      case KitchenOption.WORKTOP:
        this.rootStore.actionTracker.changeWorktopMaterial((option as Option<IWorktop>).model);
        break;
      case KitchenOption.UPSTAND:
        this.rootStore.actionTracker.changeUpstandMaterial((option as Option<INameImage>).model);
        break;
      default:
        return assertUnreachable(this.editingProperty, 'Invalid KitchenOption');
    }
    this.configuratorStore.changeKitchenMaterial(this.editingProperty, option)
  }

  @action.bound
  handleBack() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.OVERVIEW);
  }

  @action.bound
  handleAddComponent() {
    this.configuratorStore.navigateTo(ConfigurationViewStoreName.SELECT_CATEGORY);
  }

  setJsFilter(filterName: string) {
    this.jsFilterName = filterName;
  }
}
