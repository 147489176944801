import * as React from 'react';
import { Component } from 'react';
import './LoadingMask.scss';

export class LoadingMask extends Component {
  render() {
    return <div className="loading-mask-container">
      <div className="loading-mask-wrapper">
        <div className="block-wrapper first-block">
          <div className="block"/>
        </div>
        <div className="block-wrapper second-block">
          <div className="block"/>
        </div>
        <div className="block-wrapper third-block">
          <div className="block"/>
        </div>
        <div className="block-wrapper fourth-block">
          <div className="block"/>
        </div>
      </div>
    </div>
  }
}
