export class Converter {
  /***
   * Converts millimeters to meters
   * @param mm
   */
  static mmToMeters(mm: number) {
    return mm / 1000;
  }

  static metresToMm(m: number) {
    return Math.round(m * 1000);
  }

  static metresToMm2Digit(m: number) {
    return Math.round((Math.floor(m * 100) / 100) * 1000);
  }

  static mmToCm(mm: number) {
    return Math.round(mm / 10);
  }

}
