import { IActionRecord } from './trackers/ActionTracker';

export class ReduxDevToolsBase {
  constructor() {
  }

  init(state: object) {
  }

  send(action: IActionRecord, state: object) {
  }

  subscribe(callback: (message: any) => void) {
  }
}

class ReduxDevTools implements ReduxDevToolsBase {
  private devTools: any;

  constructor() {
    this.devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__.connect({
      name: 'Configurator'
    });

  }

  init(state: object): void {
    this.devTools.init(state);
  }

  send(action: IActionRecord, state: object): void {
    const actionToSend = { type: action.action.name, payload: action };
    this.devTools.send(actionToSend, state);
  }

  subscribe(callback: (message: any) => void) {
    this.devTools.subscribe(callback);
  }
}

export class ReduxDevToolsFactory {
  static create() {
    return (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? new ReduxDevTools()
      : new ReduxDevToolsBase();
  }
}
