"use strict";

var _classCallCheck = require("/home/felger/projects/canvaslogic/canvas-logic-model/apps/nobilia/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var OclValidationError = function OclValidationError(message) {
  _classCallCheck(this, OclValidationError);

  this.message = message;
};

exports.OclValidationError = OclValidationError;