import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import SmallButton from '../../../../src/components/buttons/SmallButton/SmallButton';
import Icon from '../../../../src/components/Icon/Icon';
import RelatedProductItem from '../../../components/RelatedProductItem/RelatedProductItem';
import { SelectAdditionalViewStore } from '../../../stores/ConfiguratorViews/SelectAdditionalViewStore';

import './SelectAdditional.scss';
import Button from "../../../components/buttons/Button/Button";
import {getOptionId} from "@canvas-logic/engine";

export interface IProps {
  store: SelectAdditionalViewStore;
}

@observer
export default class SelectAdditional extends React.Component<IProps> {
  render() {
    const { store } = this.props;
    const selected = store.selectedAdditional;
    const selectedOptionId = getOptionId(selected);

    return (
      <div className="select-additional">
        <div className="select-additional__panel">
          <SmallButton
            className="select-additional__panel__button"
            color="transparent"
            onClick={store.handleBack}
          >
            <Icon type='leftArrow' />
          </SmallButton>
          <FormattedMessage id={store.selectedElement.unit.name} />
          <SmallButton
            className="select-additional__panel__button"
            color="transparent"
            onClick={store.handleCancel}
          >
            <Icon type='close' />
          </SmallButton>
        </div>
        <ul className="select-additional__container">
          {
            store.getAdditionals().map((additional) => {
              const validationResult = store.canChangeAdditionTo(additional.model);
              const isSelected = !!selected && selectedOptionId === additional._id;
              const onSelect = () => !isSelected && !validationResult.isInvalid && store.changeAdditional(additional);
              return (
                <div key={additional._id}>
                  <RelatedProductItem
                    item={additional.model}
                    selected={isSelected}
                    className={validationResult.isInvalid ? 'related-products-card__error' : ''}
                    onSelect={onSelect}/>
                </div>
              );
            })
          }
        </ul>
        <div className="select-additional__panel--bottom">
          <Button className="select-additional__add-button" onClick={store.handleAddComponent}>
            <Icon type='plus_24' />
            <FormattedMessage id="ui.elements.add" />
          </Button>
        </div>
      </div>
    );
  }
}
