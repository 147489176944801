import React from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import { OpeningSide } from '../../schema';
import Icon from '../Icon/Icon';

import './SelectorHandle.scss';

interface ISelectorHandleProps {
  openOptions: OpeningSide[];
  selectedMode: OpeningSide;
  onChange: (mode: OpeningSide) => void;
}

export default class SelectorHandle extends React.Component<ISelectorHandleProps> {

  render() {
    const {openOptions, selectedMode} = this.props;
    return (
      <div className="selector-handle">
        <FormattedMessage id="ui.elements.open_side.label" />
        <div className="selector-handle__icons">
          {openOptions.map(item =>
            <div className={classNames('selector-handle__label', {'checked': item === selectedMode})}
                 key={`side_${item}`} onClick={this.clickHandler(item)}>
              <Icon type={SelectorHandle.defineIcon(item)}/>
              <FormattedMessage id={`ui.elements.open_side.${item.toString().toLowerCase()}`}/>
            </div>
          )}
        </div>
      </div>
    );
  }

  clickHandler = (mode: OpeningSide) => () => this.props.onChange(mode);

  static defineIcon(side: OpeningSide) {
    if (side === OpeningSide.L) {
      return 'opening_left';
    } else {
      return 'opening_right';
    }
  }
}
