import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Button from '../buttons/Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { NotificationContent, NotificationType } from "../../stores/NotificationsStore";
import Dialog, { DialogContent, DialogControls } from '../Dialog/Dialog';

import './Notification.scss';

interface IProps {
  type: NotificationType;
  content: NotificationContent;
  onClose?: (doNotShowAgain: boolean) => void;
  canBeMuted?: boolean;
}

const Notification: React.FC<IProps> = ({ type, content, onClose, canBeMuted }) => {
  const [doNotShowAgain, setDontShowAgain] = React.useState(false);

  const handleOkClick = React.useCallback(() => {
    if (onClose) {
      onClose(doNotShowAgain);
    }
    setDontShowAgain(false);
  }, [onClose, doNotShowAgain]);

  return (
    <Dialog className={classNames("notification-dialog", {
      'notification-dialog--error': type === NotificationType.ERROR,
    })}>
      <DialogContent>
        {content.messageID &&
          <FormattedHTMLMessage
            id={content.messageID}
            tagName="p"
          />
        }
        {content.raw &&
          <p>
            {content.raw}
          </p>
        }
      </DialogContent>
      {canBeMuted &&
      <Checkbox
          label={<FormattedMessage id="ui.notifications.mute"/>}
          checked={doNotShowAgain}
          onChange={setDontShowAgain}
      />
      }
      <DialogControls>
        <Button className="button_white" onClick={handleOkClick}>
          <FormattedMessage id="ui.ok"/>
        </Button>
      </DialogControls>
    </Dialog>
  );
};

export default Notification;
