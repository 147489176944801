import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ConfiguratorStore } from '../../stores/ConfiguratorStore';

import { hoodUnitGuard, UIId } from '../../model/Model';

import ItemCard from '../ItemCard/ItemCard';
import Icon from '../Icon/Icon';

import SkinalItem from '../SkinalItem/SkinalItem';
import { getModelValue } from '../../services/ModelWalker';

import './ElementsList.scss';
import { IMountedUnit, ISkinal } from '../../schema';

export interface IProps {
  store: ConfiguratorStore;
  className?: string;
}

@observer
export class ElementsList extends React.Component<IProps> {
  @observable selectedGroup: string | null = null;

  render() {
    return this.renderViewMode;
  }

  @computed
  get renderViewMode() {
    const { store, className } = this.props;
    const { model } = store;
    const units = model.units
      .slice()
      .filter(unit => !hoodUnitGuard(unit.unit))
      .sort((a, b) => b.uiId - a.uiId) || [];
    const skinal = getModelValue(model, 'skinal') as ISkinal;
    const select = (uiId: UIId) => () => store.toggleUnit(uiId);
    const selectSkinal = () => store.selectSkinal();
    return (
      <div className={classNames('elements', className)}>
        <h2 className="elements__heading">
          <Icon type="elements"/><FormattedMessage id="ui.elements.header"/>
        </h2>
        <ul className="elements__list">
          {
            units.map((mountedUnit: IMountedUnit) => (
              <li className='elements__item' key={mountedUnit.uiId}>
                <ItemCard item={mountedUnit.unit} onClick={select(mountedUnit.uiId)}/>
              </li>
            ))
          }
          {
            !!skinal &&
            <li className='elements__item'><SkinalItem skinal={skinal} onClick={selectSkinal}/></li>
          }
        </ul>
      </div>
    );
  }
}
