import React, { Component } from 'react';

import Pill, { PillAlignment } from '../../../../components/Pill/Pill';
import { enumKeys } from '../../../../helpers/utils';

import './LocaleSelector.scss';
import PillList from '../../../../components/PillList/PillList';
import { Locale } from '../../../../stores/Localization';


interface IProps {
  value: Locale;

  onChange: (value: Locale) => void;
}

export default class LocaleSelector extends Component<IProps> {

  render() {
    const { value, onChange } = this.props;

    const availableValues = enumKeys(Locale);
    return (
      <div className="locale-selector">
        <PillList className="locale-selector__pill-list">{availableValues.map((availableValue, index) =>
          <li key={index}>
            <Pill
              className="locale-selector__pill"
              active={Locale[availableValue] === value}
              alignment={PillAlignment.Center}
              onClick={() => onChange(Locale[availableValue])}
            >
              {availableValue.toUpperCase()}
            </Pill>
          </li>)}
        </PillList>
      </div>
    );
  }
}
