import React from 'react';
import classNames from 'classnames';

import Icon, { IconName } from '../../Icon/Icon';

import './IconButton.scss';

interface IIconButton {
  className?: string;
  color?: 'white' | 'primary';
  size?: 'small' | 'normal';
  icon: IconName;
  altIcon?: IconName;
  showAltIcon?: boolean;
  onClick?: () => void;
}

class IconButton extends React.Component<IIconButton> {

  render() {
    const { icon, className, color, size, onClick, altIcon, showAltIcon } = this.props;
    return (
      <button
        className={classNames('button-round', className, {
          'button-round--small': size === 'small',
          'button-round--primary': color === 'primary',
          'button-round--active': showAltIcon
        })}
        onClick={onClick}
      >
        <Icon className="button-round__icon button-round__icon--basic" type={showAltIcon ? altIcon : icon} />
      </button>
    );
  }
}

export default IconButton;
