import { action, observable } from 'mobx';
import { getOptionId, IEntitySearchResult } from '@canvas-logic/engine';

import RootStore from './RootStore';
import { ConfiguratorStore } from './ConfiguratorStore';
import PRRenderService from '../features/PRRender/PRRenderService'

import { ChangeQuantity, ICart, OpeningSide, ReportCalculation, ReportCategory } from '../schema';
import { IReportItem } from '../model/ReportItem';
import { ServerViewModel } from '../model/ViewModel';
import { MountingOption, staticVariantIdGuard } from '../model/Model'
import { domainService } from '../services/DomainService'
import { StructuralTypeMatcher } from '../services/StructuralTypeMatcher'
import { NotificationType } from './NotificationsStore';

export class ReportStore {
  private static readonly DIALOG_SILENCE_KEY = 'report_dialog_silenced';
  kitchen: ConfiguratorStore;
  renderService: PRRenderService;
  private cartItemsById: Map<string, IReportItem>;
  private cartItemsByVariantId: Map<string, IReportItem>;
  private changedVariantIds = new Set<string>();
  private hasChanges = false;
  readonly mountingOptions: MountingOption[] = [
    {
      name: 'only_mounting',
      reportCategory: 'mounting_service',
      variantId: '34724902437014',
      EAN: '34724902437014',
      price: 690
    },
    {
      name: 'mounting_dismantling',
      reportCategory: 'mounting_service',
      variantId: '34724902469782',
      EAN: '0670036574044',
      price: 950
    }
  ];

  @observable reportGroups: Array<{ name: string, items: IReportItem[] }> = [];
  @observable imageUrl = '';
  @observable dialogVisible = false;
  @observable doNotShowDialogAgain = false;
  @observable selectedMountingVariantId: string | null = null;
  @observable equipment: IReportItem[] = [];
  @observable accessories: IReportItem[] = [];

  constructor() {
    this.kitchen = RootStore.configurator;

    if (!this.kitchen.template) {
      this.kitchen.init();
    }

    const reportItems = this.kitchen.getReportItems()
    this.reportGroups = this.createReportGroups(reportItems);
    this.renderService = new PRRenderService();
    this.renderKitchen();
  }

  private createReportGroups(entities: IEntitySearchResult<ICart>[]) {
    const map = new Map<string, IReportItem[]>();
    this.cartItemsById = new Map<string, IReportItem>();
    this.cartItemsByVariantId = new Map<string, IReportItem>();
    entities.forEach(e => {
      if (e.entity.amount <= 0) {
        return;
      }

      let reportItems: IReportItem[] = [];

      const key = e.entity.reportCategory;
      if (map.has(key)) {
        reportItems = map.get(key)!;
      }
      const reportItem = this.createReportItem(e.entity);
      this.addReportItem(reportItems, reportItem);
      map.set(key, reportItems);
      this.cartItemsById.set(getOptionId(e.entity), reportItem);
      this.cartItemsByVariantId.set(reportItem.calculatedVariantId, reportItem);
    });

    const reportCategoryKeys = Object.keys(ReportCategory);

    return Array.from(map.entries())
      .map(([name, values]) => ({
        name,
        items: values.sort((first, second) => {
          if (first.item.changeQuantity === second.item.changeQuantity) {
            return 0;
          } else if (first.item.changeQuantity === ChangeQuantity.yes) {
            return 1;
          } else {
            return -1;
          }
        })
      }))
      .sort((first, second) => reportCategoryKeys.indexOf(first.name) > reportCategoryKeys.indexOf(second.name) ? 1 : -1);
  }

  private createReportItem(entity: ICart): IReportItem {
    let calculatedVariantId: string;
    if (staticVariantIdGuard(entity.variantId)) {
      calculatedVariantId = entity.variantId.value;
    } else {
      calculatedVariantId = domainService.getVariantId(this.kitchen.model, entity.variantId, entity);
    }

    let calculatedEAN: string;
    if (staticVariantIdGuard(entity.EAN)) {
      calculatedEAN = entity.EAN.value;
    } else {
      calculatedEAN = domainService.getVariantId(this.kitchen.model, entity.EAN, entity);
    }

    let openingSide: OpeningSide | undefined = undefined;
    new StructuralTypeMatcher(entity)
      .withOpeningSide()
      .run(u => {
        openingSide = u.showOpeningSide ? u.openingSide : undefined;
      });

    return {
      reportCalculation: entity.reportCalculation,
      amount: entity.amount,
      calculatedVariantId,
      calculatedEAN,
      item: entity,
      openingSide
    }
  }

  private addReportItem(reportItems: IReportItem[], reportItem: IReportItem) {
    const sameEntity = reportItems.find(i => i.calculatedVariantId === reportItem.calculatedVariantId);
    if (sameEntity) {
      sameEntity.amount += reportItem.amount;
    } else {
      reportItems.push(reportItem);
    }
  }

  @action.bound
  private async renderKitchen() {
    try {
      const renderingModel = new ServerViewModel(this.kitchen.model, {
        height: 992,
        width: 558,
        type: 'png',
        watermark: [
          {
            name: 'nobilia',
            align: 'bottom-left',
            rotation: 0
          },
          {
            name: 'poweredBy',
            align: 'right',
            rotation: 270
          }
        ]
      });

      const imageUrl = await this.renderService.render(renderingModel);
      if (imageUrl) {
        this.replaceImage(imageUrl);
      }
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  private replaceImage(imageUrl: string) {
    this.imageUrl = imageUrl;
  }

  handlePrintProject = () => {
    RootStore.actionTracker.printProject();
    window.print();
  };

  handleBackToConfiguration = async (event: React.MouseEvent) => {
    if (this.hasChanges && !this.dialogVisible && !this.dialogSilenced()) {
      event.preventDefault();
      this.toggleDialog();
      return;
    }

    if (this.doNotShowDialogAgain) {
      this.silenceDialog();
    }

    await this.kitchen.backToConfiguration();
  };

  toggleDialog = () => {
    this.doNotShowDialogAgain = false;
    this.dialogVisible = !this.dialogVisible;
  };

  silenceDialog = () => {
    localStorage.setItem(ReportStore.DIALOG_SILENCE_KEY, 'true');
  };

  dialogSilenced = () => {
    const value = localStorage.getItem(ReportStore.DIALOG_SILENCE_KEY);
    return value !== null && value === 'true';
  };

  toggleDoNotShowDialogAgain = (checked: boolean) => {
    this.doNotShowDialogAgain = checked;
  };

  changeReportItem = (reportItems: { name: string; items: IReportItem[] }[], inCycleCall = false) => (variantId: string, excluded: boolean, amount: number) => {
    this.hasChanges = true;
    if (!inCycleCall) {
      this.changedVariantIds.clear();
    }
    if (this.changedVariantIds.has(variantId)) {
      throw new Error(`Update cycle detected. VariantId ${variantId} already updated`);
    }

    let reportItem = ReportStore.findReportItem(reportItems, item => item.calculatedVariantId === variantId);
    if (!reportItem) {
      throw new Error(`Cannot find cart item with variantId ${variantId}`)
    }
    if (reportItem) {
      if (reportItem.reportCalculation !== ReportCalculation.fixed) {
        if (excluded && reportItem.reportCalculation !== ReportCalculation.deselected) {
          RootStore.actionTracker.excludeItem(reportItem.item);
        } else if (!excluded && reportItem.reportCalculation !== ReportCalculation.selected) {
          RootStore.actionTracker.includeItem(reportItem.item);
        }

        let reportCalculation, childExcluded;
        if (excluded) {
          reportCalculation = ReportCalculation.deselected;
          childExcluded = true;
        } else {
          reportCalculation = ReportCalculation.selected;
          childExcluded = false;
        }

        reportItem.reportCalculation = reportCalculation;
        if (reportItem.item.child) {
          const child = ReportStore.findReportItem(reportItems, item => getOptionId(item.item) === reportItem!.item.child);
          if (!child) {
            throw new Error(`Cannot find child ${child} for item with variantId ${variantId}`)
          }
          if (child.reportCalculation !== reportCalculation && child.reportCalculation !== ReportCalculation.fixed) {
            RootStore.notification({
              content: {
                raw: RootStore.localization.formatMessage(
                  `ui.report.${childExcluded ? 'child_excluded' : 'child_included'}`,
                  { child: RootStore.localization.formatMessage(child.item.name) }
                )
              },
              type: NotificationType.INFO,
              modal: true,
            });
            this.changeReportItem(reportItems, true)(child.calculatedVariantId, childExcluded, child.item.amount);
          }
        }
      }

      if (reportItem.amount !== amount) {
        RootStore.actionTracker.changeItemQuantity(reportItem.item, amount);
      }

      reportItem.amount = amount;
    }

    this.reportGroups = [...this.reportGroups];
  };

  private static findReportItem(reportItems: { name: string; items: IReportItem[] }[], criteria: (item: IReportItem) => boolean) {
    for (let group of reportItems) {
      const reportItem = group.items.find(criteria); //item => item.calculatedVariantId === variantId);
      if (reportItem) {
        return reportItem;
      }
    }
    return undefined;
  }

  totalCount = (accumulator: number, item: IReportItem) => {
    const excluded = item.reportCalculation === ReportCalculation.deselected;
    return accumulator + (excluded ? 0 : item.amount);
  };

  get totalSum() {
    const entities = this.reportGroups.flatMap(g => g.items);
    return entities.reduce((accumulator, item) => {
      const excluded = item.reportCalculation === ReportCalculation.deselected;
      return accumulator + (excluded ? 0 : (item.item.basePrice + item.item.price * item.amount));
    }, 0) + this.mountingServicePrice;
  }

  get mountingServicePrice() {
    return this.selectedMountingVariantId
      ? this.mountingOptions.find(option => option.variantId === this.selectedMountingVariantId)!.price
      : 0;
  }

  @action.bound
  setSelectedMountingOption = (variantId: string | null) => {
    this.hasChanges = true;
    this.selectedMountingVariantId = variantId;
  };

  checkout = () => {
    RootStore.actionTracker.checkout();

    let url = 'https://top-shelf.de/cart/update?';
    let params: string;
    const positions: IReportItem[] = this.reportGroups.flatMap(g => g.items);
    params = positions
      .filter(u => u.calculatedVariantId)
      .map(u => `updates[${u.calculatedVariantId}]=${u.reportCalculation !== ReportCalculation.deselected ? u.amount : 0}`)
      .join('&');
    params += this.mountingOptions
      .map(option => `&updates[${option.variantId}]=${option.variantId === this.selectedMountingVariantId ? 1 : 0}`)
      .join('');
    const hash = this.kitchen.getKitchenHash();
    params += `&attributes[canvasConfigurator]=${hash}`;
    url += params;
    if (window.parent !== window) {
      window.parent.postMessage({ type: 'redirect', url }, '*');
    } else {
      window.location.replace(url);
    }
  };
}
