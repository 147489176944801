import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Dimensions from '../Dimensions/Dimensions';
import './ItemCard.scss';

interface IItem {
  itemImage: string;
  name: string;
  price?: number;
  width: number;
  height: number;
  depth: number;
  description: string;
  typeNo?: string;
}

export interface IProps {
  item: IItem;
  installed?: number;
  maxQuantity?: boolean;
  isSelected?: boolean;
  onClick?: (item: IItem) => void;
}

export default class ItemCard extends React.Component<IProps> {
  descriptionWrapRef: React.RefObject<HTMLDivElement> = React.createRef();
  descriptionRef: React.RefObject<HTMLParagraphElement> = React.createRef();

  componentDidMount(): void {
    const entry = this.descriptionRef.current;
    if (entry && entry.scrollHeight > entry.clientHeight) {
      entry.classList.add('truncated');
    } else if (this.descriptionWrapRef.current) {
      this.descriptionWrapRef.current.classList.add('expanded');
    }
  }

  render() {
    const { item, maxQuantity, installed, isSelected } = this.props;
    const showPrices = process.env.REACT_APP_CL__FEATURE__PRICING === 'ON';

    return (
      <div
        className={classNames('elements-card', installed && 'elements-card_installed', maxQuantity && 'elements-card_max-quantity', isSelected && 'elements-card_selected')}
        onClick={this.clickHandler}
        tabIndex={0}
      >
        <h5 className="elements-card__heading">
          <FormattedMessage id={item.name}/>
        </h5>
        <div className="elements-card__image-wrapper">
          <img className="elements-card__image" alt="" src={item.itemImage}/>
        </div>
        <div className="elements-card__data">
          {(item.width || item.height) &&
          <Dimensions
            className="elements-card__dimensions"
            width={item.width}
            height={item.height}
            depth={item.depth}
          />
          }
          {item.typeNo !== undefined &&
          <p className="elements-card__type-number">
            <FormattedMessage id="ui.elements.typeNumber"/>: {item.typeNo}
          </p>
          }
          {item.price && showPrices && <p className="elements-card__price">{item.price} €</p>}
        </div>
        <div className="elements-card__description" ref={this.descriptionWrapRef}>
          <p ref={this.descriptionRef}><FormattedMessage id={item.description}/></p>
          <button type="button" className="elements-card__more-info" onClick={this.handleShowMoreInfo}>
            <FormattedMessage id="ui.elements.description.more"/>
          </button>
          <button type="button" className="elements-card__less-info" onClick={this.handleShowLessInfo}>
            <FormattedMessage id="ui.elements.description.less"/>
          </button>
        </div>
        {!!installed &&
        <div className="elements-card__status elements-card__status_installed">
          {installed} <FormattedMessage id="ui.elements.installed"/>
        </div>
        }
        {maxQuantity &&
        <div className="elements-card__status elements-card__status_max-quantity"><FormattedMessage id="ui.elements.amount.max"/></div>
        }
        {isSelected &&
        <div className="elements-card__action-bar">
          <FormattedMessage id="ui.elements.select_location"/>
        </div>
        }
      </div>
    );
  }

  clickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { item, onClick } = this.props;
    if (onClick) {
      onClick(item);
    }
  };

  handleShowMoreInfo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.descriptionWrapRef.current) {
      this.descriptionWrapRef.current.classList.add('expanded');
    }
  };

  handleShowLessInfo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.descriptionWrapRef.current) {
      this.descriptionWrapRef.current.classList.remove('expanded');
    }
  };
}
