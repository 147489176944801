import { getOptionId, MutatorContext, Option, OptionGroup, PModel, POption, useTypeFrom, ValidationResult } from '@canvas-logic/engine';
import { ChangeKitchenMaterialMutator } from './ChangeKitchenMaterialMutator';
import { UnitMatcher } from '../services/UnitMatcher';
import { IKitchen, IMountedUnit, OpeningSide, Unit } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';
import {
  ISerializedUnit,
  ISerializedUnitV3,
  ISerializedUnitV4,
  SerializedKitchen,
  SerializedKitchenMatcher
} from '../services/SerializedKitchen';

class LinkCompatibilityService {
  constructor(private state: SerializedKitchen) {

  }

  getCompatibleState() {
    if (this.state.worktop.optionId === '192_Oak_Provence_Plate') {
      this.state.worktop.optionId = '192_Oak_Provence_Plate_60_180'
    }
    if (this.state.worktop.optionId === '354_Concrete_Slate_Grey_Plate') {
      this.state.worktop.optionId = '354_Concrete_Slate_Grey_Plate_60_180'
    }
    return this.state;
  }
}

export default class DeserializeKitchenMutator extends KitchenBaseMutator {
  constructor(private state: SerializedKitchen) {
    super();
    const service = new LinkCompatibilityService(state);
    this.state = service.getCompatibleState();
  }

  mutateKitchen(context: MutatorContext, typeRef: string, model: IKitchen): [PModel<IKitchen>, ValidationResult] {
    let resultModel = model as PModel;
    const stateUnits: (ISerializedUnitV4 | ISerializedUnitV3 | ISerializedUnit)[] = this.state.units;
    let hasUiId = this.state.units.every(u => !!(u as any).uiId);
    let maxUiId = hasUiId
      ? Math.max(0, ...stateUnits.map(u => (u as any).uiId))
      : 0;
    let nextUiId = maxUiId + 1;
    const mountedUnits = stateUnits.map((unit) => {
      // Make sure we have new copy of units in each iteration
      const units = this.getOptionsFor(model, context, 'units.unit');
      const unitOption = units.find(option => getOptionId(option.model) === unit.unit.optionId);
      const innerUnit: Unit = (unitOption as any).model;
      new UnitMatcher(innerUnit)
        .withOpeningSide()
        .run((innerUnit) => {
          const openingSideField = 'openingSide' in unit.unit ? 'openingSide' : 'opening_side';
          innerUnit.openingSide = (unit.unit as any)[openingSideField];
        })

      new UnitMatcher(innerUnit)
        .withAdditionals()
        .run(innerUnit => {
          const additionals = unit.unit.additionals;
          if (additionals) {
            for (let i = 0; i < innerUnit.additionals.length; i++) {
              if (additionals[i]) {
                const additions = this.getOptionsFor((unitOption as any).model as any, context, `additionals.${i}`);
                const additionalOption = additions.find(additional => getOptionId(additional.model) === additionals[i]);
                if (additionalOption) {
                  innerUnit.additionals[i] = (additionalOption as any).model as any;
                }
              } else {
                innerUnit.additionals[i] = null;
              }
            }
          }
        });

      const mountedUnit: IMountedUnit = {
        position: unit.position,
        unit: innerUnit,
        uiId: hasUiId ? (unit as any).uiId : nextUiId++
      };
      useTypeFrom(mountedUnit, resultModel.units[0]);

      return mountedUnit;
    });

    // Replaces all units with serialized.
    // Make sure to deserialize units before any other mutation to be made
    (resultModel as any).units = mountedUnits;

    const worktops = this.getOptionsFor(model, context, 'worktop');
    const worktopOption = (worktops as any[]).find((option: POption) => getOptionId(option.model) === this.state.worktop.optionId);
    if (!worktopOption) {
      return [model, ValidationResult.Error(`Worktop ${this.state.worktop.optionId} not found`)]
    }
    resultModel = context.mutate(resultModel, new ChangeKitchenMaterialMutator('worktop', [worktopOption]))[0];
    // resultModel = context.mutate(resultModel, new ChangeKitchenMaterialMutator('worktop.amount', this.state.worktop.count as any))[0];

    if (this.state.skinal.optionId) {
      const skinals = this.getOptionsFor(model, context, 'skinal');
      const skinalOption = (skinals as any[]).find((option: POption) => getOptionId(option.model) === this.state.skinal.optionId);
      resultModel = context.mutate(resultModel, new ChangeKitchenMaterialMutator('skinal', skinalOption))[0];
      resultModel = context.mutate(resultModel, new ChangeKitchenMaterialMutator('skinal.amount', this.state.skinal.count as any))[0];
    } else {
      if (resultModel.skinal) {
        (resultModel as any).skinal = null;
      }
    }

    new SerializedKitchenMatcher(this.state)
      .withUpstand()
      .run((state => {
        const upstands = this.getOptionsFor(model, context, 'upstand');
        const upstandOption = (upstands as any[]).find((option: POption) => getOptionId(option.model) === state.upstand);
        resultModel = context.mutate(resultModel, new ChangeKitchenMaterialMutator('upstand', upstandOption))[0];
      }));
    useTypeFrom(resultModel.units, model.units);
    return [resultModel as PModel<IKitchen>, ValidationResult.Valid()];
  }

  getOptionsFor(model: PModel, context: MutatorContext, name: string) {
    const options = context.propertyValuesByPath(model, name);
    if (!options || !options.length) {
      return [];
    }
    let q: any[] = options!.slice() as any;
    const result = [];
    while (q.length > 0) {
      const next = q.pop();
      if (next instanceof OptionGroup) {
        q.push(...next.children);
      } else if (next instanceof Option) {
        result.push(next);
      }
    }
    return result;
  }
}
