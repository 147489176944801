import { MutatorContext, PModel, ValidationResult } from '@canvas-logic/engine';
import { IKitchen } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';

export class DeleteSkinalMutator extends KitchenBaseMutator {
  mutateKitchen(context: MutatorContext, typeRef: string, model: IKitchen): [PModel<IKitchen>, ValidationResult] {
    model.skinal = undefined;
    return [model, ValidationResult.Valid()];
  }
}
