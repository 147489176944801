export enum ConfigurationState {
  REVIEW,
  SETTINGS_EDIT,
  ELEMENTS
}

export enum ConfigurationMode {
  VIEW,
  EDIT
}

export const OPEN_SIDE_PATH = 'openingSide';

export enum ActiveCamera {
  Front,
  Perspective
}
