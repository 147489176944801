import { ModelNotificationEvent, PModel } from '@canvas-logic/engine';
import { IAdditionalAppliance } from '../schema';

export enum LinkedElementModelEventType {
  ADD,
  REMOVE,
  REPLACE
}

export default class LinkedElementModelEvent extends ModelNotificationEvent {
  static readonly EVENT_NAME = 'LinkedElementModelEvent';

  constructor(readonly type: LinkedElementModelEventType, target: PModel, readonly element: IAdditionalAppliance) {
    super(target);
  }
}
