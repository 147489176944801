import { hoodUnitGuard, unitElementGuard } from '../model/Model';
import { Unit } from '../schema';
import TypeMatcher, {
  WithAdditionals,
  WithArticleNumber,
  WithOpeningSide, WithPrice,
  WithReportCategory,
  WithVariantId,
  WithVisibility
} from './TypeMatcher';

/**
 * Determine specific entity features by broader entity type.
 *
 * @see StructuralTypeMatcher
 */

export class UnitMatcher<T extends Unit> extends TypeMatcher<T> {
  withArticleNumber(): UnitMatcher<T & WithArticleNumber> {
    const satisfy = 'articleNumber' in this.entity;
    if (satisfy === this.satisfy) {
      return this as any;
    } else {
      return new UnitMatcher(this.entity as any, satisfy);
    }
  }

  withAdditionals(): UnitMatcher<T & WithAdditionals> {
    // With copy
    if (unitElementGuard(this.entity) || hoodUnitGuard(this.entity)) {
      return new UnitMatcher(this.entity, true);
    } else {
      return new UnitMatcher(this.entity as any, false);
    }
  }

  withOpeningSide(): UnitMatcher<T & WithOpeningSide> {
    if ('openingSide' in this.entity) {
      return new UnitMatcher(this.entity as any, true);
    } else {
      return new UnitMatcher(this.entity as any, false);
    }
  }

  withVisibility(): UnitMatcher<T & WithVisibility> {
    if ('visibility' in this.entity) {
      return new UnitMatcher(this.entity as any, true);
    } else {
      return new UnitMatcher(this.entity as any, false);
    }
  }

  withReportCategory(): UnitMatcher<T & WithReportCategory> {
    if ('reportCategory' in this.entity) {
      return new UnitMatcher(this.entity as any, true);
    } else {
      return new UnitMatcher(this.entity as any, false);
    }
  }

  withVariantId(): UnitMatcher<T & WithVariantId> {
    if ('variantId' in this.entity) {
      return new UnitMatcher(this.entity as any, true);
    } else {
      return new UnitMatcher(this.entity as any, false);
    }
  }

  withPrice(): UnitMatcher<T & WithPrice> {
    if ('price' in this.entity) {
      return new UnitMatcher(this.entity as any, true);
    } else {
      return new UnitMatcher(this.entity as any, false);
    }
  }
}
