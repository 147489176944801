import { EngineInternalAPI, MutatorContext, Option, PModel, ValidationResult } from '@canvas-logic/engine';
import { AfterPosition, BoundaryPosition, unitElementGuard, UnitInsertPosition } from '../model/Model';
import { collectInformation, createSelectedNobiliaUnit } from './utils';
import { Schema } from '@canvas-logic/engine-data';
import { layoutService } from '../services/LayoutService';
import { IKitchen, IMountedUnit, OpeningSide, Unit, UnitKind } from '../schema';
import { KitchenBaseMutator } from './KitchenBaseMutator';
import { domainService } from '../services/DomainService';

export class AddUnitMutator extends KitchenBaseMutator {

  constructor(private position: UnitInsertPosition, private unit: Option<Unit>) {
    super();
  }
  mutateKitchen(context: MutatorContext, typeRef: string, origin: PModel<IKitchen>): [IKitchen, ValidationResult] {
    let result: [IKitchen, ValidationResult] = [origin, ValidationResult.Valid()];
    let needAltUnit = false;
    let prevResult: any;
    try {
      const result = this._mutateKitchen(context, typeRef, origin);
      layoutService.normalizeKitchen(result[0]);
      prevResult = result;

      const [newModel, mutationResult] = result;
      if (mutationResult.isValid) {
        const validationResult = context.validate(newModel);
        if (validationResult.isValid) {
          return result;
        } else {
          needAltUnit = true;
        }
      } else {
        needAltUnit = true;
      }
    } catch (e) {
      needAltUnit = true;
      prevResult = ValidationResult.Error(e.message);
    }
    if (needAltUnit) {
      if (unitElementGuard(this.unit.model)) {
        const openingSide = this.unit.model.openingSide;
        if (openingSide === OpeningSide.NONE) {
          throw new Error('Cannot insert unit. Opening Side NONE');
        }
        const inverseddOpeningSide = openingSide === OpeningSide.R ? OpeningSide.L : OpeningSide.R;
        this.unit.model = { ...this.unit.model, openingSide: inverseddOpeningSide };
        result = this._mutateKitchen(context, typeRef, origin);
      } else {
        return prevResult;
      }
    }
    return result
  }

  _mutateKitchen(context: MutatorContext, typeRef: string, origin: PModel<IKitchen>): [IKitchen, ValidationResult] {
    const schema = context.schema;
    let units = origin.units.slice() as IMountedUnit[];
    let model = { ...origin, units };

    let newUnit: PModel<Unit> = this.unit.model as PModel<Unit>;
    switch (newUnit.unitKind) {
      case UnitKind.bottom:
        if (this.position instanceof AfterPosition) {
          if ((this.position as AfterPosition).after < 0) {
            throw Error('After wrong usage. Use InsertPosition \'left\' instead');
          }
          let movingElements = [];
          let afterTall = false;
          let beforeTall = false;
          for (let un of units) {
            if (un.unit.unitKind !== 'top') {
              if (un.position > (this.position as AfterPosition).after) {
                movingElements.push(un);
              }
              if (un.unit.unitKind === 'tall') {
                if (un.position === (this.position as AfterPosition).after) {
                  afterTall = true;
                } else if (un.position === (this.position as AfterPosition).after + 1) {
                  beforeTall = true;
                }
              }
              if (afterTall && beforeTall) {
                return [model, ValidationResult.Error('ui.error.add.between_tall')];
              }

            }
          }
          if (movingElements.length === 0) {
            throw Error('After wrong usage. Use BoundaryPosition \'right\' instead');
          }
          movingElements.forEach(u => u.position++);
          this.addUnit(schema, model, (this.position as AfterPosition).after + 1);
          return [model, ValidationResult.Valid()];

        } else {
          return this.addBottomUnit(schema, units, model);
        }
      case UnitKind.tall:
        if (this.position instanceof AfterPosition) {
          let movingElements = [];
          let afterTall = false;
          let beforeTall = false;
          let beforeBottomOrTop = false;
          for (let un of units) {
            if (un.position > (this.position as AfterPosition).after) {
              movingElements.push(un);
              beforeBottomOrTop = beforeBottomOrTop || un.unit.unitKind !== 'tall'
            }
            if (un.unit.unitKind === 'tall') {
              if (un.position === (this.position as AfterPosition).after) {
                afterTall = true;
              } else if (un.position === (this.position as AfterPosition).after + 1) {
                beforeTall = true;
              }
            }
          }
          if (!afterTall && !beforeTall) {
            return [model, ValidationResult.Error('ui.error.add.between_not_tall')];
          }
          if (!afterTall && beforeBottomOrTop) {
            return [model, ValidationResult.Error('ui.error.add.before_not_tall')];
          }
          if (movingElements.length === 0) {
            throw Error('After wrong usage. Use BoundaryPosition \'right\' instead');
          }

          movingElements.forEach(u => u.position++);
          this.addUnit(schema, model, (this.position as AfterPosition).after + 1);
          return [model, ValidationResult.Valid()];
        } else {
          return this.addTallUnit(schema, units, model);
        }
      case UnitKind.top:
        if (!model.units.some(u => u.unit.unitKind === UnitKind.top)) {
          const lastStartingTalls = layoutService.getStartingTalls(model.units);
          const position = lastStartingTalls.length > 0 ? (lastStartingTalls[lastStartingTalls.length - 1].position + 1) : 0;
          this.addUnit(schema, model, position);
          return [model, ValidationResult.Valid()];
        }
        if (this.position instanceof AfterPosition) {
          let info = collectInformation(units);

          if (info.bottomWidth > 0 && info.tallLastWidth > 0 && newUnit.width + info.topWidth > info.bottomWidth) {
            return [model, ValidationResult.Error('ui.error.add.right_tall')];
          }
          units.filter(u => u.position > (this.position as AfterPosition).after && u.unit.unitKind === 'top').forEach(
            u => u.position++
          );
          this.addUnit(schema, model, (this.position as AfterPosition).after + 1);
          return [model, ValidationResult.Valid()];
        } else {
          return this.addTopUnit(schema, units, model, newUnit);
        }
    }
    throw Error('Fatal: Not supported');
  }

  private addBottomUnit(schema: Schema, units: IMountedUnit[], model: PModel<IKitchen>): [PModel<IKitchen>, ValidationResult] {
    if ((this.position as BoundaryPosition) === 'right') {
      let info = collectInformation(units);


      if (info.bottomWidth > 0 && info.tallLastWidth > 0) {
        return [model, ValidationResult.Error('ui.error.add.right_tall')];
      }
      this.addUnit(schema, model, info.maxBottomIndex + 1);

    } else if ((this.position as BoundaryPosition) === 'left') {
      if (units.find(u => u.unit.unitKind === 'tall' && u.position === 0)) {
        return [model, ValidationResult.Error('ui.error.add.left_tall')];
      } else {
        units.filter(u => u.unit.unitKind === 'bottom' || u.unit.unitKind === 'tall').forEach(u => u.position++);
        this.addUnit(schema, model, 0);
      }
    }
    return [model, ValidationResult.Valid()]
  }

  private addUnit(schema: Schema, model: PModel, idx: number) {
    let units: IMountedUnit[] = model.units;
    const maxUiId = Math.max(0, ...units.map(u => u.uiId));
    units.push(createSelectedNobiliaUnit(schema, this.unit, idx, maxUiId + 1));
    EngineInternalAPI.relinkToModel(schema, model, 'units.' + (units.length - 1))
  }

  private addTopUnit(schema: Schema, units: IMountedUnit[], model: PModel<IKitchen>, newUnit: PModel<Unit>): [PModel<IKitchen>, ValidationResult] {
    if ((this.position as BoundaryPosition) === 'right') {
      let info = collectInformation(units);

      if (info.bottomWidth > 0 && info.tallLastWidth > 0 && newUnit.width + info.topWidth > info.bottomWidth) {
        return [model, ValidationResult.Error('ui.error.add.right_tall')];
      }
      this.addUnit(schema, model, info.maxTopIndex + 1);
    } else if ((this.position as BoundaryPosition) === 'left') {
      if (units.length > 1 && units.find(u => u.unit.unitKind === 'tall' && u.position === 0)) {
        return [model, ValidationResult.Error('ui.error.add.left_tall')];
      } else {
        const info = collectInformation(units);
        if (info.tallLastWidth > 0 && info.bottomWidth < (info.topWidth + this.unit.model.width)) {
          return [model, ValidationResult.Error('ui.error.add.no_space_tall')];
        }
        units.filter(u => u.unit.unitKind === 'top').forEach(u => u.position++);
        this.addUnit(schema, model, 0);
      }
    }
    return [model, ValidationResult.Valid()]
  }

  private addTallUnit(schema: Schema, units: IMountedUnit[], model: PModel<IKitchen>): [PModel<IKitchen>, ValidationResult] {
    if ((this.position as BoundaryPosition) === 'left') {
      units.forEach(u => u.position++);
      this.addUnit(schema, model, 0);
    } else if ((this.position as BoundaryPosition) === 'right') {
      let info = collectInformation(units);
      if (info.bottomWidth >= info.topWidth) {
        this.addUnit(schema, model, info.maxBottomIndex + 1);
      } else {
        return [model, ValidationResult.Error('ui.error.add.tall_intersection')];
      }
    }
    return [model, ValidationResult.Valid()];
  }
}
