import * as React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import BottomSheet from '../../../components/BottomSheet/BottomSheet';
import IconButton from '../../../components/buttons/IconButton/IconButton';
import SmallButton from '../../../components/buttons/SmallButton/SmallButton';
import ElementAdditionals from '../../../components/ElementAdditionals/ElementAdditionals';
import ElementDetails from '../../../components/ElementDetails/ElementDetails';
import Icon from '../../../components/Icon/Icon';
import PropertySettingsList from '../../../components/PropertySettingsList/PropertySettingsList';
import { isElementSkinal, isElementUnit } from '../../../stores/ConfiguratorStore';
import { OPEN_SIDE_PATH } from '../../../model/ConfigurationState';
import { SkinalDetails } from '../../../components/SkinalDetails/SkinalDetails';
import { ElementViewStore } from '../../../stores/ConfiguratorViews/ElementViewStore';
import Button from '../../../components/buttons/Button/Button';
import { UnitMatcher } from '../../../services/UnitMatcher';

import './ElementView.scss'
import { getOptionId, Option } from '@canvas-logic/engine';
import { INameImage } from '../../../schema';
import MaterialItem from '../../../components/MaterialItem/MaterialItem';

export interface IProps {
  store: ElementViewStore;
}

@observer
export class ElementView extends React.Component<IProps> {
  renderView() {
    const { store } = this.props;

    let component;
    if (isElementUnit(store.selectedElement)) {
      const openOptions = store.propertyValuesByPath(store.selectedElement.unit as any, OPEN_SIDE_PATH) as any || [];
      component = <ElementDetails
        className='element-view__details'
        unit={store.selectedElement}
        openOptions={openOptions}
        store={store}
        onDelete={store.deleteSelectedUnit}
      />
    } else if (isElementSkinal(store.selectedElement)) {
      component = <SkinalDetails
        skinal={store.selectedElement}
        onDelete={store.deleteSkinal}
      />
    }
    return component;
  }

  render() {
    const { store } = this.props;

    return (
      <div className='element-view'>
        {store.shouldDrawMainContent
          ?
          <>
            <div className="element-view__panel">
              <SmallButton
                color="transparent"
                onClick={store.handleBack}
              >
                <Icon type='leftArrow' />
              </SmallButton>
              <FormattedMessage id="ui.elements.options"/>
              <SmallButton
                className="element-view__button--close"
                color="transparent"
                onClick={store.handleCancel}
              >
                <Icon type='close' />
              </SmallButton>
            </div>
            <div className='element-view__wrapper'>
              <div className='element-view__container'>
                <div className='element-view__column element-view__details'>
                  {this.renderView()}
                </div>
                <div className='element-view__column'>
                  {isElementUnit(store.selectedElement)
                    ?
                    new UnitMatcher(store.selectedElement.unit)
                      .withAdditionals()
                      .map(unit => <ElementAdditionals
                          additionals={unit.additionals}
                          store={store}
                          onRemove={store.removeAdditional}
                          onSelect={store.handleSelectAdditional}
                        />, () => null)
                    :
                      <>
                        <h2 className="element-view__heading">
                          <FormattedMessage id="ui.settings.texture"/>
                        </h2>
                        {store.selectedOption && (
                          <ul className="element-view__texture-list">
                            {
                              (store.availableOptions as Option<INameImage>[]).map(option => (
                                <MaterialItem
                                  prefix={'content.property.skinal.'}
                                  material={option.model}
                                  selected={getOptionId(option.model) === getOptionId(store.selectedOption)}
                                  onClick={() => store.changeKitchenMaterial(option)}
                                  component="li"
                                />
                              ))
                            }
                          </ul>
                        )
                        }
                      </>
                  }
                </div>
              </div>
            </div>
            <div className="element-view__panel--bottom">
              <Button className="element-view__add-button" onClick={store.handleAddElement}>
                <Icon type='plus_24' />
                <FormattedMessage id="ui.elements.add" />
              </Button>
            </div>
          </>
          :
          <div className="element-view__handle-container">
            <div className="element-view__actions-container">
              <IconButton
                className="element-view__action"
                icon="menu"
                onClick={store.handleOpenSidebar}
              />
              <IconButton
                className="element-view__action"
                color='primary'
                icon="plus_24"
                onClick={store.handleAddElement}
              />
            </div>
            <BottomSheet onExpand={store.handleExpandView} className="element-view__handle">
              <FormattedMessage id='ui.elements.options' />
            </BottomSheet>
          </div>
        }
      </div>
    )
  }
}
